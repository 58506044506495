/* eslint-disable react-hooks/exhaustive-deps */
import Form from "@rjsf/core";
import "../../App.css";
import { RegistryWidgetsType, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useCallback, useEffect, useRef, useState } from "react";
import { LiveFeedPanel } from "../../reusable_components/panels/live_feed_panel";
import { EquipmentClassificationPanel } from "../../reusable_components/panels/equipment_classification_panel";
import { StatusBarPanel } from "../../reusable_components/panels/status_bar_panel";
import ObjectFieldTemplate from "../../reusable_components/templates/column_object_field_template";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompletedWorkItemRequest,
  EscalateToLaneRequest,
  FetchLayoutJsonRequest,
  RequeueWorkItemRequest,
  UpdateUserStatusRequest,
} from "../../Interfaces/work_item_interface";
import {
  completedWorkItemService,
  escalatetoLaneService,
  fetchLayoutJsonService,
  getStatusService,
  requeueWorkItemService,
  updateUserActivityStatusService,
} from "../../services/work_item_service";
import { FullscreenLoader } from "../loader";
import { VisitMatchupPanel } from "../../reusable_components/panels/visit_matchup_panel";
import { applicationAbbrievation, NotificationCategories, NotificationTypes, UserRoles, UserStatus, VisitMatchupType, WebsocketCodes, WorkItemTypes } from "../../enums/enums";
import WebsocketClient from "../../client/websocket";
import { overallContext } from "../../context/Context";
import { useContext } from "react";
import { fetchUserDetailsFromStorage } from "../../utils/utils";
import { addOldValueKey, calculateDurationInSeconds } from "../../utils/utils";
import moment from "moment";
import { CreateNotificationRequest } from "../../Interfaces/notification_interface";
import { createNotificationService } from "../../services/notification_service";
import ToastMessage from "../../reusable_components/toast";

// PS_01 to PS_13
const WorkItem = () => {
  const navigate = useNavigate();
  let navigationProps = JSON.parse(JSON.stringify(useLocation().state))

  const { userId, appRole } = fetchUserDetailsFromStorage()
  const { setIsEquipmentCorrection } = useContext(overallContext);
  const { lane_name = "", lane_id = "", completed = false, work_item_type } = navigationProps || {};
  if (!navigationProps || Object.keys(navigationProps).length === 0) {
    setIsEquipmentCorrection(false)
    navigate("/notFound", { replace: true })
  }

  const applicationUUID = appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-";

  const [loader, setLoader] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showContinueWorkingPopup, setShowContinueWorkingPopup] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(false)
  const [toastType, setToastType] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isFocusedToInputField, setIsFocusedToInputField] = useState<boolean>(false)
  const [laneDetails, setLaneDetails] = useState<any>({
    lane_id: work_item_type === WorkItemTypes.EQUIPMENT_CORRECTION ? lane_id : lane_name
  });

  const portalData = useRef<any>(null)
  const defaultPortalData = useRef<any>(null)
  const websocket = useRef<WebSocket>()
  const startTime = useRef<string>(new Date().toISOString());
  const jsonSchema = useRef<RJSFSchema>({})
  const uiSchema = useRef<UiSchema>({
    "ui:submitButtonOptions": {
      norender: true,
    },
  });

  const widgets: RegistryWidgetsType = {
    EquipmentClassificationPanel,
    StatusBarPanel,
    LiveFeedPanel,
    VisitMatchupPanel,
  };
  const fetchLayoutJson = useCallback(async () => {
    try {
      const { app_work_item_uuid } = navigationProps;
      const payload: FetchLayoutJsonRequest = {
        app_work_item_uuid: app_work_item_uuid
      };
      const apiResponse = await fetchLayoutJsonService(payload);
      if (apiResponse?.status === 200) {
        const { json_schema, ui_schema } = apiResponse?.data?.data?.json_schema
        jsonSchema.current = json_schema
        uiSchema.current = ui_schema
      } else {
        throw new Error(apiResponse?.response?.data?.message);
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error.message)
      console.log("an error occured: ", error);
    }
  }, [navigationProps]);
  //PS_48 to PS_53
  const handleOnMessage = useCallback(
    async (event: any) => {
      const { work_item_uuid } = navigationProps
      try {
        const receivedMessage = JSON.parse(event.data);
        if (receivedMessage?.data?.type === WebsocketCodes.TAKEOVER && receivedMessage?.data?.data?.work_item_uuid === work_item_uuid) {
          setIsEquipmentCorrection(false)
          navigate("/workItemNotifications", { replace: true, state: { "action": WebsocketCodes.TAKEOVER } });
          const payload: CreateNotificationRequest = {
            type: NotificationTypes.DIRECT,
            destination: [userId],
            application_uuid: applicationUUID,
            notification_details: {
              notification_category: NotificationCategories.WORKITEM,
              notification_title: "Work Item",
              notification_message: `The "work item" has been "taken over" by "${receivedMessage?.data?.data?.take_over_by || "supervisor"}".`,
              customer_name: navigationProps.customer_name,
              site_name: navigationProps.site_name,
              app_work_item_uuid: navigationProps.app_work_item_uuid,
            }
          }
          const apiResponse = await createNotificationService(payload)
          if (apiResponse?.status !== 201) {
            throw new Error(apiResponse?.response?.data?.message);
          }
        } else if (receivedMessage?.data?.type === WebsocketCodes.ESCALATEDTOCUSTOMER && receivedMessage?.data?.data?.work_item_uuid === work_item_uuid) {
          setIsEquipmentCorrection(false)
          navigate("/workItemNotifications", { replace: true, state: { "action": WebsocketCodes.ESCALATEDTOCUSTOMER } });
          await UpdateUserStatus(UserStatus.IDLE, "The work item has been deferred to customer")
        } else if (receivedMessage?.data?.type === WebsocketCodes.AUTOVISIT) {
          setLaneDetails(receivedMessage?.data?.data);
        }
      } catch (error: any) {
        handleShowToast(true, "Error", error.message)
        console.log("an error occurred: ", error);
      }
    },
    [navigate]
  );
  const handleOnError = useCallback((error: any) => {
    console.log("an error occured in websocket:", error);
  }, []);
  const loadInitialPageData = useCallback(async () => {
    setLoader(true);
    await fetchLayoutJson();
    setLoader(false);
  }, [fetchLayoutJson])
  useEffect(() => {
    try {
      const { work_item_uuid } = navigationProps
      if (!navigationProps?.completed) {
        const socket = WebsocketClient(work_item_uuid + WebsocketCodes.WORKITEMCORRECTION, [], userId);
        websocket.current = socket;
        socket.onmessage = handleOnMessage;
        socket.onerror = handleOnError;
      }
      loadInitialPageData();
      return () => {
        if (websocket.current) {
          websocket.current.close()
        }
      };
    } catch (error: any) {
      console.log("an error occured: ", error)
    }
  }, [handleOnError, handleOnMessage]);
  useEffect(() => {
    try {
      if (!completed) {
        setIsEquipmentCorrection(true);
      }
      if (navigationProps.portal_data) {
        navigationProps.portal_data = JSON.parse(navigationProps.portal_data)
        let newPortalData = navigationProps.portal_data
        defaultPortalData.current = JSON.parse(JSON.stringify(newPortalData))
        if (newPortalData.scan_data) {
          newPortalData.scan_data.old_classification = newPortalData.scan_data.classification
        }
        addOldValueKey(newPortalData)
        portalData.current = newPortalData
      }
      if (completed) {
        setMatch(true)
      }
    } catch (error: any) {
      console.log("an error occured: ", error)
    }
  }, [])
  const UpdateUserStatus = async (category: UserStatus, reason: string) => {
    try {
      if (userId) {
        const statusResponse = await getStatusService();
        if (statusResponse?.status !== 200) {
          throw new Error(statusResponse?.response?.data?.message);
        }
        const { user_status_uuid } = statusResponse?.data?.data?.find(
          (eachStatus: any) => eachStatus.status_name === category
        );
        if (!user_status_uuid) {
          throw new Error(`${category} uuid not found`);
        }
        const payload: UpdateUserStatusRequest = {
          user_uuid: userId,
          user_status_uuid: user_status_uuid,
          reason: reason,
          category: category,
        };
        const apiResponse = await updateUserActivityStatusService(payload);
        if (apiResponse?.status !== 200) {
          throw new Error(apiResponse?.response?.data?.message);
        }
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error.message)
      console.log("an error occured: ", error)
    }
  }
  //PS_40 to PS_47
  const handleSubmit = async (newPortalJson: any, completedOn: any) => {
    try {
      setLoader(true)
      const startedFormattedTime = moment.utc()
      const completionTime = moment.utc()
      const completionDurationSec: number = calculateDurationInSeconds(
        startTime.current,
        completedOn
      );
      const { work_item_uuid, customer_uuid, site_uuid, work_item_type, customer_name, site_name, expected_processing_time_sec, max_processing_time_sec } = navigationProps
      newPortalJson.utc_date_time_completed = completionTime
      if (work_item_type === WorkItemTypes.VISIT_MATCHUP) {
        newPortalJson.match_type = match === true ? VisitMatchupType.PORTAL : VisitMatchupType.MANUAL
      }
      newPortalJson.lane_id = laneDetails.lane_id;
      const payload: CompletedWorkItemRequest = {
        customer_id: customer_uuid,
        site_id: site_uuid,
        area_id: "AREA_01",
        customer_name: customer_name,
        site_name: site_name,
        group_code: laneDetails.lane_id,
        started_on: startedFormattedTime.toISOString(),
        expected_processing_time_sec: expected_processing_time_sec,
        max_processing_time_sec: max_processing_time_sec,
        lane_id: laneDetails.lane_id,
        work_item_type: work_item_type,
        work_item_id: work_item_uuid,
        completion_duration_sec: completionDurationSec,
        completed_on: completionTime.toISOString(),
        completed_work_item_data: newPortalJson,
      };
      const apiResponse = await completedWorkItemService(payload);
      if (apiResponse?.status !== 200) {
        throw new Error(apiResponse?.response?.data?.message);
      }
      setIsEquipmentCorrection(false)
      await UpdateUserStatus(UserStatus.IDLE, "The Operator has completed the work item")
      navigate("/workItemNotifications", { replace: true });
    } catch (error: any) {
      handleShowToast(true, "Error", error.message)
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }
  //PS_14 to PS_23
  const handleEscalateToLane = async () => {
    try {
      setLoader(true)
      const { work_item_uuid } = navigationProps;
      const payload: EscalateToLaneRequest = {
        work_item_uuid,
      };
      const apiResponse = await escalatetoLaneService(payload);
      if (apiResponse?.status !== 200) {
        throw new Error(apiResponse?.response?.data?.message);
      }
      setIsEquipmentCorrection(false)
      await UpdateUserStatus(UserStatus.IDLE, "The user has escalated the work item to the lane")
      navigate("/workItemNotifications", { replace: true });
    } catch (error: any) {
      handleShowToast(true, "Error", error.message)
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }
  const handleReorderWorkItem = async (setOperatorToDnd: boolean) => {
    try {
      setLoader(true)
      const { work_item_uuid } = navigationProps
      if (setOperatorToDnd) {
        await UpdateUserStatus(UserStatus.DND, "Operator did not work in work item for too long")
      } else {
        await UpdateUserStatus(UserStatus.IDLE, "The operator no longer works on the work item")
      }
      const payload: RequeueWorkItemRequest = {
        workitem_id: work_item_uuid,
        user_uuid: userId
      }
      const apiResponse = await requeueWorkItemService(payload)
      if (apiResponse?.status !== 200) {
        throw new Error(apiResponse?.response?.data?.message);
      }
      setIsEquipmentCorrection(false)
      navigate("/workItemNotifications", { replace: true });
      if (setOperatorToDnd) {
        const notificationPayload: CreateNotificationRequest = {
          type: NotificationTypes.BROADCAST,
          destination: [UserRoles.supervisor],
          application_uuid: applicationUUID,
          notification_details: {
            notification_category: NotificationCategories.WORKITEM,
            notification_title: "Work Item",
            notification_message: "The \"work item\" has been \"requeued\" due to \"operator inactivity\".",
            customer_name: navigationProps.customer_name,
            site_name: navigationProps.site_name,
            app_work_item_uuid: navigationProps.app_work_item_uuid,
          }
        }
        const apiResponse = await createNotificationService(notificationPayload)
        if (apiResponse?.status !== 201) {
          throw new Error(apiResponse?.response?.data?.message);
        }
      }
    }
    catch (error: any) {
      handleShowToast(true, "Error", error.message)
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }
  const handleFocusChange = (isFocused: boolean) => {
    setIsFocusedToInputField(isFocused)
  }
  const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
    setShowToast(showToast);
    setToastType(toastType);
    setToastMessage(toastMessage);
  };
  return (
    <>
      {showToast && <ToastMessage props={
        {
          setIsToast: setShowToast,
          toastMessage: toastMessage,
          toastType: toastType
        }
      } />}
      {loader && <FullscreenLoader />}
      < Form
        schema={jsonSchema.current}
        uiSchema={uiSchema.current}
        templates={{ ObjectFieldTemplate }}
        validator={validator}
        formContext={{
          portalData,
          defaultPortalData,
          handleSubmit,
          match,
          setMatch,
          handleEscalateToLane,
          handleReorderWorkItem,
          showContinueWorkingPopup,
          setShowContinueWorkingPopup,
          laneDetails,
          navigationProps,
          isFocusedToInputField,
          handleFocusChange
        }}
        widgets={widgets}
      />
    </>
  );
};

export default WorkItem;