import Client from "../client/client";
import { baseURL, streamingService } from "../constant";
import { GetLaneDevicesRequest, PTZCamRequest, RegisterWebRTCRequest, RTPStreamRequest, RTPStreamStatusRequest, SignalWebRTCRequest } from "../Interfaces/streaming_service_interface";

export const camControlService = async (payload: PTZCamRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.CamControl}`,
            data: payload
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const camStatusService = async (payload: PTZCamRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.CamStatus}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const signalWebRTCService = async (payload: SignalWebRTCRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.SignalWebRTC}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const registerWebRTCService = async (payload: RegisterWebRTCRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.RegisterWebRTC}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const startRTPStreamService = async (payload: RTPStreamRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.StartRTPStream}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const stopRTPStreamService = async (payload: RTPStreamRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.StopRTPStream}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const getLaneDevicesService = async (payload: GetLaneDevicesRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.LaneDevices}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const checkRTPStatusService = async (payload: RTPStreamStatusRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${streamingService.GetRTPStreamStatus}`,
            data: payload,
        };

        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}