import { encryptKeys, encryptStorage } from "../../constant";
import { NotificationDetails } from "../../Interfaces/notification_interface";
import { userDetails } from "../../Interfaces/login_interface";

// formatDateLabel formats date label based on received at time.
// Pseudocode mapping:
// PS_09 to PS_10 (SQ_EX_1.9 to SQ_EX_1.10)
// 1. Calculates date label based on dateKey which is the received at time.
export const formatDateLabel = (dateKey: string): string => {
    const today = new Date().toISOString().split('T')[0];
    const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];

    if (dateKey === today) return 'Today';
    if (dateKey === yesterday) return 'Yesterday';
    return new Date(dateKey).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
};

export const formatDateLabelForGrid = (dateKey: string): string => {
    return new Date(dateKey).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
};
// formatTimeAgo calculates the verbage for time duration
// Pseudocode mapping:
// PS_09 to PS_10 (SQ_EX_1.9 to SQ_EX_1.10)
// 1. Calculates the relative time difference from current datetime to recevied at time.
export const formatTimeAgo = (date: Date): string => {
    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / 60000);

    if (diffInMinutes < 60) {
        if (diffInMinutes <= 0) {
            return `Just now`
        }
        return `${diffInMinutes}m ago`
    };
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return `${Math.floor(diffInMinutes / 1440)}d ago`;
};
// groupNotificationsByDate groups an array of notifications by date.
// Pseudocode mapping:
// PS_09 to PS_10 (SQ_EX_1.9 to SQ_EX_1.10)
// 1. Reduce over the array of notifications.
// 2. For each notification, find the date it was received.
// 3. Use the date as a key to group notifications.
// 4. Return the grouped notifications.
export const groupNotificationsByDate = (notifications: NotificationDetails[]): Record<string, NotificationDetails[]> => {
    return notifications.reduce((acc, notification) => {
        const dateKey = notification.received_at.toISOString().split('T')[0];
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(notification);
        return acc;
    }, {} as Record<string, NotificationDetails[]>);
};

export const highlightQuotedText = (text: string): (string | JSX.Element)[] => {
    const parts = text.split(/"([^"]+)"/g)
    return parts.map((part, index) => {
        if (index % 2 === 1) {
            return <span className="fw-bold" key={index}>{part}</span>;
        }
        return part;
    });
};

export const fetchUserDetailsFromStorage = (): any => {
    const defaultUserDetails : userDetails = {
        accessToken: "",
        userName: "",
        emailId: "",
        userId: "",
        refreshToken: "",
        appRole: []
    }
    const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
    const parsedUserDetails = storedUserDetails
        ? JSON.parse(storedUserDetails)
        : defaultUserDetails;
    return parsedUserDetails;
}