import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Tooltip, styled } from '@mui/material';
import moment from "moment";

// Memoize BreakSpan outside the component
const BreakSpan = React.memo(styled('span')(({ theme }) => ({
  height: '36px',
  backgroundColor: '#FDA700',
  position: 'absolute',
  cursor: 'pointer',
})));

export function ShiftScroll(shiftData: any) {
  const current_time = moment.utc().format('HH:mm:ss'); // Current time in UTC
  const shiftDetails = shiftData?.shiftData;

  const [hours, setHours] = useState<JSX.Element[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolledToStart, setIsScrolledToStart] = useState(true);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const formatTimeRange = useCallback((shiftStartTime: string, breakStartTime: string, durationMinutes: string): string => {
    const [shiftHours, shiftMinutes] = shiftStartTime.split(':').map(Number);
    const [breakHours, breakMinutes] = breakStartTime.split(':').map(Number);

    let startHours = shiftHours + breakHours;
    let startMinutes = shiftMinutes + breakMinutes;

    if (startMinutes >= 60) {
      startHours += Math.floor(startMinutes / 60);
      startMinutes %= 60;
    }

    let endHours = startHours;
    let endMinutes = startMinutes + parseInt(durationMinutes, 10);

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    startHours %= 24;
    endHours %= 24;

    const startPeriod = startHours >= 12 ? 'PM' : 'AM';
    const endPeriod = endHours >= 12 ? 'PM' : 'AM';

    const startHours12 = startHours % 12 || 12;
    const endHours12 = endHours % 12 || 12;

    const formattedStart = `${startHours12.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}`;
    const formattedEnd = `${endHours12.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;

    return `${formattedStart} - ${formattedEnd} ${startPeriod === endPeriod ? startPeriod : `${startPeriod}-${endPeriod}`}`;
  }, []);

  const getBreakElement = useMemo(() => (hourTime: moment.Moment): JSX.Element | null => {
    const shiftStart = moment.utc(shiftDetails?.shift_start_time, 'HH:mm:ss');

    for (const breakRange of shiftDetails?.break_ranges) {
      const breakStart = moment.utc(breakRange?.break_start_time, 'HH:mm:ss');
      const actualBreakStart = shiftStart.clone().add(breakStart.hour(), 'hours').add(breakStart.minute(), 'minutes');
      const durationMinutes = parseInt(breakRange?.duration_minutes, 10);
      const actualBreakEnd = actualBreakStart.clone().add(durationMinutes, 'minutes');

      if (hourTime.isBetween(actualBreakStart, actualBreakEnd, 'hour', '[]')) {
        let startMinute = 0;
        let endMinute = 60;
        let widthPercentage = 100;
        let leftPercentage = 0;

        if (hourTime.isSame(actualBreakStart, 'hour')) {
          startMinute = actualBreakStart.minute();
          leftPercentage = (startMinute / 60) * 100;
        }

        if (hourTime.isSame(actualBreakEnd, 'hour')) {
          endMinute = actualBreakEnd.minute();
        }

        widthPercentage = ((endMinute - startMinute) / 60) * 100;

        return (
          <Tooltip
            key={`${breakRange.break_start_time}-${breakRange.duration_minutes}`}
            title={
              <span style={{ fontSize: '9px', fontWeight: 'normal', color: 'white' }}>
                {formatTimeRange(shiftDetails?.shift_start_time, breakRange?.break_start_time, breakRange?.duration_minutes)}
              </span>
            }
            placement="top-end"
            arrow
          >
            <BreakSpan
              className="break lg"
              style={{
                width: `${widthPercentage}%`,
                left: `${leftPercentage}%`,
              }}
            />
          </Tooltip>
        );
      }
    }

    return null;
  }, [shiftDetails?.shift_start_time, shiftDetails?.break_ranges, formatTimeRange]);

  const generateHours = useCallback(() => {
    const startTimeString = shiftDetails?.shift_start_time;
    const endTimeString = shiftDetails?.shift_end_time;
    const startTime = moment.utc(startTimeString, 'HH:mm:ss');
    const endTime = moment.utc(endTimeString, 'HH:mm:ss');
    const currentTime = moment.utc(current_time, 'HH:mm:ss');

    const renderShift = !(startTimeString === "00:00:00" && endTimeString === "00:00:00");

    const hourElements: JSX.Element[] = [];

    for (let i = 0; i < 24; i++) {
      const hourTime = moment.utc().startOf('day').add(i, 'hours');
      let isShift = false;

      // Check if the current hour is within the shift
      if (renderShift) {
        if (startTime.isBefore(endTime)) {
          // Shift does not cross midnight
          isShift = hourTime.isBetween(startTime, endTime, 'hour', '[)');
        } else {
          // Shift crosses midnight
          isShift = hourTime.isBetween(startTime, moment.utc('23:59:59', 'HH:mm:ss'), 'hour', '[)') ||
                    hourTime.isBetween(moment.utc('00:00:00', 'HH:mm:ss'), endTime, 'hour', '[)') ||
                    hourTime.isSame(moment.utc('23:00:00', 'HH:mm:ss'));
        }
      }

      const isCurrent = hourTime.isSame(currentTime, 'hour');

      let className = `hour lg hour-${i}`;
      if (isShift) className += ' shift';
      if (isCurrent) className += ' current-time';

      let breakElement = null;
      if (renderShift) {
        breakElement = getBreakElement(hourTime);
      }

      hourElements.push(
        <div key={i} className={className} id={`hour-${i}`}>
          {breakElement}
        </div>
      );
    }

    setHours(hourElements);
  }, [shiftDetails, current_time, getBreakElement]);

  const scrollToCurrentTime = useCallback(() => {
    const hourWidth = 50; // Width of each hourly block
    const offsetAt3AM = 100; // Fixed left value for 3 AM

    const currentHour = moment.utc().hours(); // Use moment.utc to get the current hour in UTC
    let scrollPosition;

    if (currentHour >= 3 && currentHour <= 23) {
        const hoursSinceThreeAM = currentHour - 3;
        scrollPosition = (hoursSinceThreeAM * hourWidth) + offsetAt3AM;
    } else if (currentHour === 2) {
        scrollPosition = 50;
    } else {
        scrollPosition = 0;
    }

    if (containerRef.current) {
      containerRef.current.scroll({
        left: scrollPosition
      });
    }
  }, [containerRef]);

  const updateScrollButtons = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsScrolledToStart(scrollLeft <= 0);
      setIsScrolledToEnd(scrollLeft >= scrollWidth - clientWidth - 1); // Subtract 1 to account for potential rounding errors
    }
  }, []);

  const checkInitialScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsScrolledToEnd(scrollWidth <= clientWidth);
    }
  }, []);

  useEffect(() => {
    generateHours();
  }, [generateHours]);

  useEffect(() => {
    if (hours.length > 0) { // Ensures hours have been rendered
      scrollToCurrentTime();
    }
  }, [hours, scrollToCurrentTime]); // The dependency on hours ensures it re-runs when hours change.

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', updateScrollButtons);
      window.addEventListener('resize', checkInitialScroll);
      updateScrollButtons();
    }

    return () => {
      container?.removeEventListener('scroll', updateScrollButtons);
      window.removeEventListener('resize', checkInitialScroll);
    };
  }, [updateScrollButtons, checkInitialScroll]);

  const scrollContainer = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollAmount = 100; // Total amount to scroll
      const duration = 300; // Duration of the scroll animation in milliseconds
      const startTime = performance.now();
      const startScrollLeft = container.scrollLeft;

      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        let newScrollLeft;
        if (direction === 'left') {
          newScrollLeft = Math.max(0, startScrollLeft - (progress * scrollAmount));
        } else {
          const maxScroll = container.scrollWidth - container.clientWidth;
          newScrollLeft = Math.min(maxScroll, startScrollLeft + (progress * scrollAmount));
        }

        container.scrollLeft = newScrollLeft;

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        } else {
          if (direction === 'left' && newScrollLeft < 1) {
            container.scrollLeft = 0;
          } else if (direction === 'right' && newScrollLeft > container.scrollWidth - container.clientWidth - 1) {
            container.scrollLeft = container.scrollWidth - container.clientWidth;
          }
          updateScrollButtons();
        }
      };

      requestAnimationFrame(animateScroll);
    }
  };

  return (
    <>
      <div className="d-flex align-items-start">
        <button
          id="scrollLeft"
          className="time-btn left lg"
          onClick={() => scrollContainer('left')}
          disabled={isScrolledToStart}
        >
          <img src="img/time-left.svg" alt="left-arrow" />
        </button>
        <div className="hourly-grid-wrapper">
          <div className="hourly-grid-container" ref={containerRef}>
            <div className="total-hours lg">
              {hours}
            </div>
          </div>
        </div>
        <button
          id="scrollRight"
          className="time-btn lg"
          onClick={() => scrollContainer('right')}
          disabled={isScrolledToStart ? false : isScrolledToEnd}
        >
          <img src="img/time-right.svg" alt="right-arrow" />
        </button>
      </div>
    </>
  );
}