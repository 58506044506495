/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/**PS_01 - Importing of the packages and declaration of state variables */

import React, { useState, useCallback, useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Timer from "./timer";
import { AssignWorkItemPayLoad, DragItem, GetActiveOperatorsPayLoad, Message, Operator, QueuedItemsPayLoad, ReArrangeWorkItemPayLoad, SelectedOperator, SortableItemProps, SortableListProps, SortConfig, TakeWorkItemPayLoad, WorkItemQueueNotificationData, WorkItems } from "../../Interfaces/work_items_interface";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import ToastMessage from "../../reusable_components/toast";
import { assignWorkItem, getAvailableOperators, getQueuedWorkItems, takeWorkItem, updateItemPriority } from "../../services/operator_service";
import { DndProvider, useDrag, useDrop, DropTargetMonitor, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from "moment";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { baseURL, encryptStorage, encryptKeys, notificationSubscribe, operatorNotifications, formatDescription } from "../../constant";
import { applicationAbbrievation, UserStatus, WorkItemStatus } from '../../enums/enums';
import { v4 as uuidv4 } from 'uuid'; // Import the v4 function from uuid



const QueuedWorkItems = () => {

  const inputref: any = useRef()
  const [queuedWorkItems, setQueuedWorkItems] = useState<WorkItems[]>([]); // using mock data for state
  const [recordCount, setRecordCount] = useState(10);
  const [totalRecordCount, setTotalRecordCount] = useState<number>(0)
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate;
  const [availableOperators, setAvailableOperators] = useState<any>({});
  const [timeStamp, setCurrentTimeStamp] = useState<string>();
  const [visibleDropdown, setVisibleDropdown] = useState<string | null>(null);
  const [showActionDropdown, setShowActionDropdown] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const ItemType = 'ITEM_TYPE';
  const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
  const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const vemAppId = userDetails ? userDetails?.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-" : null;



  /**PS_02 - PS_03 invoke the fetchQueueWorkItems in the empty dependency useEffect */
  /**PS_QU_LIVE_03 - PS_QU_LIVE_09 - Establishing the Connection to get the live data in In Queued data */

  useEffect(() => {
    fetchQueuedWorkItems();
    let modified_url = baseURL ? baseURL.replace("https://", "") : "-"
    const newSocket = new WebSocket(
      `wss:${modified_url}${notificationSubscribe.getLiveSubscribeNotification}${operatorNotifications.getLiveWorkItemsNotification}&subscriptionId=${userDetails.userId}`
    );

    newSocket.onopen = () => {
      console.log("connection opened")
    };

    newSocket.onmessage = (event: any) => {
      let message = JSON.parse(event.data);
      try {
        const data: Message = message.data;
        handleSocketMessage(data);

      } catch (error) {
        console.error("Parsing error:", error);
      }
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close();
    }
  }, []);

  /**PS_QU_LIVE_11 - PS_QU_LIVE_20 - Based on the oldStatus and newStatus,the Data is getting manipulated in In Queued data */


  const handleSocketMessage = (data: any) => {
    const { oldStatus, newStatus, workItemJSON }: WorkItemQueueNotificationData = data;
    if (newStatus === WorkItemStatus.Queued || oldStatus === WorkItemStatus.Queued) {
      // Check if sessionWorkItems is null and initialize updatedWorkItems accordingly
      const sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems');
      let updatedWorkItems: any[] = sessionWorkItems ? JSON.parse(sessionWorkItems) : [];
      const existingIndex = updatedWorkItems?.length > 0 ? updatedWorkItems.findIndex(item => item.work_item_uuid === workItemJSON.work_item_uuid) : -1
      // Handle state transitions based on oldState and newState
      // if (oldStatus === WorkItemStatus.Queued && newStatus === WorkItemStatus.Requeued) {
      //   if (existingIndex !== -1) {
      //     updatedWorkItems[existingIndex] = workItemJson;
      //     setTotalRecordCount(prevCount => prevCount + 1);
      //     setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
      //     sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
      //   }
      // }
      if (newStatus === WorkItemStatus.Queued) {
        if (existingIndex === -1) {
          updatedWorkItems.push(workItemJSON);
          setTotalRecordCount(prevCount => prevCount + 1);
          setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
        }
      } else if (oldStatus === WorkItemStatus.Queued) {
        if (existingIndex !== -1) {
          updatedWorkItems.splice(existingIndex, 1);
          setTotalRecordCount(prevCount => prevCount - 1);
          setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
        }
      }

    }
  };

  useEffect(() => {
    const sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems');
    let sorted = JSON.parse(sessionWorkItems);
    sorted?.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    // Update state with the sorted items, limiting to recordCount
    setQueuedWorkItems(sorted ? sorted?.slice(0, recordCount) : []);
  }, [sortConfig])

  useEffect(() => {
    if (inputref.current) {
      inputref?.current?.focus();
    }
  }, [searchTerm])

  /**PS_42 - Function to handle the Sorting of the queued Work Items */
  const handleSort = (key: string) => {
    // Update sort configuration state
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };


  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const fetchQueuedWorkItems = async () => {
    try {
      const payload: QueuedItemsPayLoad = {
        work_item_status: WorkItemStatus.Queued
      };
      const response: any = await getQueuedWorkItems(payload);
      if (response?.status === 200) {
        if (response?.data) {
          setCurrentTimeStamp(response.data?.timestamp);
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(response.data?.data));
          setQueuedWorkItems(response.data?.data?.slice(0, recordCount));
          setTotalRecordCount(response.data?.data?.length || 0);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setQueuedWorkItems([]);
        setRecordCount(0);
        sessionStorage.removeItem('queuedWorkItems')
      }
      else {
        handleShowToast(true, "Error", error?.response?.message);
      }
    }
  };


  /**PS_21 Function to handle the assigning of an work Item in the queued Work Items  */
  const handleAssignOperator = async (workItemUUID: string, operatorUUID: string, operatorName: string) => {
    try {
      const payload: AssignWorkItemPayLoad = {
        work_item_uuid: workItemUUID,
        operator_uuid: operatorUUID
      };
      const response: any = await assignWorkItem(payload);
      if (response?.status === 200) {
        handleShowToast(true, "Success", "Work Item Assigned Successfully");
        let sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems')
        let queuedWorkitems = JSON.parse(sessionWorkItems)
        const newQueuedWorkItems = queuedWorkitems?.map((item: any) => {
          if (item.work_item_uuid === workItemUUID) {
            return { ...item, is_assigned: true, user_uuid: operatorUUID, assigned_to: operatorName };
          }
          return item;
        });
        sessionStorage.setItem('queuedWorkItems', JSON.stringify(newQueuedWorkItems));
        setQueuedWorkItems(newQueuedWorkItems?.slice(0, recordCount));
      }
      else {
        handleShowToast(true, "Error", response?.data?.message);
      }
    }
    catch (error: any) {
      if (error?.response?.status === 409) {
        handleShowToast(true, "Error", "User Is Currently DND or Offline");
        return;
      }
      else {
        handleShowToast(true, "Error", error?.response?.data?.message);
      }
    }
  };


  /**PS_23 Function to handle the visibility of the operator dropdown  */
  const showOperatorsDropdown = async (workItem: WorkItems) => {
    const isSameDropdown = visibleDropdown === workItem.work_item_uuid;

    // Close the currently open dropdown if it's clicked again
    if (isSameDropdown) {
      setVisibleDropdown(null);
      setSearchTerm("");
      setShowActionDropdown(null)
      return;
    }
    // Reset search term for new dropdown selection
    setSearchTerm("");
    // Set the visible dropdown and fetch the available operators
    setVisibleDropdown(workItem.work_item_uuid);
    setShowActionDropdown(workItem.work_item_uuid)
    await fetchActiveOperators(workItem.work_item_uuid, workItem.site_uuid, workItem.customer_uuid);
  };

  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const fetchActiveOperators = async (workItemId: string, siteId: string, customerId: string) => {
    try {
      const payload: GetActiveOperatorsPayLoad = {
        work_item_uuid: workItemId,
        site_id: siteId,
        customer_id: customerId
      };
      const response: any = await getAvailableOperators(payload);
      if (response?.status === 200) {
        if (response?.data) {
          setAvailableOperators((prevState: any) => ({
            ...prevState,
            [workItemId]: response.data?.data || []
          }));

        }
      }
    } catch (error: any) {
      console.error(error, "error")
    }
  };

  /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
  const renderSortIcon = (key: string) => {
    const iconSrc = sortConfig.key === key
      ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
      : "img/sort-down-arrow.svg";
    return <img src={iconSrc} alt="sort-arrow" />;
  };

  /**Helper function to set the Search Term */
  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  /**PS_25 Fuction To Implement the drag and drop using react dnd and useRef */
  const CreateDragDrop = (index: number, moveItem: (dragIndex: number, hoverIndex: number) => void) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const [{ isDragging }, drag] = useDrag<DragItem, void, { isDragging: boolean }>({
      type: ItemType,
      item: () => {
        sessionStorage.setItem('initialDragIndex', index.toString());
        return { type: ItemType, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop<DragItem>({
      accept: ItemType,
      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();

        if (clientOffset) {
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

          if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
            return;
          }

          item.index = hoverIndex;
          moveItem(dragIndex, hoverIndex);
        }
      },
      drop: async (item: DragItem, monitor: DropTargetMonitor) => {
        const dragIndex = Number(sessionStorage.getItem('initialDragIndex'));
        const hoverIndex = item.index;
        if (dragIndex === null || hoverIndex === null || queuedWorkItems.length <= 1) return;
        const sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems');
        let updatedWorkItems: any[] = sessionWorkItems ? JSON.parse(sessionWorkItems) : [];
        const dragItem = updatedWorkItems[dragIndex];
        if (!dragItem) return;
        const payload: ReArrangeWorkItemPayLoad = {
          work_item_uuid: dragItem.work_item_uuid,
          current_position: dragIndex + 1,
          target_position: hoverIndex + 1,
        };

        if (payload.current_position === payload.target_position) {
          const sessionWorkItems = JSON.parse(sessionStorage.getItem('queuedWorkItems') || '[]');
          setQueuedWorkItems(sessionWorkItems.slice(0, recordCount));
          sessionStorage.removeItem('initialDragIndex');
          return
        }

        try {
          const response = await updateItemPriority(payload);
          if (response.status === 200) {
            const sessionWorkItems = JSON.parse(sessionStorage.getItem('queuedWorkItems') || '[]');
            sessionWorkItems.splice(dragIndex, 1);
            sessionWorkItems.splice(hoverIndex, 0, dragItem);
            sessionStorage.setItem('queuedWorkItems', JSON.stringify(sessionWorkItems));
            handleShowToast(true, 'Success', 'Work Items ReOrdered Successfully');
          } else {
            // If the update fails, reload from session
            const sessionWorkItems = JSON.parse(sessionStorage.getItem('queuedWorkItems') || '[]');
            setQueuedWorkItems(sessionWorkItems.slice(0, recordCount));
            handleShowToast(true, 'Error', response?.data?.message);
          }
        } catch (error: any) {
          console.error('Could not update item priorities:', error);
          handleShowToast(true, 'Error', error?.response?.data?.message);
          // Revert the state to the initial state
          const sessionWorkItems = JSON.parse(sessionStorage.getItem('queuedWorkItems') || '[]');
          setQueuedWorkItems(sessionWorkItems.slice(0, recordCount));
        }
        finally {
          sessionStorage.removeItem('initialDragIndex');
        }
      },
    });

    if (ref.current) {
      drag(drop(ref));
    }

    return { drag, drop, ref, isDragging };
  };

  const SortableList: React.FC<SortableListProps> = ({
    items,
    moveItem,
    CreateDragDrop,
    showOperatorsDropdown,
    invokeTakeWorkItem,
    handleAssignOperator,
    handleSearch,
    visibleDropdown,
    setVisibleDropdown,
    setShowActionDropdown,
    searchTerm,
    setSearchTerm,
    availableOperators,
  }) => {
    const createDragDropItem = useCallback((index: any, moveItem: any) => {
      return CreateDragDrop(index, moveItem);
    }, [CreateDragDrop]);

    if (items?.length === 0) {
      return (
        <NoRecordsFoundComponent
          colspan={5}
          mainContent="No Queued Work Items Found"
          subContent="Work items will be listed once created"
          columnHeight="40"
        />
      );
    }

    return (
      <tbody>
        {items?.map((item, index) => (
          <SortableItem
            key={item.work_item_uuid}
            item={item}
            index={index}
            moveItem={moveItem}
            CreateDragDrop={createDragDropItem}
            showOperatorsDropdown={showOperatorsDropdown}
            invokeTakeWorkItem={invokeTakeWorkItem}
            handleAssignOperator={handleAssignOperator}
            handleSearch={handleSearch}
            visibleDropdown={visibleDropdown}
            setVisibleDropdown={setVisibleDropdown}
            setShowActionDropdown={setShowActionDropdown}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            availableOperators={availableOperators}
            items={[]} />
        ))}
      </tbody>
    );
  }

  const SortableItem: React.FC<SortableListProps & { item: any; index: number }> = React.memo(({
    item,
    index,
    moveItem,
    CreateDragDrop,
    showOperatorsDropdown,
    invokeTakeWorkItem,
    handleAssignOperator,
    handleSearch,
    visibleDropdown,
    setVisibleDropdown,
    searchTerm,
    setSearchTerm,
    availableOperators,
    setShowActionDropdown,
  }) => {
    const { drag, drop, ref, isDragging } =
      CreateDragDrop(index, moveItem);

    drag(drop(ref));
    const actionDropdownRef = useRef<any>(null);
    const assignDropdownRef = useRef<any>(null);

    useEffect(() => {
      const handleAssignClickOutside = (event: MouseEvent) => {
        if (assignDropdownRef.current && !assignDropdownRef.current.contains(event.target as Node)) {
          setShowActionDropdown(null);
          setVisibleDropdown(null);
        }
      };

      const handleActionClickOutside = (event: MouseEvent) => {
        if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target as Node)) {
          setShowActionDropdown(null);
        }
      };

      document.addEventListener('mousedown', handleAssignClickOutside);
      document.addEventListener('mousedown', handleActionClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleAssignClickOutside);
        document.removeEventListener('mousedown', handleActionClickOutside);
      };
    }, [actionDropdownRef]);

    return (
      <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} className={item?.is_escalated ? "bg-warning" : ''}>
        <td className="align-middle">
          <img src="img/draggable-icon.svg" alt="draggable-icon" className="cursor-grab" />
        </td>
        <td>
          <span className="d-flex flex-column gap-1">
            <span
              data-bs-placement="right"
              title={item?.work_item_name?.length > 10 ? item.work_item_name : " "}
              {...(item?.work_item_name && item.work_item_name.length > 10 ? { 'data-bs-toggle': '' } : {})}
            >
              {item.work_item_name ? formatDescription(item.work_item_name, 10) : "-"}
            </span>
            <span className="font-10 color-sub-grey d-flex gap-1">
              {item.queued_on ? moment.utc(item.queued_on).format("MM/DD/YYYY HH:mm") : "-"}
              {(item.is_escalated) && (
                <span className="cursor-pointer position-relative tooltip-icon">
                  <img src="img/warning-icon.svg" alt="warning-icon" />
                  <span className="custom-tooltip-upward">
                    <span className="color-white font-10">
                      Escalated Item Back in Queue
                    </span>
                  </span>
                </span>
              )}
              {item.assigned_to && (
                <span className="cursor-pointer position-relative tooltip-icon">
                  <img src="img/assigned-icon.svg" alt="assigned-icon" />
                  <span className="custom-tooltip-upward">
                    <span className="color-white font-10">Assigned</span>
                  </span>
                </span>
              )}
            </span>
          </span>
        </td>
        <Timer
          receivedTime={item.queued_on}
          presentTime={new Date()}
          normalTime={item.expected_processing_time_sec}
          moderateTime={item.max_processing_time_sec}
        />
        <td>
          <span className="d-flex flex-column gap-1">
            <span
              data-bs-placement="right"
              title={item?.customer_name?.length > 10 ? item.customer_name : " "}
              {...(item?.customer_name && item.customer_name?.length > 10 ? { 'data-bs-toggle': '' } : {})}
            >
              {item.customer_name ? formatDescription(item.customer_name, 10) : "-"}
            </span>
            <span className="font-10 color-sub-grey">{item.site_name}</span>
          </span>
        </td>
        <td>{item.group_code ? item.group_code : "-"}</td>
        <td className="text-center align-middle">
          <span className="dropdown d-block">
            <button type="button" className="border-0 dropdown-btn" aria-expanded={showActionDropdown === item.work_item_uuid}
              onClick={() => {
                setShowActionDropdown(showActionDropdown === item.work_item_uuid ? null : item.work_item_uuid);
                setVisibleDropdown(null);
              }}>
              <img src="img/dots-icon.svg" alt="dots" />
            </button>
            {showActionDropdown === item.work_item_uuid

              && (<span className="dropdown-menu dropdown-menu-end work-item-menu px-2 show" style={{
                position: 'absolute',
                inset: '0px 0px auto auto',
                margin: '0px',
                transform: ((index === queuedWorkItems?.length - 1 || index === queuedWorkItems?.length - 2) && (queuedWorkItems?.length > 4)) ? 'translate3d(-13.6px, -88px, 0px)' : 'translate3d(-13.6px, 21.6px, 0px)',
              }} ref={actionDropdownRef}>
                <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular cust-border-bottom" onClick={() => { showOperatorsDropdown(item); setShowActionDropdown(null) }}>
                  <img src="img/user-icon-blue.svg" alt="user-icon" />Assign
                </span>
                <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular" onClick={() => { invokeTakeWorkItem(item.work_item_uuid); setShowActionDropdown(null) }}>
                  <img src="img/take-over-icon.svg" alt="arrows-icon" />Take Work Item
                </span>
              </span>)}
            {visibleDropdown === item.work_item_uuid && (
              <ul
                data-popper-placement="bottom-start" className="dropdown-menu work-item-menu w-215 border-0 px-3 show" style={{
                  position: 'absolute',
                  inset: '0px auto auto 0px',
                  margin: '0px',
                  transform: 'translate3d(-166px, 7.6px, 0px)',
                }} ref={assignDropdownRef}>
                <li className="d-flex justify-content-between align-items-center gap-2 py-2 font-13 font-regular list">
                  <span className="d-flex align-items-center gap-1">
                    <img src="img/user-icon-blue.svg" alt="user-icon" />
                    <span className="font-14 font-regular">Assign</span>
                  </span>
                  <img
                    src="img/close-icon-dd.svg"
                    alt="close-icon"
                    className="cursor-pointer"
                    onClick={() => { setVisibleDropdown(null); setShowActionDropdown(null) }} // Hide the dropdown menu
                  />
                </li>
                <li className="py-1 list">
                  <div className="input-group border bg-white w-100 rounded-2 mb-1">
                    <input
                      type="text"
                      className="form-control font-12 border-0 shadow-none bg-white"
                      placeholder="Search"
                      ref={inputref}
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)} // Update searchTerm directly
                    />
                    <span className="input-group-text border-0 bg-transparent">
                      <img src="img/search-icon.svg" alt="Search" />
                    </span>
                  </div>
                </li>
                <li>
                  {availableOperators[item.work_item_uuid] && availableOperators[item.work_item_uuid].length > 0 &&
                    availableOperators[item.work_item_uuid]
                      .filter((operator: Operator) =>
                        operator.operator_name.toLowerCase().includes(searchTerm.toLowerCase())
                      ).length > 0 ? (
                    <ul className="dropdown-y-scroll ps-0 d-flex flex-column gap-1">
                      {availableOperators[item.work_item_uuid]
                        ?.filter((operator: Operator) =>
                          operator.operator_name.toLowerCase().includes(
                            searchTerm.toLowerCase()
                          )
                        )
                        ?.map((operator: Operator) => (
                          <li
                            key={operator.operator_uuid}
                            className={`font-14 font-regular color-black-v2 d-flex align-items-center gap-1 p-1 list justify-content-between blue-assign`}
                            onMouseEnter={(e) => {
                              const buttonElement = e.currentTarget.querySelector('.primary-btn') as HTMLElement;
                              if (buttonElement) buttonElement.style.display = 'inline-block';
                            }}
                            onMouseLeave={(e) => {
                              const buttonElement = e.currentTarget.querySelector('.primary-btn') as HTMLElement;
                              if (buttonElement) buttonElement.style.display = 'none';
                            }}
                          >
                            <span className='d-flex align-items-center gap-2 flex-shrink-0'>
                              <span className="green-dot" hidden={operator.operator_status !== UserStatus.IDLE}></span>
                              <span className="red-dot" hidden={operator.operator_status === UserStatus.IDLE}></span>
                              <span className='font-12' data-bs-placement="right"
                                title={operator.operator_name?.length > 12 ? operator.operator_name : " "}
                                {...(operator.operator_name && operator.operator_name?.length > 12 ? { 'data-bs-toggle': '' } : {})}>{formatDescription(operator.operator_name, 12)}</span>
                            </span>
                            {
                              <button
                                className="primary-btn sm"
                                style={{ display: 'none' }}
                                disabled={item.user_uuid === operator.operator_uuid}
                                onClick={() =>
                                  handleAssignOperator(
                                    item.work_item_uuid,
                                    operator.operator_uuid,
                                    operator.operator_name
                                  )
                                }
                              >
                                Assign
                              </button>
                            }
                          </li>
                        ))}
                    </ul>
                  ) : (
                    <ul className="dropdown-y-scroll ps-0 d-flex flex-column gap-1">
                      <li>
                        <span className="font-12 font-regular">No available operators</span>
                      </li>
                    </ul>

                  )}
                </li>
              </ul>
            )}
          </span>
        </td>
      </tr>
    );
  });


  /** PS_26 Function to invoke the take workItem  */
  const invokeTakeWorkItem = async (workItemId: string) => {
    try {
      const payload: TakeWorkItemPayLoad = {
        work_item_uuid: workItemId
      };
      const response: any = await takeWorkItem(payload);
      if (response?.status === 200) {
        if (response?.data) {
          // Navigate to workItemScreen and pass response.data as state
          let json = {
            expected_processing_time_sec: response.data.data.expected_processing_time_sec,
            application_uuid: vemAppId,
            app_work_item_uuid: response.data.data.app_work_item_uuid,
            work_item_type: response.data.data.workItemType,
            max_processing_time_sec: response.data.data.max_processing_time_sec,
            requeue_time_sec: response.data.data.requeue_time_sec,
            customer_uuid: response.data.data.customer_id,
            customer_name: response.data.data.customer_name,
            site_uuid: response.data.data.site_uuid,
            site_name: response.data.data.site_name,
            lane_id: response.data.data.lane_id,
            lane_name: response.data.data.group_code,
            requeue_ack_time_sec: response.data.data.requeue_ack_time_sec,
            confidence_percentage: response.data.data.confidence_percentage,
            work_item_uuid: response.data.data.work_item_uuid,
            portal_data: response.data.data.portalJSON,
            startTime: new Date(),
            completed: false,
            CompletedDurationSec: null
          }
          navigate(`/workItem`, {
            state: json,
          });
        }
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error?.response?.data?.message);
    }
  };

  /**PS_53 Function for handling toast */
  const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
    setShowToast(showToast);
    setToastType(toastType);
    setToastMessage(toastMessage);
  };

  /**PS_34 Function to handle the loading of more queued Work Items */
  const handleLoadMore = () => {
    setRecordCount(prevCount => prevCount + 10);
    let sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems')
    let workItems = JSON.parse(sessionWorkItems);
    setQueuedWorkItems(workItems?.slice(0, recordCount + 10))
  };

  /**PS-24 Function to handle the moving of an item while dragging */
  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    const dragItem = queuedWorkItems[dragIndex];
    if (!dragItem || queuedWorkItems?.length <= 1) return;

    const updates = [...queuedWorkItems];
    updates.splice(dragIndex, 1);
    updates.splice(hoverIndex, 0, dragItem);
    setQueuedWorkItems(updates.slice(0, recordCount));
  }, [queuedWorkItems, recordCount]);

  return <>
    {/* Queued Work Items */}
    <h2 className="font-16 font-bold color-black">Queued Work Items  {totalRecordCount > 0 ? (
      <span> ({totalRecordCount}) </span>
    ) : ''}</h2>
    <div className="table-responsive theme-table bg-white w-100 table-y-scroll rows-6 rounded-3 mb-3" id="scrollIDGRC07">
      <InfiniteScroll
        dataLength={recordCount}
        next={handleLoadMore}
        hasMore={recordCount < totalRecordCount}
        scrollThreshold={0.6}
        loader={true}
        scrollableTarget="scrollIDGRC07"
      >
        <table className="table mb-0">
          <colgroup>
            <col span={1} width="1%" />
          </colgroup>
          <thead className='sticky-top table-header-index'>
            <tr className="alter">
              <th />
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount <= 1 ? (
                      <span>Work Item</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                        Work Item {" "}{renderSortIcon('work_item_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>
                Queued Time
              </th>
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount <= 1 ? (
                      <span> Customer &amp; Site</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('customer_name')}>
                        Customer &amp; Site {" "}{renderSortIcon('customer_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount <= 1 ? (
                      <span>  Lane / CHE</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('group_code')}>
                        Lane / CHE {" "}{renderSortIcon('group_code')}
                      </span>
                    )}
                  </>
                )}
              </th>
              {totalRecordCount > 0 && (<th className="text-center">Action</th>)}
            </tr>
          </thead>
          <DndProvider backend={HTML5Backend}>
            <SortableList
              items={queuedWorkItems}
              moveItem={moveItem}
              CreateDragDrop={CreateDragDrop}
              showOperatorsDropdown={showOperatorsDropdown}
              invokeTakeWorkItem={invokeTakeWorkItem}
              handleAssignOperator={handleAssignOperator}
              handleSearch={handleSearch}
              visibleDropdown={visibleDropdown}
              setVisibleDropdown={setVisibleDropdown}
              setShowActionDropdown={setShowActionDropdown}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              availableOperators={availableOperators}
            />
          </DndProvider>
        </table>
      </InfiniteScroll>
    </div>
    {showToast && <ToastMessage props={
      {
        setIsToast: setShowToast,
        toastMessage: toastMessage,
        toastType: toastType
      }
    } />}
  </>
}


export default QueuedWorkItems