/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/**PS_UA_02 - Initializing the necessary imports ,services and functions */
/**PS_LIV_UI10 -PS_LIV_UI_11  Importing of the Packages for the Excel Downloading*/

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ToastMessage from "../../reusable_components/toast";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import { fetchUserActivity, IndividualUserActivityApi } from "../../services/user_activity_service";
import { useNavigate, useLocation } from "react-router-dom";
import { IndividualUserActivityRequest, SortConfig, IndividualKPIs, IndividualUserActivityFilter, IndividualBreakDetail, UserActivityPayLoad } from "../../Interfaces/user_activity_interface";
import { encryptStorage, encryptKeys, userActivityNotificationTopics, sessionStorageKeyNames, formatShiftDetails, determineColorCode, formatStatusName, MinimumFilterDate, formatDescription } from "../../constant";
import { applicationAbbrievation, UserStatus, WorkItemStatus } from "../../enums/enums";
import { ShiftScroll } from "../../reusable_components/shift_scroll_component";
import moment from "moment";
import WebsocketClient from "../../client/websocket";
import { FullscreenLoader } from "../loader";
import exportActivityData from "../../reusable_components/exportExcelComponent";


/**PS_UA_02 - Initializing the necessary states  */
export const IndividualUserActivity: React.FC = () => {
    const [individualUserActivityData, setIndividualUserActivityData] = useState<any[]>([]);
    const [displayedData, setDisplayedData] = useState<any[]>([]);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [advanceFilters, setAdvanceFilters] = useState<IndividualUserActivityFilter>({ from_Date: "", to_Date: '' });
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState<boolean>(false);
    const [kpis, setKpis] = useState<IndividualKPIs | null>(null);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [recordCount, setRecordCount] = useState<number>(10);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [passiveSearchTerm, setPassiveSearchTerm] = useState<string>('')

    let userName = location?.state?.user_name ? location?.state?.user_name : ""
    let userId = location?.state?.user_id ? location?.state?.user_id : ""
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
    const vemAppId = userDetails ? userDetails?.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-" : null;
    const websocket = useRef<WebSocket>()


    /**PS_LIV_UI_05 - Processing the received socket message and checking for this particualar userId for duplicacy  */
    const handleSocketMessage = useCallback((event: any) => {
        let message = JSON.parse(event.data);
        try {
            let individualUserData = message.data;
            if (individualUserData?.workitem_json?.status === WorkItemStatus?.Completed && individualUserData?.user_uuid === userId && isApplyFilterClicked === false) {
                setIndividualUserActivityData(prevData => {
                    const existingIndex = prevData?.findIndex(item => item?.work_item_uuid === individualUserData?.workitem_json?.work_item_uuid);
                    if (existingIndex === -1) {
                        let updatedData = [individualUserData?.workitem_json, ...prevData];
                        sessionStorage.setItem(sessionStorageKeyNames?.individualUserActivitySessionKey, JSON.stringify(updatedData));
                        let filteredItems = updatedData?.filter((item) =>
                            item.work_item_name?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase())
                        );
                        if (sortConfig?.key !== '') {
                            filteredItems?.sort((a: any, b: any) => {
                                const aValue = a[sortConfig.key]?.toString().toLowerCase() || '';
                                const bValue = b[sortConfig.key]?.toString().toLowerCase() || '';

                                if (aValue < bValue) {
                                    return sortConfig?.direction === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return sortConfig?.direction === 'asc' ? 1 : -1;
                                }
                                return 0;
                            });
                        }
                        setRecordCount(updatedData?.length);
                        return filteredItems;
                    }
                    return prevData;
                });
            }

            if (individualUserData?.user_uuid === userId) {
                setKpis(individualUserData?.kpi[0]);
            }
        } catch (error) {
            console.error("Parsing error:", error);
        }
    }, [userId, searchTerm, sortConfig, isApplyFilterClicked]); // Ensure required dependencies are included

    const handleOnError = useCallback((error: any) => {
        console.log("an error occured in websocket:", error);
    }, []);

    /**PS_LIV_UI_01 - Instantiate WebSocketService with options (topics like 'IndividualUserActivity', callbacks) */
    useEffect(() => {
        const socket = WebsocketClient(userId, [userActivityNotificationTopics.getIndividualUserActivityTopic]);
        websocket.current = socket;
        socket.onmessage = handleSocketMessage;
        socket.onerror = handleOnError;
        return () => {
            if (websocket.current) {
                websocket.current.close()
            }
        };
    }, [handleOnError, handleSocketMessage, userId]);

    useEffect(() => {
        userIndividualActivityInitialLoad()
    }, []);   

    /*PS_UA_11 - Function to update the Individual User Activity Data whenever the sort ,search and individualUserActivity changes */
    useEffect(() => {
        if (!isApplyFilterClicked) {
            setDisplayedData([]);
        }
        loadMoreData();

    }, [sortConfig, searchTerm, individualUserActivityData]);    

     /**PS_PS_UA_02 - Getting the details in the initial load */
     const userIndividualActivityInitialLoad = async () => {
        await getUserActivityData(true, true);
        await fetchIndividualKPI();
    }

    const formatShiftDetails = (input: IndividualKPIs): any => {
        return {
            shift_start_time: input.shift_start_time,
            shift_end_time: input.shift_end_time,
            break_ranges: input.break_details.map((breakDetail: IndividualBreakDetail) => ({
                break_start_time: breakDetail.break_start_time,
                duration_minutes: breakDetail.break_duration.toString()
            }))
        };
    };

    /**PS_03 - PS_21 Function to fetch the Individual User Activity KPI  */
    const fetchIndividualKPI = async () => {
        try {
            const payload: UserActivityPayLoad = {
                application_uuid: vemAppId,
                filter: {
                    user_uuid:userId
                }
            };
            const response: any = await fetchUserActivity(payload);
            if (response?.status === 200) {
                if (response?.data) {
                    setKpis(response.data?.data[0] || []);
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_UA_15 - useMemo function to filter and sort the Data and update the userActivitydata */
    const filteredAndSortedData = useMemo(() => {
        if (isApplyFilterClicked) return [];
        let filteredItems = individualUserActivityData?.filter((item: any) =>
            item.work_item_name?.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
        if (sortConfig.key !== '') {
            filteredItems?.sort((a: any, b: any) => {
                const aValue = a[sortConfig.key]?.toString().toLowerCase() || '';
                const bValue = b[sortConfig.key]?.toString().toLowerCase() || '';

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredItems;
    }, [individualUserActivityData, sortConfig, searchTerm, isApplyFilterClicked]);


    /**SQ_EX_22.2 - Function to handle the loadMore Data */
    const loadMoreData = () => {
        if (isApplyFilterClicked) {
            getUserActivityData();
            return;
        }
        setDisplayedData(prevData => [...prevData, ...filteredAndSortedData?.slice(prevData.length, prevData.length + 10)]);
    };

    /**SQ_EX_12.0 - Function to sort the data */
    const handleSort = (key: string) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    /*SQ_EX_22.3 - Helper function to set the search Term */
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassiveSearchTerm(event.target.value)
    };

    const applySearch = (isCancel: boolean = false) => {
        setSearchTerm(isCancel ? '' : passiveSearchTerm); // Set searchTerm to passiveSearchTerm
    };

    /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
    const renderSortIcon = (key: string) => {
        const iconSrc = sortConfig.key === key
            ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
            : "img/sort-down-arrow.svg";
        return <img src={iconSrc} alt="sort-arrow" />;
    };


    /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
    const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAdvanceFilters(prev => ({ ...prev, [e.target.id]: e.target.value }));
    };


    /*SQ_EX_10.3 - Function to fetch the current userActivity Data */
    const getUserActivityData = async (isCancel: boolean = false, isInitial: boolean = false) => {

        let individualUserActivityPayload: IndividualUserActivityRequest = {
            user_uuid: userId,
            filter: {
                from_Date: isCancel || isInitial ? "" : advanceFilters.from_Date ,
                to_Date: isCancel || isInitial ? "" : advanceFilters.to_Date 
            },
            sort: {
                field: isInitial ? "" : sortConfig.key,
                order: isInitial ? "asc" : sortConfig.direction
            },
            search: searchTerm,
            limit: isInitial ? undefined : recordCount
        }

        try {
            setIsLoading(true)
            const response: any = await IndividualUserActivityApi(individualUserActivityPayload);
            if (response?.code === 200) {
                if (isInitial ) {
                    setIndividualUserActivityData(response.data);
                    sessionStorage.setItem(sessionStorageKeyNames.individualUserActivitySessionKey, JSON.stringify(response.data));
                } else {
                    setDisplayedData(response?.data);
                    setRecordCount(response?.meta.total);
                    setIsApplyFilterClicked(true)
                }
            }
            else if (response.response.data.code === 404) {
                setRecordCount(0);
                setDisplayedData([]);
            }
        }
        catch (error: any) {
            console.log("error reccdived", error)
            handleShowToast(true, "Error", error.message);
        }
        finally{
            setIsLoading(false)
        }
    };

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    /**PS_LIV_UI_25 - Function to export Individual user activity data */
    const exportUserActivityData = () => {
        // Example transformations for individual user activity

        // Define headers for individual user activity
        const individualHeaders = [
            { label: 'Work Item', key: 'workItemName', width: 30 },
            { label: 'Processed Time', key: 'processedTime', width: 25 },
            { label: 'Customer', key: 'customerName', width: 30 },
            { label: 'Site', key: 'siteName', width: 30 },
            { label: 'Lane/CHE', key: 'laneChe', width: 15 },
            { label: 'Date', key: 'date', width: 15 },
            { label: 'Time', key: 'time', width: 20 },
        ];

        const transformedData = displayedData?.map(activity => ({
            workItemName: activity.work_item_name,
            processedTime: activity.completed_duration_sec,
            customerName: activity.customer_name || '-',
            siteName: activity.site_name || '-',
            laneChe: activity.group_code || '-',
            date: activity?.completed_on ? moment.utc(activity.completed_on).format('MM/DD/YYYY') : "-",
            time: activity?.completed_on ? moment.utc(activity.completed_on).format('HH:mm') : "-",
        }));


        // Example success and error callbacks
        const handleSuccessExport = () => {
            handleShowToast(true, "Success", "User Details exported successfully");
        };

        const handleErrorExport = () => {
            handleShowToast(false, "Error", "Failed to export User Details");
        };

        // Invoke exportActivityData with the correct parameters
        exportActivityData({
            data: transformedData,
            headers: individualHeaders,
            fileName: 'User Details.xlsx',
            sheetName: 'User Details',
            successCallback: handleSuccessExport,
            errorCallback: handleErrorExport
        });
    };

    const cancelFilter = (() => {
        setAdvanceFilters({ from_Date: '', to_Date: '' });
        getUserActivityData(true);
        setIsApplyFilterClicked(false);
    })

    const handlecloseFilter = () =>{      
        setShowFilter(false);
        if(!isApplyFilterClicked){
            setAdvanceFilters({ from_Date: '', to_Date: '' });
        }
    }

    const userActivityBinding = () => {
        if (displayedData?.length === 0) {
            return <NoRecordsFoundComponent
                colspan={8}
                mainContent="No WorkItems Found"
                subContent="WorkItems will be listed once completed by user"
                columnHeight='40'
            />
        }
        return (displayedData?.map?.((activity: any, index: number) => {
            // Determine the color code
            const colorCode = determineColorCode(activity.completed_duration_sec, activity.expected_processing_time_sec, activity.max_processing_time_sec);
            // Update the DOM
            return (<>
                <tr>
                    <td className="align-middle" data-bs-placement="right"
                        title={activity?.work_item_name?.length > 20 ? activity?.work_item_name : " "}
                        {...(activity?.work_item_name && activity?.work_item_name?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}>{activity?.work_item_name ? formatDescription(activity?.work_item_name, 20) : "-"}</td>
                    <td className="align-middle">
                        <span className={`progress-timer ${colorCode} d-flex align-items-center gap-1`}>
                            <img src="img/timer-icon.svg" alt="timer" width={10} />
                            <span className="font-bold color-white">{activity.completed_duration_sec} sec</span>
                        </span>
                    </td>
                    <td className="align-middle" data-bs-placement="right"
                        title={activity?.customer_name?.length > 20 ? activity?.customer_name : " "}
                        {...(activity?.customer_name && activity?.customer_name?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}>{activity?.customer_name ? formatDescription(activity?.customer_name, 20) : "-"}</td>
                    <td className="align-middle" data-bs-placement="right"
                        title={activity?.site_name?.length > 20 ? activity?.site_name : " "}
                        {...(activity?.site_name && activity?.site_name?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}>{activity?.site_name ? formatDescription(activity?.site_name, 20) : "-"}</td>
                    <td className="align-middle" data-bs-placement="right"
                        title={activity?.group_code?.length > 20 ? activity?.group_code : " "}
                        {...(activity?.group_code && activity?.group_code?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}>{activity?.group_code ? formatDescription(activity?.group_code, 20) : "-"}</td>
                    <td className="align-middle"> {activity?.completed_on ? moment.utc(activity.completed_on).format('MM/DD/YYYY HH:mm') : "-"}</td>
                </tr>
            </>)
        })
        )
    }
    return (
        <>
            {/* Header */}
            {/* Content Container */}
            <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
                {isLoading ? (
                    <FullscreenLoader />
                ) : (
                    < div className="p-3">
                        {/* Breadcrumbs */}
                        <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a>Operations</a>
                                </li>
                                <li className="breadcrumb-item cursor-pointer" aria-current="page" onClick={() => {
                                    navigate(`/operations`, {
                                        state: {
                                            currentTab: 'User Activity'
                                        },
                                    });
                                }}>
                                    User Activity
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {userName ? userName : ""}
                                </li>
                            </ol>
                        </nav>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-center gap-2">
                                <a className="d-flex align-items-center cursor-pointer">
                                    <img src="img/left-arrow-icon.svg" alt="arrow-icon"
                                        onClick={() => { navigate(`/operations`, {
                                            state: {
                                                currentTab: 'User Activity'
                                            },
                                        }); }} />
                                </a>
                                <h2 className="font-16 font-bold color-black m-0">{userName ? userName : ""}</h2>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                    <input
                                        type="search"
                                        className="form-control font-semibold border-0 shadow-none font-14"
                                        placeholder="Search"
                                        aria-label="Search"
                                        value={passiveSearchTerm}
                                        onChange={(e) => handleSearch(e)}
                                        onKeyUp={(event) => event.key === "Enter" ? applySearch() : null}
                                    />
                                    <span className="input-group-text border-0 bg-transparent cursor-pointer" onClick={() => {
                                        applySearch()
                                    }}>
                                        <img src="img/search-icon.svg" alt="search" />
                                    </span>
                                </div>
                                <div className="dropdown filter-dpd">
                                    <button
                                        className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                        type="button"
                                        data-bs-auto-close="false"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <img
                                            src="img/filter-icon-blue.svg"
                                            alt="filter"
                                            className="filter-img"
                                        />
                                        <span>Filter</span>
                                    </button>
                                    <div style={{
                                        position: 'absolute',
                                        right: '1%'
                                    }} className={` user-activity-filter dropdown-menu border-0 custom-shadow dropdown-menu-end  filter-dpd p-3 mt-2 ${showFilter ? 'show' : ''}`}
                                        data-popper-placement="bottom-end"
                                    >
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="m-0 font-bold font-16 color-black-v1">
                                                Advanced Filter
                                            </h3>
                                            <button className="bg-transparent border-0" type="button" onClick={() => { handlecloseFilter() }}>
                                                <img src="img/close-icon.svg" alt="close" />
                                            </button>
                                        </div>
                                        <h4 className="mb-3 font-semibold font-14 color-black-v1">
                                            Date & Time
                                        </h4>
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <label
                                                    htmlFor="from_Date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="date"
                                                    id="from_Date"
                                                    value={advanceFilters.from_Date}
                                                    min={MinimumFilterDate}
                                                    max={advanceFilters.to_Date ? advanceFilters.to_Date : new Date().toISOString().split('T')[0]}
                                                    className="form-control font-12 theme-input shadow-none"
                                                    onChange={(e) => { onChangeFilter(e) }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label
                                                    htmlFor="to_Date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="date"
                                                    id="to_Date"
                                                    value={advanceFilters.to_Date}
                                                    min={advanceFilters.from_Date ? advanceFilters.from_Date : MinimumFilterDate}
                                                    max={new Date().toISOString().split('T')[0]}
                                                    className="form-control font-12 theme-input shadow-none"
                                                    onChange={(e) => { onChangeFilter(e) }}
                                                />
                                            </div>
                                            <div className="co1-12">
                                                <div className="d-flex justify-content-end gap-3 mt-5">
                                                    <button className="secondary-btn rounded-3" type="button" onClick={() => { cancelFilter() }}>
                                                        <span className="d-inline-block my-1">Clear</span>
                                                    </button>
                                                    <button
                                                        className="primary-btn rounded-3"
                                                        type="button"
                                                        disabled={Object.values(advanceFilters).some((data: any) => data === "")}
                                                        onClick={() => { getUserActivityData(); setShowFilter(false);setIsApplyFilterClicked(true) }}                                              >
                                                        <span className="d-inline-block my-1">Apply</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="shadow-btn export rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-dark-green lh-1 px-3"
                                    type="button" onClick={() => { exportUserActivityData() }}
                                >
                                    <img src="img/excel-icon.svg" alt="export" />
                                    <span>Export</span>
                                </button>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between my-3">
                            <div className="col-3">
                                <div className="op-status-card py-1">
                                    <span>
                                        <img src="img/shift-icon.svg" alt="clock-icon" />
                                        <span className="font-12 font-semibold color-grey-v2">
                                            {" "}Shift & Break
                                        </span>
                                    </span>
                                    <div className="w-50">
                                        <h3 className="font-12 font-semibold mb-1">{kpis?.shift_name ? kpis?.shift_name : " "}</h3>
                                        <div className="d-flex align-items-start">
                                            {kpis && (
                                                <ShiftScroll shiftData={formatShiftDetails(kpis)} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="op-status-card">
                                    <span>
                                        <img src="img/dnd-icon.svg" alt="minus-icon" />
                                        <span className="font-12 font-semibold color-grey-v2">{" "}DND</span>
                                    </span>
                                    <span className="font-18 font-bold color-black-v1">  {kpis?.total_dnd_time_minutes || 0}
                                        {kpis?.total_dnd_time_minutes === 1 || kpis?.total_dnd_time_minutes === 0 ? ' min' : ' mins'}</span>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="op-status-card position-relative">
                                    <span className="font-8 font-semibold time-report">
                                        Last 12 hrs
                                    </span>
                                    <span>
                                        <img src="img/target-icon.svg" alt="target-icon" />
                                        <span className="font-12 font-semibold color-grey-v2">
                                            {" "}Target Percentage
                                        </span>
                                    </span>
                                    <span className="font-18 font-bold color-black-v1">  {(kpis?.target_completion_percentage || 0).toFixed(2)}% 
                                    </span>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="op-status-card">
                                    <span>
                                        <img src="img/status-icon.svg" alt="close-icon" />
                                        <span className="font-12 font-semibold color-grey-v2">
                                            {" "}Status
                                        </span>
                                    </span>
                                    <span
                                        style={{ width: 'auto' }}
                                        className={`operator-status ${kpis?.current_status ? kpis.current_status === UserStatus.BREAK ? 'break-time' : (kpis.current_status === UserStatus.IDLE || kpis.current_status === UserStatus.ACTIVE) ? 'idle' : kpis.current_status === UserStatus.DND ? 'dnd' : 'working' : 'offline'} font-12 font-semibold`}
                                    >  {kpis?.current_status ? formatStatusName(kpis.current_status) : "Offline"}

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-12">
                            <div className="table-responsive theme-table bg-white w-100 table-y-scroll custom rounded-3" id="userActivityTableContainer">
                                <InfiniteScroll
                                    dataLength={displayedData.length}
                                    next={() => loadMoreData()}
                                    hasMore={!isApplyFilterClicked ? (displayedData?.length < filteredAndSortedData?.length) : (displayedData?.length < recordCount)}
                                    loader={<></>}
                                    scrollableTarget="userActivityTableContainer"
                                >
                                    <table className="table mb-0">
                                        <thead className="sticky-top table-header-index">
                                            <tr className="alter">
                                                <>
                                                    <th>
                                                        {displayedData.length < 2 ? (
                                                            <span>Work Item</span>
                                                        ) : (
                                                            <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                                                                Work Item {" "}{renderSortIcon('work_item_name')}
                                                            </span>
                                                        )}
                                                    </th>
                                                </>
                                                <th>
                                                    Processed Time
                                                </th>
                                                <>
                                                    <th>
                                                    <span>Customer</span>
                                                    </th>
                                                </>
                                                <>
                                                    <th>
                                                     <span>Site</span>                                                      
                                                    </th>
                                                </>
                                                <>
                                                    <th>
                                                        {displayedData.length < 2 ? (
                                                            <span>Lane/CHE</span>
                                                        ) : (
                                                            <span className="cursor-pointer" onClick={() => handleSort('group_code')}>
                                                                Lane/CHE {" "}{renderSortIcon('group_code')}
                                                            </span>
                                                        )}
                                                    </th>
                                                </>
                                                <>
                                                    <th>
                                                        {displayedData.length < 2 ? (
                                                            <span>Date & Time</span>
                                                        ) : (
                                                            <span className="cursor-pointer" onClick={() => handleSort('completed_on')}>
                                                                Date & Time {" "}{renderSortIcon('completed_on')}
                                                            </span>
                                                        )}
                                                    </th>
                                                </>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userActivityBinding()}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                            <div className="mt-3">
                                <span className="font-14 font-regular color-grey-v4">
                                    # of Records: <span className="font-semibold color-black ">{displayedData?.length}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div >
            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />
            }
        </>
    )
}
