import Client from "../client/client";
import { baseURL, equipmentCorrection, userActivityService, workItems } from "../constant";
import {
  CheckVisitMatchupRequest,
  CompletedWorkItemRequest,
  EscalateToLaneRequest,
  FetchLayoutJsonRequest,
  RequeueWorkItemRequest,
  UpdateUserStatusRequest,
} from "../Interfaces/work_item_interface";

export const fetchLayoutJsonService = async (
  fetchLayoutJson: FetchLayoutJsonRequest
) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.fetchLayoutJson}`,
      data: fetchLayoutJson,
    };
    const response = await Client(config)
    return response
  } catch (error: any) {
    return error;
  }
}

export const escalatetoLaneService = async (payload: EscalateToLaneRequest) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.escalatetoLane}`,
      data: payload,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const getStatusService = async () => {
  try {
    const config = {
      method: "GET",
      url: `${baseURL}${equipmentCorrection.getStatus}`,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const updateUserActivityStatusService = async (
  payload: UpdateUserStatusRequest
) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.updateUserActivityStatus}`,
      data: payload,
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const completedWorkItemService = async (
  payload: CompletedWorkItemRequest
) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.completeWorkItem}`,
      data: payload,
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const requeueWorkItemService = async (payload: RequeueWorkItemRequest) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.requeueWorkItem}`,
      data: payload,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const checkWorkItemService = async (payload: CheckVisitMatchupRequest) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.visitMatchupCheck}`,
      data: payload,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const getOperatorTeamDetailsService = async (userUUID: string) => {
  try {
    const config = {
      method: "GET",
      url: `${baseURL}${userActivityService.user}${userUUID}${userActivityService.getTeamDetails}`,
      data: {},
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const getAvailableWorkItemsService = async () => {
  try {
    const config = {
      method: "GET",
      url: `${baseURL}${workItems.getAvailableWorkItems}`,
      data: {},
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}
