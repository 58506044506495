import { baseURL, equipmentCorrection, } from "../constant";

function WebsocketClient(userId: string, topics: string[], userUUID?: string): WebSocket {
  const queryParams = topics.map(topic => `topics=${encodeURIComponent(topic)}`).join("&");
  const url: any = baseURL
  const wsURL = url.replace(/^http(s)?:\/\//, "wss://");
  const baseWsURL = `${wsURL}${equipmentCorrection.websocket}?subscriptionId=${encodeURIComponent(userId)}${userUUID?`&userUUID=${userUUID}`:``}&${queryParams}`
  const conn = new WebSocket(baseWsURL);
  return conn;
}

export default WebsocketClient;
