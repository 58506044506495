/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { getBreakShiftAndTimeZonesService } from "../../services/operator_service";
import { ShiftScroll } from "../../reusable_components/shift_scroll_component";
import { BreakTemplate, CreateTeamsRequest, CustomerSite, CustomerSitesRequest, fetchProfileImageRequest, fetchUserNamesRequest, fetchUserNamesResponse, GetTeamByIdRequest, SelectedUser, ShiftTemplate, TeamUsersRequest, UpdateTeamRequest, WorkItem, WorkItemListProps, WorkItemProps } from "../../Interfaces/team_interface";
import { getProfileImageService, getTeamByIdService, getUserService, postTeamService, updateTeamService } from "../../services/team_service";
import { getCustomerWorkItemAndApplicationsService } from "../../services/work_items_sla_service";
import { encryptStorage, encryptKeys } from "../../constant";
import { applicationAbbrievation, TeamPrivilege } from "../../enums/enums";
import { useLocation, useNavigate } from "react-router-dom";
import ToastMessage from "../../reusable_components/toast";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FullscreenLoader } from "../loader";

const TeamForm = () => {

    // Retrieve stored user details from encrypted storage
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetail = userDetailsString ? JSON.parse(userDetailsString) : null;
    const vemAppId = userDetail ? userDetail?.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-" : null;
    const location = useLocation();
    const debounce = useRef<any>()
    let mode = location?.state?.mode ? location?.state?.mode : "Add"
    let team_uuid = location?.state?.mode ? location?.state?.team_uuid : "-"

    const navigate = useNavigate();


    const [shiftTemplates, setShiftTemplates] = useState<ShiftTemplate[]>(([
        {
            shift_template_uuid: "",
            shift_name: "",
            shift_start_time: "00:00:00",
            shift_end_time: "00:00:00",
            time_zone_uuid: ""
        }
    ]));

    const [breakTemplates, setBreakTemplates] = useState<BreakTemplate[]>([
        {
            "break_template_uuid": "",
            "break_name": "",
            "break_timings": [
                {
                    "break_timing_uuid": "",
                    "break_start_time": "",
                    "duration_minutes": 0,
                }
            ]
        }
    ]);

    const [teamData, setTeamData] = useState<CreateTeamsRequest>({
        team_name: "",
        team_status: 1,
        team_description: "",
        handling_method: TeamPrivilege.Push,
        shift_template_uuid: "",
        break_template_uuid: "",
        user_uuids: [],
        work_items: [],
        customers: [],
        application_uuid: vemAppId
    })
   
    const [initialFetchedTeamData, setInitialFetchedTeamData] = useState<CreateTeamsRequest>(teamData); // Initial data


    const [usersError, setUsersError] = useState<string>('')

    const [teamDataError, setTeamDataError] = useState<CreateTeamsRequest>({
        // validationstatus: true,
        team_name: "",
        team_status: 1,
        team_description: "",
        handling_method: TeamPrivilege.NoPush,
        shift_template_uuid: "",
        break_template_uuid: "",
        user_uuids: [],
        work_items: [],
        customers: [],
        application_uuid: vemAppId,
    })

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');

    const [userInput, setUserInput] = useState<string>('');

    const [userDropDownDetails, setUserDropDownDetails] = useState<fetchUserNamesResponse[]>([]);

    const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
    const [initialFetchedUsers, setInitialFetchedUsers] = useState<TeamUsersRequest[]>([]);


    const [customers, setCustomers] = useState<CustomerSite[]>([]);

    const [selectedCustomers, setSelectedCustomers] = useState<CustomerSite[]>([]);
    const [initialCustomers, setInitialCustomers] = useState<CustomerSitesRequest[]>([]);
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(true);





    const [workItems, setWorkItems] = useState<WorkItem[]>([]);

    const [selectedWorkItems, setSelectedWorkItems] = useState<WorkItem[]>([]);
    const [initialWorkItems, setInitialWorkItems] = useState<WorkItem[]>([])

    const [scrollComponentData, setScrollComponentData] = useState<any>({
        shift_start_time: "00:00:00",
        shift_end_time: "00:00:00",
        break_ranges: []
    })
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const getShiftBreakAndTimeZonesData = useCallback(async () => {
        const payload = {
            break_details: true,
            shift_details: true,
            time_zone_details: true
        }
        try {
            const response: any = await getBreakShiftAndTimeZonesService(payload)
            if (response?.status === 200) {
                setBreakTemplates(response?.data?.data?.break_templates);
                setShiftTemplates(response?.data?.data?.shift_templates);
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
    }, [])

    const getCustomersAndWorkItems = useCallback(async (application_uuid: string) => {
        const payload: any = {
            applications: false,
            customer_site: true,
            work_item: true,
            application_uuid: application_uuid,
            eliminate_associated_customer_and_sites: false
        };
        try {
            const response: any = await getCustomerWorkItemAndApplicationsService(payload);
            if (response?.status === 200) {
                setCustomers(response?.data?.data?.customer_sites);
                setWorkItems(response?.data?.data?.work_items);
            }
            else {
                handleShowToast(true, "Error", response?.data?.message);
                console.error(response.message);
            }
        } catch (err: any) {
            console.error(err);
        }
    }, []);




    // Function to fetch all teams
    const fetchTeamDetails = async () => {
        try {
            const payload: GetTeamByIdRequest = {
                application_uuid: vemAppId,
                team_uuid: team_uuid || ""
            }

            const response: any = await getTeamByIdService(payload);
            if (response?.code === 200) {
                if (response?.data?.team_work_items) {
                    const workItemsWithIsDeleted = response.data.team_work_items?.map((item: any) => ({
                        ...item,
                        is_deleted: false
                    }));
                    setSelectedWorkItems(workItemsWithIsDeleted);
                    setInitialWorkItems(workItemsWithIsDeleted)
                }

                if (response?.data?.customer_site_data) {
                    const customersWithIsDeleted = response.data.customer_site_data?.map((item: any) => ({
                        ...item,
                        is_deleted: false
                    }));
                    setSelectedCustomers(customersWithIsDeleted);
                    setInitialCustomers(customersWithIsDeleted); // save initial customers

                }

                if (response?.data?.team_users) {
                    const usersWithIsDeleted = response.data.team_users?.map((item: any) => ({
                        ...item,
                        is_deleted: false
                    }));
                    setSelectedUsers(usersWithIsDeleted);
                    setInitialFetchedUsers(usersWithIsDeleted);
                }

                const selectedShift: any = shiftTemplates?.find((st: any) => st.shift_template_uuid === response?.data?.shift_template_uuid);
                transformShiftData(selectedShift, "shift");

                const selectedBreak: any = breakTemplates?.find((bt: any) => bt.break_template_uuid === response?.data?.break_template_uuid);
                transformShiftData(selectedBreak, "break");
                
                setTeamData(response?.data);
                setInitialFetchedTeamData(response.data);

            } else {
                handleShowToast(true, "Error", response?.data?.message);
                console.error(response.message);
            }
        } catch (error: any) {
            console.error("Error fetching teams:", error);
        }
    }   

    /**PS_PS_UA_02 - Getting the details in the initial load */
    const fetchInitialCreateTeamsLoad = useCallback(async () => {
        await getShiftBreakAndTimeZonesData();
        await getCustomersAndWorkItems(vemAppId);

    }, [getCustomersAndWorkItems, getShiftBreakAndTimeZonesData, vemAppId])


    useEffect(() => {       
     fetchInitialCreateTeamsLoad();
    }, []); 

    useEffect(() => {
        if (shiftTemplates?.length > 0 && breakTemplates?.length > 0 && mode !== "Add") {
            fetchTeamDetails(); 
        }
    }, [shiftTemplates, breakTemplates, mode]);


    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    // Helper function to get shift name from shift_template_uuid
    const getShiftName = (shift_template_uuid: string) => {
        const shift = shiftTemplates?.find(s => s.shift_template_uuid === shift_template_uuid);
        return shift ? shift.shift_name : "Select";
    };

    // Function to handle the shift template change
    const handleShiftTemplateChange = (shift_template_uuid: string) => {
        setTeamData((prev: any) => ({
            ...prev,
            shift_template_uuid
        }));
        const selectedShift = shiftTemplates?.find(st => st.shift_template_uuid === shift_template_uuid);
        transformShiftData(selectedShift, "shift");
    };

    const getBreakName = (break_template_uuid: string) => {
        const selectedBreak = breakTemplates.find(bt => bt.break_template_uuid === break_template_uuid);
        return selectedBreak ? selectedBreak.break_name : "Select";
    };

    const handleBreakTemplateChange = (breakTemplateUuid: string) => {
        setTeamData((prev: any) => ({
            ...prev,
            break_template_uuid: breakTemplateUuid,
        }));
        const selectedBreak = breakTemplates.find(bt => bt.break_template_uuid === breakTemplateUuid);
        transformShiftData(selectedBreak, "break");
    };

    const getBreakTimingValue = (timeValue: any) => {
        if (timeValue?.includes('T')) {
            const [hours, minutes] = timeValue.split('T')[1].split('.')[0].split(':');
            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        }
        return timeValue;
    };

    const transformShiftData = (data: any, type: string): any => {
        var shiftStartTime: string
        var shiftEndTime: string
        var breaks: any
        if (type === "shift" && data) {
            shiftStartTime = getBreakTimingValue(data?.shift_start_time);
            shiftEndTime = getBreakTimingValue(data?.shift_end_time);
            setScrollComponentData((prev: any) => ({
                ...prev,
                shift_start_time: shiftStartTime,
                shift_end_time: shiftEndTime
            }))
        }
        else if (type === 'shift' && !data) {
            setScrollComponentData((prev: any) => ({
                ...prev,
                shift_start_time: "00:00:00",
                shift_end_time: "00:00:00"
            }))
        }
        else {
            breaks = data;
            setScrollComponentData((prev: any) => ({
                ...prev,
                break_ranges: breaks?.break_timings.length > 0 ? breaks?.break_timings?.map((breakItem: any) => ({
                    break_start_time: getBreakTimingValue(breakItem?.break_start_time),
                    duration_minutes: breakItem.duration_minutes
                })) : []
            }))
        }
    };

    const handleInputChange = (e: any) => {
        const { id, value } = e.target;
        setTeamData((prevState: any) => ({
            ...prevState,
            [id]: value
        }));
        let errorMessage = '';
        setTeamDataError((prevState: any) => ({
            ...prevState,
            [id]: errorMessage
        }));

    };

    const handleStatusChange = (val: any) => {

        setTeamData((prevState: any) => ({
            ...prevState,
            team_status: val === "Active" ? 1 : 0
        }));
    };

    const handleCheckboxChange = (e: any) => {
        if (e.target.checked) {
            setTeamData((prevState: any) => ({
                ...prevState,
                handling_method: TeamPrivilege.NoPush// Update based on checkbox state
            }));
        } else {
            setTeamData((prevState: any) => ({
                ...prevState,
                handling_method: TeamPrivilege.Push// Update based on checkbox state
            }));
        }

    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setUserInput(value);
        if(debounce.current){
            clearTimeout(debounce.current)
        }
        debounce.current = setTimeout(()=>{
            if (e.target.value.length >= 0) {
                getUsers(e.target.value); // Fetch users when typing
            } else {
                setUserDropDownDetails([]); // Clear suggestions if input is empty
            }
        },500)
    };

    const handleUserSelect = async (user: fetchUserNamesResponse) => {
        // Check if the user is already in selectedUsers and marked as deleted
        const existingIndex = selectedUsers.findIndex(
            (selectedUser) => selectedUser.user_uuid === user.user_uuid && selectedUser.is_deleted
        );
    
        if (existingIndex !== -1) {
            // If the user is found and marked as deleted, toggle the is_deleted flag
            setSelectedUsers((prev) =>
                prev.map((selectedUser, index) =>
                    index === existingIndex ? { ...selectedUser, is_deleted: false } : selectedUser
                )
            );
        } else {
            // If the user is not in selectedUsers or not marked as deleted, add it as a new user
            const profileImage = await fetchProfileImage(user?.business_email);
            const newUser: SelectedUser = {
                ...user,
                profileImage,
                is_deleted: false,
            };
            setSelectedUsers((prev) => [...prev, newUser]);
        }
    
        setUserInput(''); // Clear the input
        setUserDropDownDetails([]); // Clear the suggestions
        setUsersError('');
    };

    const handleRemoveUser = (userToRemove: SelectedUser) => {
        setSelectedUsers(prev => prev?.map(user => {
            if (user.user_uuid === userToRemove.user_uuid) {
                // Mark the user as deleted
                return { ...user, is_deleted: true };
            }
            // Return the user as is if not the one to remove
            return user;
        }));
    };

    const isCustomerSelected = (customer: CustomerSite) => {
        return selectedCustomers?.some(
            selected =>
                selected.customer_uuid === customer.customer_uuid &&
                selected.site_uuid === customer.site_uuid && !selected.is_deleted
        );
    };


    const isWorkItemSelected = (work_item: WorkItem) => {
        return selectedWorkItems?.some(
            selectedItem => selectedItem.app_work_item_uuid === work_item.app_work_item_uuid && !selectedItem.is_deleted
        );
    };

    const handleWorkItemChange = (uuid: string) => {
        setSelectedWorkItems(prevState => {
            const existingItem = prevState?.find(item => item.app_work_item_uuid === uuid);
            if (existingItem) {
                return prevState?.map(item =>
                    item.app_work_item_uuid === uuid ? { ...item, is_deleted: !item.is_deleted } : item
                );
            } else {
                const newItem = workItems?.find(item => item.app_work_item_uuid === uuid);
                if (newItem) {
                    return [...prevState, { ...newItem, is_deleted: false }];
                }
            }
            return prevState;
        });
    };

    const handleCustomerChange = (customer: CustomerSite) => {
        setSelectedCustomers(prevState => {
            const customerIndex = prevState?.findIndex(
                selected => selected.customer_uuid === customer.customer_uuid && selected.site_uuid === customer.site_uuid
            );

            if (customerIndex !== -1) {
                // Create a shallow copy of the state to ensure immutability
                const updatedCustomers = [...prevState];
                // Toggle the `is_deleted` property for the existing customer
                updatedCustomers[customerIndex] = {
                    ...updatedCustomers[customerIndex],
                    is_deleted: !updatedCustomers[customerIndex].is_deleted
                };
                return updatedCustomers;
            } else {
                // Add new customer with `is_deleted` set to false
                return [...prevState, { ...customer, is_deleted: false }];
            }
        });
    };


    const getUserUUIDs = () => {
        const user_uuids = selectedUsers?.map(selected => selected?.user_uuid);
        return user_uuids; // This will return an array of user UUIDs
    };

    const handleCancelClick = () => {
        navigate(`/team`);
    };

    const ValidateForm = () => {
        return (
            teamData?.team_name?.trim() !== '' &&
            teamData?.team_description?.trim() !== '' &&
            teamData.break_template_uuid !== "" &&
            teamData.shift_template_uuid !== "" &&
            selectedUsers?.filter(user => user.is_deleted === false)?.length > 0 &&
            selectedUsers?.length > 0 &&
            selectedCustomers?.filter(customer => customer.is_deleted === false).length > 0 &&
            selectedCustomers.length > 0 &&
            selectedWorkItems?.filter(workitem => workitem.is_deleted === false).length > 0 &&
            selectedWorkItems?.length > 0
        );
    };

    const getUsers = async (name: string) => {
        const payload: fetchUserNamesRequest = {
            user_name: name
        }
        try {
            const response: any = await getUserService(payload)
            if (response?.status === 200) {
                let combinedUsers = [
                    ...selectedUsers?.filter(user => user.is_deleted),
                    ...response?.data?.data
                ];    
                let filteredResponse = combinedUsers.filter((eachResponse: fetchUserNamesResponse) => {
                    let found = selectedUsers?.findIndex((eachSelectedUser: SelectedUser) => (eachSelectedUser.user_uuid === eachResponse.user_uuid) && (eachSelectedUser.is_deleted === false))
                    if (found !== -1) {
                        return false
                    }
                    return true
                })
                setUserDropDownDetails(filteredResponse);
            }
            else {
                setUserDropDownDetails([])
                setUsersError('No user details found')
            }
        }
        catch (error: any) {
            console.error(error)
        }
    }

    const fetchProfileImage = async (email: string) => {
        const payload: fetchProfileImageRequest = { business_email: email };
        try {
            const response: any = await getProfileImageService(payload);
            if (response?.status === 200) {
                return response?.data?.data.image_data; // Assuming this is where the image data is
            } else {
                return null;
            }
        } catch (error: any) {
            console.error(error);
            return null;
        }
    };

    const getInitials = (name: string): string => {
        if (!name) return 'UK';
        const names = name.split(' ');
        return names?.map((n) => n[0]).join('').toUpperCase();
    };

    const postTeam = async () => {
        const payload: CreateTeamsRequest = {
            team_name: teamData.team_name,
            application_uuid: teamData.application_uuid,
            team_status: teamData.team_status,
            team_description: teamData.team_description,
            handling_method: teamData.handling_method,
            shift_template_uuid: teamData.shift_template_uuid,
            break_template_uuid: teamData.break_template_uuid,
            user_uuids: getUserUUIDs(),
            work_items: selectedWorkItems,
            customers: selectedCustomers
        }
        try {
            setIsLoading(true);
            const response: any = await postTeamService(payload)
            if (response?.status === 201) {
                navigate('/team', { state: { "mode": "Add" } })
            }
            else if (response?.status === 409) {
                handleShowToast(true, "Error", "Team Name already exists");
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
        finally{
            setIsLoading(false);
        }
    }


    const ItemType = {
        WORK_ITEM: 'WORK_ITEM',
    };

    const WorkItemComponent: React.FC<WorkItemProps> = ({ item, index, moveItem, isWorkItemSelected, handleWorkItemChange }) => {
        const [{ isDragging }, drag] = useDrag({
            type: ItemType.WORK_ITEM,
            item: { index },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: ItemType.WORK_ITEM,
            hover: (draggedItem: { index: number }) => {
                if (draggedItem.index !== index) {
                    moveItem(draggedItem.index, index);
                    draggedItem.index = index;
                }
            },
        });

        return (
            <div
                ref={(node) => drag(drop(node))}
                className={`d-flex align-items-start gap-2 border-bottom pb-2 mb-2 ${isDragging ? 'bg-light' : ''}`}
                style={{ opacity: isDragging ? 0.5 : 1 }}
            >
                <img src="img/drag-icon.svg" alt="drag" className="mt-2 cursor-grab" />
                <div className="form-check w-100">
                    <input
                        className="form-check-input shadow-none"
                        type="checkbox"
                        id={`_select_workItems_${index + 1}`}
                        checked={isWorkItemSelected(item)}
                        onChange={() => handleWorkItemChange(item?.app_work_item_uuid)}
                    />
                    <label className="form-check-label font-semibold font-12 color-grey-v4 cursor-pointer" htmlFor={`_select_workItems_${index + 1}`}>
                        {item.work_item_name}
                    </label>
                </div>
            </div>
        );
    };



    const WorkItemList: React.FC<WorkItemListProps> = ({ workItems, setWorkItems, isWorkItemSelected, setSelectedWorkItems, handleWorkItemChange }) => {
        const moveItem = (fromIndex: number, toIndex: number) => {
            const updatedItems = [...workItems];
            const [movedItem] = updatedItems?.splice(fromIndex, 1);
            updatedItems?.splice(toIndex, 0, movedItem);

            const updatedSequenceItems = updatedItems?.map((item, index) => ({
                ...item,
                sequence: index + 1
            }));
            setWorkItems(updatedSequenceItems);
            // If the moved item is selected, update its sequence in the selectedWorkItems array as well.
            setSelectedWorkItems((selected) => {
                // Check if the moved item is in the selectedWorkItems array.
                const isSelected = selected?.some(
                    (item) => item.app_work_item_uuid === movedItem.app_work_item_uuid
                );

                // If the item is selected, update the sequence in the selectedWorkItems array.
                if (isSelected) {
                    const updatedSelectedItems = selected?.map((item) => {
                        if (item.app_work_item_uuid === movedItem.app_work_item_uuid) {
                            return { ...item, sequence: toIndex + 1 };
                        }
                        return item;
                    });
                    return updatedSelectedItems;
                }
                // If the moved item is not selected, return the current selectedWorkItems without any changes.
                return selected;
            });
        };

        return (
            <div className="border rounded-2 p-2 list-scroll">
                {workItems?.map((item: WorkItem, index: number) => (
                    <WorkItemComponent
                        key={item.app_work_item_uuid}
                        item={item}
                        index={index}
                        moveItem={moveItem}
                        isWorkItemSelected={isWorkItemSelected}
                        handleWorkItemChange={handleWorkItemChange}
                        setSelectedWorkItems={setSelectedWorkItems}
                    />
                ))}
            </div>
        );
    };

    const areArraysEqual = (arr1: any[], arr2: any[]) => {
        // Filter out any objects marked as 'is_deleted'
        const cleanArr1 = arr1.filter((item) => !item.is_deleted);
        const cleanArr2 = arr2.filter((item) => !item.is_deleted);
    
        // Compare the lengths of the arrays
        if (cleanArr1.length !== cleanArr2.length) return false;
    
        // Sort arrays by keys to ensure order consistency
        const sortedArr1 = [...cleanArr1].sort((a, b) => a.twim_uuid ? a.twim_uuid.localeCompare(b.twim_uuid) : 0);
        const sortedArr2 = [...cleanArr2].sort((a, b) => a.twim_uuid ? a.twim_uuid.localeCompare(b.twim_uuid) : 0);
    
        // Stringify and compare the sorted arrays
        return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
    };

    useEffect(() => {
        const isEqual = (
            teamData.team_name === initialFetchedTeamData.team_name &&
            Number(teamData.team_status) === Number(initialFetchedTeamData.team_status) &&
            teamData.team_description === initialFetchedTeamData.team_description &&
            teamData.handling_method === initialFetchedTeamData.handling_method &&
            teamData.shift_template_uuid === initialFetchedTeamData.shift_template_uuid &&
            teamData.break_template_uuid === initialFetchedTeamData.break_template_uuid &&
            areArraysEqual(selectedWorkItems, initialWorkItems) &&
            areArraysEqual(selectedCustomers, initialCustomers) &&
            areArraysEqual(selectedUsers, initialFetchedUsers)
        );
    
        setIsUpdateButtonDisabled(isEqual);
    }, [teamData, selectedWorkItems, selectedCustomers, selectedUsers, initialFetchedTeamData, initialWorkItems, initialCustomers, initialFetchedUsers]);

    const updateTeam = async () => {
        const payload: UpdateTeamRequest = {
            team_name: teamData.team_name,
            status: Number(teamData.team_status),
            team_description: teamData.team_description,
            handling_method: teamData.handling_method,
            shift_template_uuid: teamData.shift_template_uuid,
            break_template_uuid: teamData.break_template_uuid,
            team_uuid: team_uuid,
            team_work_items: selectedWorkItems,
            customer_site_data: selectedCustomers,
            team_users: selectedUsers,
            application_uuid: vemAppId,
        }
        try {
            setIsLoading(true)
            const response: any = await updateTeamService(payload)
            if (response?.status === 200) {

                handleShowToast(true, "Success", response?.data?.message)
                navigate('/team', { state: { "mode": "Edit" } })
            }
            else if (response?.status === 409) {
                handleShowToast(true, "Error", "Team Name already exists");
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
        finally{
            setIsLoading(false);
        }
    }



    const renderCustomerDetails = (customerDetails: CustomerSite[]) => {
        // Sort the customerDetails array in alphabetical ascending order
        const sortedCustomerDetails = customerDetails?.sort((a, b) => a.customer_name?.localeCompare(b.customer_name));

        if (sortedCustomerDetails?.length === 0) {
            return (
                <p className="font-semibold font-14 color-grey-v4 border-bottom pb-2 m-0 cursor-pointer">
                    No customers available
                </p>
            );
        }

        return sortedCustomerDetails?.map((customer, index) => (
            <div key={`${customer.customer_uuid}:${customer.site_uuid}`} className="form-check border-bottom pb-2 mb-2">
                <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    id={`_select_customers_${customer.customer_uuid}:${customer.site_uuid}`}
                    checked={isCustomerSelected(customer)}
                    onChange={() => handleCustomerChange(customer)}
                />
                <label className="form-check-label font-semibold font-12 color-grey-v4 cursor-pointer" htmlFor={`_select_customers_${customer.customer_uuid}:${customer.site_uuid}`}>
                    {`${customer.customer_name} - ${customer.site_name}`}
                </label>
            </div>
        ));
    };

    return (
        <>
            <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
                {isLoading ? (
                    <FullscreenLoader />
                ) : (<div className="p-3 py-4">
                    {/* breadcrumbs */}
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item ">Admin</li>
                            <li className="breadcrumb-item cursor-pointer" onClick={() => navigate("/team")} >Team Management</li>
                            <li className="breadcrumb-item active" aria-current="page">{mode === "Add" ? "Add New Team" : "Edit Team"}</li>
                        </ol>
                    </nav>
                    <h2 className="mb-3 font-bold font-16 color-black d-flex align-items-center gap-2">
                        <a className="mb-1 cursor-pointer"><img onClick={() => navigate("/team")} src="img/back-arw.svg " alt="back-arw" /></a>
                        {mode === "Add" ? "Add New Team" : "Edit Team"}
                    </h2>
                    <div className="bg-white rounded-3 shadow-sm p-4">
                        <div className="row w-50 gx-5 row-gap-4">
                            <div className="col-6">
                                <label htmlFor="team-name" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    Team Name <span className="color-red">*</span>
                                </label>
                                <input
                                    id="team_name"
                                    type="text"
                                    className="form-control border font-12 custom-input shadow-none"
                                    placeholder="Enter Team Name"
                                    value={teamData.team_name}
                                    onChange={handleInputChange}
                                    maxLength={255}
                                />
                                {teamDataError.team_name && (
                                    <span className="error text-danger font-13">{teamDataError?.team_name}</span>
                                )}
                            </div>
                            <div className="col-6">
                                <label htmlFor="_status" className="m-0 font-semibold font-13 color-black-v1 mb-2 w-100">Status</label>
                                <div className="form-check form-check-inline mt-1">
                                    <input
                                        className="form-check-input shadow-none cursor-pointer"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="Active"
                                        checked={(teamData?.team_status === 1) || (teamData?.team_status.toString() === "1")}
                                        onChange={(e) => { handleStatusChange("Active") }}
                                    />
                                    <label className="form-check-label color-black font-regular font-13 cursor-pointer" htmlFor="Active">Active</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input shadow-none cursor-pointer"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="Inactive"
                                        checked={teamData?.team_status === 0 || (teamData?.team_status.toString() === "0")}
                                        onChange={(e) => { handleStatusChange("Inactive") }}
                                    />
                                    <label className="form-check-label color-black font-regular font-13 cursor-pointer" htmlFor="Inactive">Inactive</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="_desc" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    Description <span className="color-red">*</span>
                                </label>
                                <textarea
                                    id="team_description"
                                    className="form-control txtarea shadow-none border font-regular font-13 color-black-v1 resize-none"
                                    placeholder="Enter Description"
                                    value={teamData.team_description}
                                    onChange={handleInputChange}
                                    maxLength={3000}
                                />

                                {teamDataError.team_description && (
                                    <span className="error text-danger font-13">{teamDataError?.team_description}</span>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="shift_name" className="m-0 font-semibold font-13 color-black-v1 mb-2">Shift Name <span className="color-red">*</span></label>
                                <span className="filter-drop-down">
                                    <button
                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >
                                        <span>{getShiftName(teamData.shift_template_uuid)?.length > 35 ? getShiftName(teamData.shift_template_uuid).substring(0, 30) + "..." : getShiftName(teamData.shift_template_uuid)}</span>
                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                    </button>

                                    <span className="dropdown-menu font-regular font-14 p-0">
                                        <span
                                            className="px-2 py-1 list d-block font-12 teams-shift-break-select"
                                            onClick={() => handleShiftTemplateChange("")}
                                        >
                                            Select
                                        </span>
                                        {shiftTemplates?.map((shift, index) => (
                                            <span
                                                key={index}
                                                className="px-2 py-1 list d-block font-12"
                                                onClick={() => handleShiftTemplateChange(shift.shift_template_uuid)}
                                                title={shift.shift_name.length > 30 ? shift.shift_name : ""}
                                            >
                                                {shift.shift_name.length > 35 ? shift.shift_name.substring(0, 30) + "..." : shift.shift_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="shift_name" className="m-0 font-semibold font-13 color-black-v1 mb-2">Break Name <span className="color-red">*</span></label>
                                <span className="filter-drop-down">
                                    <button
                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >

                                        <span>{getBreakName(teamData.break_template_uuid)?.length > 35 ? getBreakName(teamData.break_template_uuid).substring(0, 30) + "..." : getBreakName(teamData.break_template_uuid)}</span>

                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                    </button>

                                    <span className="dropdown-menu font-regular font-14 p-0">
                                        <span
                                            className="px-2 py-1 list d-block font-12 teams-shift-break-select"
                                            onClick={() => handleBreakTemplateChange("")}
                                        >
                                            Select
                                        </span>
                                        {breakTemplates?.map((breakTemplate, index) => (
                                            <span
                                                key={index}
                                                className="px-2 py-1 list d-block font-12"
                                                onClick={() => handleBreakTemplateChange(breakTemplate.break_template_uuid)}
                                                title={breakTemplate.break_name.length > 30 ? breakTemplate.break_name : ""}
                                            >
                                                {breakTemplate.break_name.length > 35 ? breakTemplate.break_name.substring(0, 30) + "..." : breakTemplate.break_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="shift_and_break" className="m-0 font-semibold font-13 color-black-v1 mb-2">Shift &amp; Break Time<span className="color-red">*</span></label>
                                <ShiftScroll shiftData={scrollComponentData} />
                            </div>
                        </div>
                        <div className="col-12">
                            <h3 className="font-bold font-18 color-black mt-3">Users</h3>
                            <label htmlFor="add_users" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                Add Users <span className="color-red">*</span>
                            </label>
                            <div className="d-flex align-items-stretch gap-4">
                                <input
                                    id="add_users"
                                    type="text"
                                    className="form-control border font-12 custom-input shadow-none w-25"
                                    placeholder="Enter Username"
                                    value={userInput}
                                    onChange={handleNameChange}
                                />
                                {/* Dropdown for user suggestions */}
                                {userDropDownDetails?.length > 0 && userInput?.length > 0 && (
                                    <div className="teams-form-select dropdown-menu show teams-select-scroll">
                                        {userDropDownDetails?.map(user => (
                                            <div
                                                key={user.user_uuid}
                                                className="dropdown-item cursor-pointer"
                                                onClick={() => handleUserSelect(user)}
                                            >
                                                <span>{user.display_name?.length > 35 ? user.display_name.substring(0, 30) + "..." : user.display_name}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {usersError && (!(userDropDownDetails?.length) || userInput) && (
                                <span className="error text-danger font-13">{usersError}</span>
                            )}
                            <div className="user-tag-wrapper mt-3 d-flex flex-wrap align-items-center gap-2">
                                {selectedUsers
                                    ?.filter(user => !user.is_deleted)
                                    .map(({ user_uuid, display_name, profileImage }) => (
                                        <div key={user_uuid} className="user-tag d-flex align-items-center gap-4">
                                            <div className="d-flex align-items-center gap-2">
                                                {profileImage ? (
                                                    <span className="teams-user-image d-flex align-items-center justify-content-center">
                                                        <img className="teams-user-img" src={`data:image/jpeg;base64,${profileImage}`} alt={display_name} width="24px" /></span>
                                                ) : (
                                                    <div className="position-relative dp-wrapper active teams-user-image d-flex justify-content-center align-items-center">
                                                        {getInitials(display_name ?? "")}
                                                    </div>

                                                )}
                                                <span className="font-regular font-13 color-black">{display_name}</span>
                                            </div>
                                            <span className="cursor-pointer d-flex" onClick={() => handleRemoveUser({ user_uuid, display_name, business_email: '', profileImage, is_deleted: true })}>
                                                <img src="img/close-icon.svg" alt="close" width="10px" />
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <h3 className="my-3 py-3 font-bold font-18 color-black border-bottom">Entitlement</h3>
                        <h4 className="font-bold font-16 color-black m-0">Operations</h4>
                        <div className="ms-3 mt-3">
                            <h4 className="font-semibold font-14 color-black mb-2">Handling Method</h4>
                            <div className="form-check">
                                <input
                                    className="form-check-input shadow-none"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    checked={teamData?.handling_method === TeamPrivilege.NoPush}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label font-regular font-14 color-grey-v4 cursor-pointer" htmlFor="flexCheckDefault">
                                    Enable the checkbox to allow operator to accept work item.{"  "}
                                    <span className="cursor-pointer position-relative tooltip-icon">
                                        <img
                                            src="img/tooltip-icon.svg" width={9}
                                            alt="tooltip" /><span className="custom-tooltip-teams">
                                            <span className="color-white font-12">
                                                If this is not enabled the work item will be directly pushed
                                            </span>
                                        </span>
                                    </span>
                                </label>
                            </div>
                            <div className="w-50 d-flex flex-nowrap gap-4 mt-4">
                                <div className="w-50">
                                    <h4 className="font-semibold font-14 color-black">Select Customers</h4>
                                    <div className="border rounded-2 p-2 list-scroll">
                                        {renderCustomerDetails(customers)}
                                    </div>
                                </div>
                                <div className="vr vr-color" />
                                <div className="w-50">
                                    <h4 className="font-semibold font-14 color-black">Select Work Items</h4>
                                    <DndProvider backend={HTML5Backend}>
                                        <WorkItemList
                                            workItems={workItems}
                                            setWorkItems={setWorkItems}
                                            isWorkItemSelected={isWorkItemSelected}
                                            handleWorkItemChange={handleWorkItemChange}
                                            setSelectedWorkItems={setSelectedWorkItems}
                                        />
                                    </DndProvider>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end gap-3 mt-5 pt-3 mb-3">
                            <button className="secondary-btn lh-base" onClick={() => handleCancelClick()} type="button">Cancel</button>
                            <button className="primary-btn lh-base" disabled={!ValidateForm() || (mode !== "Add" && isUpdateButtonDisabled)} onClick={() => { mode === "Add" ? postTeam() : updateTeam() }} type="button">{mode === "Add" ? "Add" : "Update"}</button>                      </div>
                    </div>
                </div>)}
            </div>
            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />
            }</>


    );
};

export default TeamForm;
