/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Stage as KonvaStage, Layer, Circle, Group as KonvaGroup, Line } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import Konva from 'konva';
import { Tween } from 'konva/lib/Tween';
import { Easings } from 'konva/lib/Tween';
import WebRTCPlayer from './webrtc_player';
import { PTZValues, PTZVideoPlayerProps } from '../Interfaces/webrtc_player_interface';

const PTZVideoPlayer: React.FC<PTZVideoPlayerProps> = ({
    width, height, onPTZChange, initialPTZValues, camera
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isClicking, setIsClicking] = useState(false);
    const [controlPosition, setControlPosition] = useState({ x: width / 2, y: height / 2 });
    const [ptzValues, setPTZValues] = useState<PTZValues>(initialPTZValues);
    const [isHovered, setIsHovered] = useState(false);

    const stageRef = useRef<Konva.Stage | null>(null);
    const groupRef = useRef<Konva.Group | null>(null);
    const controlRef = useRef<Konva.Circle | null>(null);
    const lineRef = useRef<Konva.Line | null>(null);
    const lastPosition = useRef<Vector2d>({ x: width / 2, y: height / 2 });
    const containerRef = useRef<HTMLDivElement>(null);

    const { centerX, centerY, outerRadius } = useMemo(() => ({
        centerX: width / 2,
        centerY: height / 2,
        outerRadius: 30,
    }), [width, height]);

    const isPointInsideCircle = useCallback((point: Vector2d, center: Vector2d, radius: number): boolean => {
        const dx = point.x - center.x;
        const dy = point.y - center.y;
        return dx * dx + dy * dy <= radius * radius;
    }, []);

    const handleMouseDown = useCallback((e: KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            const pos = stage.getPointerPosition();
            if (pos && e.evt.button === 0) { // Left mouse button
                if (isPointInsideCircle(pos, { x: centerX, y: centerY }, outerRadius)) {
                    setIsDragging(true);
                    setIsClicking(false);
                    setControlPosition(pos);
                    lastPosition.current = pos;
                } else {
                    setIsClicking(true);
                }
            }
        }
    }, [centerX, centerY, outerRadius, isPointInsideCircle]);

    const handleMouseMove = useCallback((pos: Vector2d) => {
        if (isDragging) {
            setControlPosition(pos);
            const dx = pos.x - lastPosition.current.x;
            const dy = pos.y - lastPosition.current.y;


            // Calculate zoom factor (assuming zoom range is 0-9999)
            const zoomSensitivity = 0.0026; // Adjust this value to change how much zoom affects sensitivity

            // Calculate zoom factor with adjustable sensitivity
            const zoomFactor = 1 + (ptzValues.zoom * zoomSensitivity);
            // Apply zoom factor to sensitivity

            const pitchChange = (dx / width) * 180 / zoomFactor;
            const tiltChange = (-dy / height) * 90 / zoomFactor;

            setPTZValues(prev => ({
                ...prev,
                pan: (prev.pan + pitchChange + 360) % 360,
                tilt: Math.max(-90, Math.min(0, prev.tilt + tiltChange))
            }));

            lastPosition.current = pos;
        }
    }, [isDragging, width, height]);

    const handleMouseUp = useCallback(() => {
        if (isDragging) {
            setIsDragging(false);
            onPTZChange(ptzValues);

            // Smooth transition of the control and line back to center
            if (controlRef.current && lineRef.current) {
                new Tween({
                    node: controlRef.current,
                    duration: 0.2,
                    x: centerX,
                    y: centerY,
                    easing: Easings.EaseOut,
                    onFinish: () => setControlPosition({ x: centerX, y: centerY })
                }).play();

                new Tween({
                    node: lineRef.current,
                    duration: 0.2,
                    points: [centerX, centerY, centerX, centerY],
                    easing: Easings.EaseOut,
                }).play();
            }
        }
        setIsClicking(false);
    }, [centerX, centerY, onPTZChange, ptzValues, isDragging]);

    const handleClick = useCallback((e: KonvaEventObject<MouseEvent>) => {
        if (isClicking) {
            const stage = e.target.getStage();
            if (stage) {
                const pos = stage.getPointerPosition();
                if (pos && e.evt.button === 0) { // Left mouse button
                    if (!isPointInsideCircle(pos, { x: centerX, y: centerY }, outerRadius)) {
                        // Click outside the circle
                        const dx = pos.x - centerX;
                        const dy = pos.y - centerY;
                        const distance = Math.sqrt(dx * dx + dy * dy);
                        const normalizedX = dx / distance;
                        const normalizedY = dy / distance;

                        const newControlX = centerX + normalizedX * outerRadius;
                        const newControlY = centerY + normalizedY * outerRadius;

                        setControlPosition({ x: newControlX, y: newControlY });
                        let baseSensitivityFactorX = 0.35;
                        let baseSensitivityFactorY = 0.515;

                        // Calculate zoom factor (assuming zoom range is 0-9999)
                        const zoomSensitivity = 0.0026; // Adjust this value to change how much zoom affects sensitivity

                        // Calculate zoom factor with adjustable sensitivity
                        const zoomFactor = 1 + (ptzValues.zoom * zoomSensitivity);
                        // Apply zoom factor to sensitivity
                        const sensitivityFactorX = baseSensitivityFactorX / zoomFactor;
                        const sensitivityFactorY = baseSensitivityFactorY / zoomFactor;

                        const pitchChange = (dx / width) * 180 * sensitivityFactorX;
                        const tiltChange = (-dy / height) * 90 * sensitivityFactorY;

                        const newPTZValues = {
                            pan: (ptzValues.pan + pitchChange + 360) % 360,
                            tilt: Math.max(-90, Math.min(0, ptzValues.tilt + tiltChange)),
                            zoom: ptzValues.zoom
                        };

                        setPTZValues(newPTZValues);
                        onPTZChange(newPTZValues);

                        // Animate the control and line back to center
                        if (controlRef.current && lineRef.current) {
                            new Tween({
                                node: controlRef.current,
                                duration: 0.2,
                                x: centerX,
                                y: centerY,
                                easing: Easings.EaseOut,
                                onFinish: () => setControlPosition({ x: centerX, y: centerY })
                            }).play();

                            new Tween({
                                node: lineRef.current,
                                duration: 0.2,
                                points: [centerX, centerY, centerX, centerY],
                                easing: Easings.EaseOut,
                            }).play();
                        }
                    }
                }
            }
        }
        setIsClicking(false);
    }, [centerX, centerY, outerRadius, isPointInsideCircle, width, height, onPTZChange, ptzValues, isClicking]);

    const handleWheel = useCallback((e: WheelEvent) => {
        e.preventDefault();
        if (isHovered) {
            const zoomChange = e.deltaY < 0 ? 100 : -100; // Adjust this value for zoom sensitivity
            setPTZValues(prev => {
                const newZoom = Math.max(0, Math.min(9999, prev.zoom + zoomChange));
                onPTZChange({ ...prev, zoom: newZoom });
                return { ...prev, zoom: newZoom };
            });
        }
    }, [isHovered, onPTZChange]);

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    useEffect(() => {
        const handleGlobalMouseMove = (e: MouseEvent) => {
            if (isDragging) {
                const stage = stageRef.current;
                if (stage) {
                    const pos = stage.getPointerPosition() || { x: e.clientX, y: e.clientY };
                    handleMouseMove(pos);
                }
            }
        };

        const handleGlobalMouseUp = () => {
            handleMouseUp();
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleGlobalMouseMove);
            window.addEventListener('mouseup', handleGlobalMouseUp);

            return () => {
                window.removeEventListener('mousemove', handleGlobalMouseMove);
                window.removeEventListener('mouseup', handleGlobalMouseUp);
            };
        }
    }, [isDragging, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        setPTZValues(initialPTZValues);
    }, [initialPTZValues]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel, { passive: false });
            container.addEventListener('mouseenter', handleMouseEnter);
            container.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                container.removeEventListener('wheel', handleWheel);
                container.removeEventListener('mouseenter', handleMouseEnter);
                container.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, [handleWheel, handleMouseEnter, handleMouseLeave]);

    return (
        <div
            ref={containerRef}
            style={{ position: 'relative', width, height }}
            className="container-view-video"
        >
            <WebRTCPlayer camera={camera} />
            <KonvaStage
                width={width}
                height={height}
                style={{ position: 'absolute', top: 0, left: 0 }}
                ref={stageRef}
                className="cursor-target"
                onMouseDown={handleMouseDown}
                onClick={handleClick}
                onMouseMove={(e) => {
                    const pos = e.target.getStage()?.getPointerPosition();
                    if (pos) handleMouseMove(pos);
                }}
                onMouseUp={handleMouseUp}
                onContextMenu={(e) => e.evt.preventDefault()}
            >
                <Layer>
                    <KonvaGroup ref={groupRef}>
                        <Circle
                            x={centerX}
                            y={centerY}
                            radius={outerRadius}
                            stroke="white"
                            strokeWidth={2}
                            opacity={0.25}
                        />
                        <Line
                            ref={lineRef}
                            points={[centerX, centerY, controlPosition.x, controlPosition.y]}
                            stroke="white"
                            strokeWidth={2}
                            opacity={0.25}
                        />
                        <Circle
                            ref={controlRef}
                            x={controlPosition.x}
                            y={controlPosition.y}
                            radius={8}
                            fill="white"
                            opacity={0.25}
                        />
                    </KonvaGroup>
                </Layer>
            </KonvaStage>
        </div>
    );
};

export default PTZVideoPlayer;