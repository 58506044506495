/**SQ_EX_35.0 - Component to render the color and time second */

import moment from 'moment';
import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ receivedTime, normalTime, moderateTime }: any) => {
    const [seconds, setSeconds] = useState<number>(receivedTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds((prevSeconds) => {
                let currentTime = prevSeconds + 1;               

                return currentTime;
            });
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [receivedTime]);

    // Determine the color level based on the current seconds
    const getLevel = (currentSeconds: number) => {
        if (currentSeconds < normalTime) {
            return 'low';
        } else if (currentSeconds >= normalTime && currentSeconds < moderateTime) {
            return 'medium';
        } else {
            return 'high';
        }
    };

    const level = getLevel(seconds);

    return (
        <td className="align-middle">
            <span className={`progress-timer ${level}  d-flex align-items-center gap-1`}>
                <img src="/img/timer-icon.svg" alt="timer" width={10} />
                <span className="font-bold color-white">{seconds} sec</span>
            </span>
        </td>
    );
};

const Timer = ({ receivedTime, normalTime, moderateTime , presentTime }: any) => {
    const calculateDifference = (time: string) => {
        const pastTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
        const currentTime = presentTime ? moment.utc(presentTime, "YYYY-MM-DD HH:mm:ss") : moment.utc();
        const timeDifference = currentTime.diff(pastTime);
        const differenceInSeconds = Math.abs(Math.floor(timeDifference / 1000)); 
        return differenceInSeconds;
    };

    return (
        <CountdownTimer 
            receivedTime={calculateDifference(receivedTime)} 
            normalTime={normalTime} 
            moderateTime={moderateTime} 
        />
    );
};

export default Timer;