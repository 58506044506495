/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
import { encryptKeys, encryptStorage, notificationAudioPath } from "../../constant";
import { userDetails } from "../../Interfaces/login_interface";
import { ActionsEnum, applicationAbbrievation, HeaderNotificationSocketActions, NotificationHeader, UserRoles, UserStatus, DisplayUserRoles } from "../../enums/enums";
import { getScheduleDetailsService, getStatusService, getUserStatusService, updateUserActivityStatus } from "../../services/operator_service";
import { BreakTiming } from "../../Interfaces/break_templates_interface";
import { ShiftData, UpdateUserStatusInterface } from "../../Interfaces/operations_interface";
import { overallContext } from "../../context/Context";
import { useContext } from "react";
import WebsocketClient from "../../client/websocket";
import { GetNotificationRequest, NotificationDetails, UpdateNotificationRequest } from "../../Interfaces/notification_interface";
import NotificationHeaderList from "../notifications/notification_list";
import { getUserNotifications, updateUserNotifications } from "../../services/notification_service";
import { fetchUserDetailsFromStorage } from "../utils/utils";
import { defaultSesionTimeOut } from "../../constant";
import SessionTimeOutPopup from "../../reusable_components/session_timeout_component";
import moment from "moment";
import { FullscreenLoader } from "../loader";

export default function Header() {
    const { isEquipmentCorrection, setUserBreak, userStatus: globalUserStatus, setUserStatus: setGlobalUserStatus } = useContext(overallContext);

    const userDetail: userDetails = {
        accessToken: "",
        userName: "",
        emailId: "",
        userId: "",
        refreshToken: "",
        appRole: []
    }
    const navigate = useNavigate();
    const { instance } = useMsal();
    const notificationAlert = new Audio(notificationAudioPath)
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { userId, userName, appRole } = fetchUserDetailsFromStorage()
    const sessionApplication = encryptStorage.getItem(encryptKeys?.currentApplication);
    const applicationUUID = appRole?.find((role: any) => role.abbreviation === sessionApplication)?.app_uuid || "-";
    const [userDetails, setUserDetails] = useState<userDetails>(userDetail);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [shiftData, setShiftData] = useState<ShiftData>({
        shift_start_time: "",
        shift_end_time: "",
        break_timings: [
            {
                break_start_time: "",
                duration_minutes: ""
            }
        ]
    });
    const [requestPayload, setRequestPayload] = useState<GetNotificationRequest>({
        user_uuid: userId,
        application_uuid: applicationUUID,
        filter: {
            search: "",
            duration: "",
            from_date: "",
            to_date: "",
        },
        page_number: 1,
        number_of_records_per_page: 10
    })
    const location = useLocation();

    const websocket = useRef<WebSocket>()
    const [notifications, setNotifications] = useState<NotificationDetails[]>([])
    const [unreadNotifications, setUnreadNotifications] = useState<number>(0)
    const [showNotificationDropdown, setShowNotificationDropdown] = useState<boolean>(false)
    const [userStatus, setUserStatus] = useState<string>("");

    // State to control the visibility of the 'Admin' dropdown
    const [isAdminOpen, setAdminOpen] = useState(false);
    const [isOperationsOpen, setOperationsOpen] = useState(false);
    const [isSupervisorOpen, setSupervisorOpen] = useState(false);
    const [showSessionTimeOutPopup, setShowSessionTimeOutPopup] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const defaultSessionTimeInMilliSeconds = Number(defaultSesionTimeOut)
        let timeoutId: any;
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleExpiration, defaultSessionTimeInMilliSeconds);
        };
        const handleExpiration = async () => {
            try {
                setShowSessionTimeOutPopup(true);
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    if (user) {
                        const statusData = response?.data?.data;
                        const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.OFFLINE);
                        let statusUUID = foundStatus?.user_status_uuid;
                        if (statusUUID) {
                            const payload: UpdateUserStatusInterface = {
                                user_status_uuid: statusUUID,
                                status_name: UserStatus.OFFLINE,
                                reason: "Session Expired",
                                category: UserStatus.OFFLINE
                            }
                            await updateUserStatus(payload);
                        } else {
                            console.error("IDLE status not found");
                        }
                    }
                }
                sessionStorage.clear();
                encryptStorage.clear()
            } catch (error) {
                console.error("Error in expiration logic:", error);
            }
        };

        const handleUserInteraction = () => {
            resetTimeout();
        };
        // Set up initial timeout
        resetTimeout();
        // Add event listeners for user interaction
        window.addEventListener("mousemove", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("scroll", handleUserInteraction);

        // Cleanup: remove event listeners and clear timeout on component unmount
        return () => {
            window.removeEventListener("mousemove", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("scroll", handleUserInteraction);
            clearTimeout(timeoutId);
        };
    }, []);

    // Ref to the 'Admin' dropdown
    const adminRef = useRef<HTMLLIElement>(null);
    const operationsRef = useRef<HTMLLIElement>(null);
    const supervisorsRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const toggleAdminDropdown = () => {
        setAdminOpen(!isAdminOpen);
    };

    const toggleSupervisorDropdown = () => {
        setSupervisorOpen(!isSupervisorOpen);
    };

    const toggleOperationsDropdown = () => {
        setOperationsOpen(!isOperationsOpen);
    };

    const operationsPathsToCheck = [
        '/operations',
        '/individualuseractivity',
        '/workitemnotifications',
        '/livefeed',
        '/workitem'
    ];

    const adminPathsToCheck = [
        '/audithistory',
        '/workitemsla',
        '/usermanagementgrid',
        '/usermanagementform',
        '/team',
        '/createteam',
        '/workitemslaaccordion',
        '/shiftbreaktemplates'
    ];

    const isOperatorPathActive = operationsPathsToCheck.some(path => location.pathname.toLowerCase() === path);
    const isAdminPathActive = adminPathsToCheck.some(path => location.pathname.toLowerCase() === path);
    let user = encryptStorage.getItem(encryptKeys?.userDetails);
    user = user ? JSON?.parse(user) : null;
    const userApps = userDetails?.appRole || [];
    const currentApplication = encryptStorage.getItem(encryptKeys?.currentApplication);


    const handleAdminNavigate = async (path: string) => {
        if (isEquipmentCorrection) return;
        if (path !== '/workItemNotifications') {
            await updateActiveStatus()
        }
        navigate(path);
        setAdminOpen(false); // Ensure dropdown closes after navigation
    };


    const handleSupervisorNavigate = async (path: string) => {
        if (isEquipmentCorrection) return;
        if (path !== '/workItemNotifications') {
            await updateActiveStatus()
        }
        navigate(path);
        setSupervisorOpen(false); // Ensure dropdown closes after navigation
    };


    const handleOperationsNavigate = async (path: string) => {
        if (isEquipmentCorrection) return;
        if (path !== '/workItemNotifications') {
            await updateActiveStatus()
        }
        navigate(path);
        setOperationsOpen(false); // Ensure dropdown closes after navigation
    };


    const handleOutsideClick = (event: MouseEvent) => {
        if (operationsRef.current && !operationsRef.current.contains(event.target as Node)) {
            setOperationsOpen(false);
        }
        if (adminRef.current && !adminRef.current.contains(event.target as Node)) {
            setAdminOpen(false);
        }
        if (supervisorsRef.current && !supervisorsRef.current.contains(event.target as Node)) {
            setSupervisorOpen(false);
        }
    };


    useEffect(() => {
        // Retrieve the role from session storage
        const storedProfileImage = sessionStorage.getItem(encryptKeys?.profile);
        const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
        const parsedUserDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null
        const storedRole = parsedUserDetails?.appRole?.filter((app: any) => (app.abbreviation === "VEM"))

        setProfileImage(storedProfileImage);
        setUserDetails(parsedUserDetails);
        setUserRole(storedRole ? storedRole[0]?.role_name : "Unauthorized");
        getScheduleDetails();
        getUserStatus();
        let user = encryptStorage.getItem(encryptKeys?.userDetails);
        user = user ? JSON?.parse(user) : null;

        if (user) {
            const userUUID = user?.userId;
            const topics = [`${userUUID}${UserStatus.LIVEUSERSTATUS}`]
            if (appRole.some((role: any) => role.role_name === UserRoles.supervisor)) {
                topics.push(NotificationHeader.SUPERVISORNOTIFICATIONID)
            }
            if (websocket.current === undefined) {
                const newSocket = WebsocketClient(`${userUUID}${NotificationHeader.NOTIFICATIONSUBSCRIPTIONID}`, topics, userId)
                newSocket.onopen = () => console.log("Connection Opened");
                newSocket.onmessage = handleWebSocketMessage;
                newSocket.close = () => console.log("connection CLOSED")
                websocket.current = newSocket
            }
            loadInitialNotificationData()
            return () => {
                if (websocket.current) {
                    websocket.current.close()
                }
            }

        }

    }, []);
    useEffect(() => {
        getStatus();
    }, [shiftData])
    // LoadInitialNotificationData handles the initial loading of notification data
    // Pseudocode mapping:
    // PS_01 and PS_03 to PS_18 (SQ_EX_1.1 to SQ_EX_1.18)
    // 1. User logs in and initializes notifications.
    // 2. Fetch initial notifications through NS (Notification Service).
    // 3. Group notifications by date using NU (Notification Utils).
    // 4. Calculate and update unread notifications count.
    // 5. Establish WebSocket connection for real-time updates.
    const loadInitialNotificationData = useCallback(async (page = 1) => {
        try {
            if (user) {
                const apiResponse = await getUserNotifications({ ...requestPayload, page_number: page });
                const { status, data: notificationList, response: errResponse } = apiResponse;
                if (status !== 200) {
                    if (status === 404) {
                        if (page === 1) {
                            setNotifications([])
                        }
                        setHasMore(false); // No more data
                    }
                    throw new Error(JSON.stringify(errResponse?.data));
                }
                const notificationDetails = notificationList.data.notifications.map((eachNotification: NotificationDetails) => ({
                    ...eachNotification,
                    received_at: new Date(eachNotification.received_at),
                }));
                if (page === 1) setNotifications(notificationDetails);
                else setNotifications(prev => [...prev, ...notificationDetails]);

                setHasMore(notificationList?.data?.notifications.length > 0);
                setCurrentPage(page);
                setUnreadNotifications(notificationList?.data?.unread_count)
            }
        } catch (error: any) {
            console.log("An error occurred: ", error.message);
        }
    }, [requestPayload]);

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        handleStatusUpdate(event)
        handleNotificationUpdate(event)
    }, [showNotificationDropdown]);

    const handleStatusUpdate = (event: MessageEvent) => {
        try {
            const message = JSON.parse(event.data);
            if (message?.data?.status_name === UserStatus.DND || message?.data?.status_name === UserStatus.BREAK) {
                setUserStatus("dnd");
                message?.data?.status_name === UserStatus.DND ? setGlobalUserStatus("dnd") : setGlobalUserStatus("break");
            } else if (message?.data?.status_name === UserStatus.IDLE) { setUserStatus("active"); setGlobalUserStatus("idle") }
        } catch (error) {
            console.error("Parsing error:", error);
        }
    }
    useEffect(() => {
        if (websocket.current) {
            websocket.current.onmessage = handleWebSocketMessage
        }
        const handleClickOutside = (event: any) => {
            if (showNotificationDropdown && !event.target.closest('.notification-box')) {
                setShowNotificationDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showNotificationDropdown]);
    // HandleNotificationUpdate processes real-time notification updates through WebSocket
    // Pseudocode mapping:
    // PS_42 to PS_61 (SQ_EX_5.1 to SQ_EX_5.20)
    // 1. WebSocket receives new notification event.
    // 2. Update notifications list based on the type (refresh or update).
    // 3. Update UI with new notifications count.
    const handleNotificationUpdate = useCallback(async (event: MessageEvent) => {
        try {
            if (user) {
                const message = JSON.parse(event.data)
                switch (message?.data?.type) {
                    case HeaderNotificationSocketActions.REFRESH:
                        if (!showNotificationDropdown) {
                            loadInitialNotificationData()
                        }
                        break
                    case HeaderNotificationSocketActions.UPDATE:
                        if (!message?.data?.notification_details) {
                            throw new Error("notification content not found")
                        }
                        let notificationDetails: NotificationDetails = message?.data?.notification_details
                        setNotifications((prevNotifications: NotificationDetails[]) => {
                            return [
                                {
                                    ...notificationDetails,
                                    received_at: new Date(notificationDetails.received_at)
                                },
                                ...prevNotifications,
                            ]
                        })
                        setUnreadNotifications(prevUnreadNotifications => prevUnreadNotifications + 1)
                        await notificationAlert.play()
                        break
                }
            }
        } catch (error: any) {
            console.error("an error occured: ", error.message);
        }
    }, [showNotificationDropdown])


    const getInitials = (name: string): string => {
        if (!name) return 'UK';
        const names = name.split(' ');
        return names?.map((n) => n[0]).join('').toUpperCase();
    };

    const onClickLogOut = async () => {
        if (isEquipmentCorrection) return
        try {
            setIsLoading(true)
            try {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    if (user) {
                        const statusData = response?.data?.data;
                        const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.OFFLINE);
                        let statusUUID = foundStatus?.user_status_uuid;
                        if (statusUUID) {
                            const payload: UpdateUserStatusInterface = {
                                user_status_uuid: statusUUID,
                                status_name: UserStatus.OFFLINE,
                                reason: "Logged Out",
                                category: UserStatus.OFFLINE
                            }
                            await updateUserStatus(payload);
                        } else {
                            console.error("IDLE status not found");
                        }
                    }
                }
            } catch (err: any) {
                console.error("Error fetching status data:", err);
                // You might want to handle this error in the component
                throw err;
            }
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/';
        } catch (err) {
            console.error("Logout error:", err);
        }
    };

    const formatTime = (timeValue: string) => {
        // Extract the time part from the ISO string
        if (timeValue.includes('T')) {
            const timePart = timeValue.split('T')[1].split('.')[0];
            const [hours, minutes] = timePart.split(':');
            // Pad with leading zeros if necessary
            const formattedHours = hours.padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
        else return timeValue
    }

    const getScheduleDetails = async () => {
        try {
            if (user) {
                const userUUID = user?.userId;
                const response: any = await getScheduleDetailsService(userUUID);
                if (response?.status === 200) {
                    const responseData = response.data.data

                    if (responseData?.usbo_uuid) {
                        setShiftData((prev: any) => ({
                            ...prev,
                            shift_start_time: formatTime(responseData.override_shift_start),
                            shift_end_time: formatTime(responseData.override_shift_end)
                        }))
                        const formattedBreaks: any[] = responseData.break_override_details.map((breakDetail: any) => ({
                            break_start_time: breakDetail.break_override_uuid ? formatTime(breakDetail.original_break_start) : formatTime(breakDetail.original_break_start),
                            duration_minutes: breakDetail.break_override_uuid ? breakDetail.override_duration_minutes : breakDetail.original_duration_minutes
                        }));
                        setShiftData((prev: any) => ({
                            ...prev,
                            break_timings: formattedBreaks
                        }))
                    }
                    else {
                        setShiftData((prev: any) => ({
                            ...prev,
                            shift_start_time: formatTime(responseData.original_shift_start),
                            shift_end_time: formatTime(responseData.original_shift_end)
                        }))
                        const formattedBreaks: any[] = responseData.break_details.map((breakDetail: any) => ({
                            break_start_time: formatTime(breakDetail.original_break_start),
                            duration_minutes: breakDetail.original_duration_minutes
                        }));
                        setShiftData((prev: any) => ({
                            ...prev,
                            break_timings: formattedBreaks
                        }))
                    }
                }
            }
        } catch (error: any) {
            console.error("Error retrieving schedule details", error);
        }
    };

    const getStatus = async () => {
        try {
            if (user) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    scheduleBreaks();
                }
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const getUserStatus = async () => {
        try {
            if (user) {
                const userUUID = user?.userId;
                const response: any = await getUserStatusService(userUUID);
                if (response?.status === 200) {
                    if (response?.data?.data?.status_name === UserStatus.DND || response?.data?.data?.status_name === UserStatus.BREAK) {
                        setUserStatus("dnd")
                    } else setUserStatus("active")
                    return response?.data?.data?.status_name;
                }
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    const scheduleBreaks = () => {
        const now = moment.utc();
        const shiftStart = moment.utc(shiftData.shift_start_time, 'HH:mm:ss');

        const newActiveBreaks: NodeJS.Timeout[] = [];

        shiftData?.break_timings.forEach((break_time: any) => {
            const [hours, minutes] = break_time.break_start_time.split(':').map(Number);
            const breakStart = shiftStart.clone().add(hours, 'hours').add(minutes, 'minutes');
            const duration = parseInt(break_time.duration_minutes, 10);
            const breakEnd = breakStart.clone().add(duration, 'minutes');

            let user = encryptStorage.getItem(encryptKeys?.userDetails);
            user = user ? JSON.parse(user) : null;

            if (user) {
                const userUUID = user.userId;
                if (breakStart.isAfter(now)) {
                    const startTimeout = setTimeout(() => {
                        setBreakStatus(break_time, userUUID);
                        setUserStatus("dnd");
                        setGlobalUserStatus("break");
                    }, breakStart.diff(now));

                    const endTimeout = setTimeout(() => {
                        setIdleStatus(userUUID);
                        setUserStatus("active");
                        setGlobalUserStatus("idle");
                    }, breakEnd.diff(now));

                    newActiveBreaks.push(startTimeout, endTimeout);
                } else if (now.isBetween(breakStart, breakEnd)) {
                    // If we're currently in a break
                    setBreakStatus(break_time, userUUID);
                    setUserStatus("dnd");
                    setGlobalUserStatus("break");
                    const endTimeout = setTimeout(() => {
                        setIdleStatus(userUUID);
                        setUserStatus("active");
                        setGlobalUserStatus("idle");
                    }, breakEnd.diff(now));

                    newActiveBreaks.push(endTimeout);
                }
            }

        });
    };

    const setBreakStatus = async (breakTime: BreakTiming, userUUID: string) => {
        try {
            if (user) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    const statusData = response?.data?.data;
                    const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.BREAK);
                    let statusUUID = foundStatus?.user_status_uuid;
                    if (statusUUID) {
                        const payload: UpdateUserStatusInterface = {
                            user_status_uuid: statusUUID,
                            status_name: UserStatus.BREAK,
                            reason: `Scheduled break for ${breakTime.duration_minutes} minutes`,
                            category: UserStatus.BREAK
                        }
                        updateUserStatus(payload);
                    } else {
                        console.error("BREAK status not found");
                    }
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            // You might want to handle this error in the component
            throw err;
        }
    };

    const setIdleStatus = async (userUUID: string) => {
        try {
            if (user) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    const statusData = response?.data?.data;
                    const foundStatus = statusData.find((item: any) => window.location.pathname === '/workItemNotifications' ? (item.status_name === UserStatus.IDLE) : (item.status_name === UserStatus.ACTIVE));
                    let statusUUID = foundStatus?.user_status_uuid;
                    if (statusUUID) {
                        const payload: UpdateUserStatusInterface = {
                            user_status_uuid: statusUUID,
                            status_name: window.location.pathname === '/workItemNotifications' ? UserStatus.IDLE : UserStatus.ACTIVE,
                            reason: "Break ended",
                            category: window.location.pathname === '/workItemNotifications' ? UserStatus.IDLE : UserStatus.ACTIVE
                        }
                        updateUserStatus(payload);
                    } else {
                        console.error("IDLE status not found");
                    }
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            // You might want to handle this error in the component
            throw err;
        }
    };

    const updateUserStatus = async (request: UpdateUserStatusInterface) => {
        if (user) {
            const userUUID = user?.userId;
            const payload: UpdateUserStatusInterface = {
                user_uuid: userUUID,
                user_status_uuid: request.user_status_uuid,
                reason: request.reason,
                category: request.category
            };

            try {
                const response: any = await updateUserActivityStatus(payload);
                if (response?.status === 200) {
                    if (payload.category === UserStatus.BREAK) {
                        setUserBreak(true)
                        setGlobalUserStatus(UserStatus.BREAK)
                    }
                    else {
                        setUserBreak(false)
                        setGlobalUserStatus(UserStatus.IDLE)
                    }
                }
            } catch (error) {
                console.error("Error Updating Activity Status", error);
                // You might want to handle this error in the component
                throw error;
            }
        }
    };
    // MarkAllAsRead marks all the notifications as read
    // Pseudocode mapping:
    // PS_27 to PS_37 (SQ_EX_3.1 to SQ_EX_3.11)
    // 1. User selects "Mark All as Read".
    // 2. Update notifications status through NS (Notification Service).
    // 3. Update local notification state and unread count.
    const markAllNotificationsAsRead = async () => {
        try {
            if (user) {
                const payload: UpdateNotificationRequest = {
                    user_uuid: userId,
                    user_name: userName,
                    application_uuid: applicationUUID,
                    notification_uuid: "",
                    action: ActionsEnum.READALL
                }
                const apiResponse = await updateUserNotifications(payload)
                const { status, response: errResponse } = apiResponse
                if (status !== 200) {
                    throw new Error(JSON.stringify(errResponse?.data))
                }
                setNotifications(prevNotifications =>
                    prevNotifications.map((notification: NotificationDetails) => {
                        return { ...notification, marked_as_read: true }
                    }
                    )
                );
                setUnreadNotifications(0)
            }
        } catch (error: any) {
            console.log("An error occured: ", error.message)
        }
    }



    const markSingleNotificationAsRead = async (notificationUUID: string) => {
        try {
            if (user) {
                const payload: UpdateNotificationRequest = {
                    user_uuid: userId,
                    user_name: userName,
                    notification_uuid: notificationUUID,
                    application_uuid: applicationUUID,
                    action: ActionsEnum.READ
                }
                const apiResponse = await updateUserNotifications(payload)
                const { status, response: errResponse } = apiResponse
                if (status !== 200) {
                    throw new Error(JSON.stringify(errResponse?.data))
                }
                setNotifications(prevNotifications =>
                    prevNotifications.map((notification: NotificationDetails) =>
                        notification.notification_uuid === notificationUUID
                            ? { ...notification, marked_as_read: true }
                            : notification
                    )
                );
                setUnreadNotifications(prevUnreadNotifications => prevUnreadNotifications - 1)
            }
        } catch (error: any) {
            console.log("An error occured: ", error.message)
        }
    }

    const updateActiveStatus = async () => {
        if (user && window.location.pathname === '/workItemNotifications') {
            const currentStatus: any = await getUserStatus();
            if (currentStatus !== UserStatus.BREAK && currentStatus !== UserStatus.DND) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    const statusData = response?.data?.data;
                    const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.ACTIVE);
                    let statusUUID = foundStatus?.user_status_uuid;
                    if (statusUUID) {
                        const payload: UpdateUserStatusInterface = {
                            user_status_uuid: statusUUID,
                            status_name: UserStatus.ACTIVE,
                            reason: `Logged out of Operator Console Page`,
                            category: UserStatus.ACTIVE
                        }
                        updateUserStatus(payload);
                    } else {
                        console.error("ACTIVE status not found");
                    }
                }
            }
        }
    }

    return (
        isLoading ? <FullscreenLoader></FullscreenLoader> : <>
            <nav className="navbar navbar-expand-lg theme-header sticky-top">
                <div className="container-fluid px-4">
                    <div className="d-flex justify-content-between">
                        <button
                            className="navbar-toggler me-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <span className="navbar-brand d-flex align-items-center gap-3 me-5">
                            <img src="img/lynxis-vem-logo.svg" alt="logo" className="lynxis-header-logo" />
                        </span>
                    </div>
                    {/* Language selector*/}
                    <div className="d-flex d-lg-none gap-3">
                        <img src="img/orca-logo.svg" alt="Orca logo" />
                        {/* <div className="vr header-vr d-none d-lg-block" />
                        <div className="btn-group language-selector">
                            <button
                                className="border-0 bg-transparent d-flex align-items-center gap-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src="img/language-icon.svg" alt="language" />
                                <span className="font-semibold font-12 color-black">English</span>
                                <img
                                    src="img/chevron-blue.svg"
                                    alt="dropdown"
                                    className="lang-selector-dpd ms-2"
                                />
                            </button>
                            <ul className="dropdown-menu border-0 dropdown-menu-end">
                                <li>
                                    <div className="input-group border bg-white w-100 rounded-2 mb-1">
                                        <input
                                            type="text"
                                            className="form-control font-13 border-0 shadow-none bg-white"
                                            placeholder="Search"
                                            aria-label="Search"
                                        />
                                        <span className="input-group-text border-0 bg-transparent">
                                            <img src="img/search-icon.svg" alt="Search" />
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <ul className="p-0 language-options">
                                        <li className="dropdown-item">
                                            <span>Spanish</span>
                                        </li>
                                        <li className="dropdown-item active">
                                            <span>English</span>{" "}
                                            <img src="img/tick-icon-blue-sm.svg" alt="selected" />
                                        </li>
                                        <li className="dropdown-item">
                                            <span>French</span>
                                        </li>
                                        <li className="dropdown-item">
                                            <span>Italian</span>
                                        </li>
                                        <li className="dropdown-item">
                                            <span>Japanese</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}
                        <div className="vr header-vr d-none d-lg-block" />
                        {/* notification */}
                        <div className="btn-group notification-container-grp notification-box">
                            <button
                                type="button"
                                className="border-0 bg-transparent d-flex align-items-center position-relative"
                                onClick={() => setShowNotificationDropdown(prevShowNotificationDropdown => !prevShowNotificationDropdown)}
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded={showNotificationDropdown}
                            >
                                <img src="img/notification-bell-icon.svg" alt="notification" />
                                {unreadNotifications > 0 && (
                                    <span className="noti-alert position-absolute">{unreadNotifications}</span>
                                )}
                            </button>
                            <div className={`dropdown-menu border-0 dropdown-menu-end p-0 ${showNotificationDropdown ? 'show' : ''}`}>
                                <div className="p-3 d-flex align-items-center justify-content-between custom-shadow-sm">
                                    <h3 className="font-bold font-14 color-black-v1">
                                        Notifications
                                    </h3>
                                    <button
                                        className="d-flex align-items-center gap-1 border-0 p-0 bg-transparent primary-color-blue"
                                        type="button"
                                    >
                                        <img src="img/double-tick.svg" alt="double-tick" />
                                        <span className="font-semibold font-12" onClick={markAllNotificationsAsRead}>Mark All as Read</span>
                                    </button>
                                </div>
                                <NotificationHeaderList
                                    notifications={notifications}
                                    markSingleNotificationAsRead={markSingleNotificationAsRead}
                                    hasMore={hasMore}
                                    currentPage={currentPage}
                                    loadInitialNotificationData={loadInitialNotificationData}
                                />
                                <div className="d-flex justify-content-center custom-shadow-sm py-3">
                                    <button className="primary-btn text-center" type="button" onClick={() => {
                                        setShowNotificationDropdown(false)
                                        navigate("/notifications")
                                    }}>
                                        View All Notifications
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="vr header-vr d-none d-lg-block" />
                        {/* user settings */}
                        <div className="btn-group user-setting-dpd">
                            <button
                                type="button"
                                className="d-flex align-items-center gap-2 border-0 p-0 bg-transparent"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className={`position-relative dp-wrapper ${userStatus} user-dp-nouser d-flex justify-content-center align-items-center`}>
                                    {profileImage ? (
                                        <img src={profileImage} alt="user" className="user-dp" />
                                    ) : (
                                        getInitials(userDetails?.userName ?? "")
                                    )}
                                    <span className="user-status d-inline-block bottom-0" />
                                </div>
                                <div className="d-flex flex-column justify-content-start">
                                    <label className="font-semibold font-14 color-black cursor-pointer">
                                        {userDetails?.userName}
                                    </label>
                                    {!["/applications"].includes(location.pathname) && <span className="font-semibold font-9 color-grey text-start">
                                        {userRole === UserRoles.admin
                                            ? DisplayUserRoles.admin
                                            : userRole === UserRoles.supervisor
                                                ? DisplayUserRoles.supervisor
                                                : userRole === UserRoles.Operator
                                                    ? DisplayUserRoles.Operator
                                                    : null
                                        } {/* Display the user role */}
                                    </span>}
                                </div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 py-1 px-3">
                                {(userDetails?.appRole?.length ?? 0) > 1 && (
                                    <li onClick={() => { if (isEquipmentCorrection) return; navigate("/applications") }}>
                                        <span

                                            className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                        >
                                            <img src="img/arrows.svg" alt="arrows" />
                                            <span className={`${isEquipmentCorrection ? "" : "cursor-pointer"}`}>Switch Applications</span>
                                        </span>
                                    </li>
                                )}
                                {/* <li className="border-bottom">
                                    <span

                                        className={`${!isEquipmentCorrection && "dropdown-item"} d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2`}
                                    >
                                        <img src="img/usericon.svg" alt="user" />
                                        <span className={`${isEquipmentCorrection ? "" : "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return }}>Profile</span>
                                    </span>
                                </li> */}
                                <li>
                                    <span

                                        className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                        onClick={() => onClickLogOut()} >

                                        <img src="img/logout-icon.svg" alt="logout" />
                                        <span className={`${isEquipmentCorrection ? "color-red" : "color-red cursor-pointer"}`}> Logout</span>
                                    </span>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mt-3 mt-lg-0 mb-lg-0 d-flex gap-4">
                            {((userRole === UserRoles.supervisor || userRole === UserRoles.admin) && currentApplication === applicationAbbrievation.VEM && !["/applications"].includes(location.pathname)) && (
                                <li className="nav-item dropdown" ref={supervisorsRef}>
                                    <a
                                        className={`nav-link d-flex gap-1 align-items-center ${location.pathname.includes('/insights') ? "header-color" : ""}`}
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded={isSupervisorOpen}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleSupervisorDropdown();;
                                        }}
                                    >
                                        Insights <img src="img/chevron-blue.svg" alt="dropdown"
                                            style={{
                                                transition: 'transform 0.3s ease',
                                                transform: isSupervisorOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                            }}
                                        />
                                    </a>
                                    <ul className={`dropdown-menu ${isSupervisorOpen ? 'show' : ''}`}>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleSupervisorNavigate("/insights")}>
                                                Completed Work Items
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {((userRole === UserRoles.supervisor || userRole === UserRoles.admin || userRole === UserRoles.Operator) && currentApplication === applicationAbbrievation.VEM && !["/applications"].includes(location.pathname)) && (
                                <li className="nav-item dropdown" ref={operationsRef}>
                                    <a
                                        className={`nav-link d-flex gap-1 align-items-center ${isOperatorPathActive ? "header-color" : ""} `}
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded={isOperationsOpen}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleOperationsDropdown();
                                        }}
                                    >
                                        Operations <img src="img/chevron-blue.svg" alt="dropdown"
                                            style={{
                                                transition: 'transform 0.3s ease',
                                                transform: isOperationsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                            }} />
                                    </a>
                                    <ul className={`dropdown-menu ${isOperationsOpen ? 'show' : ''}`}>
                                        {(userRole === UserRoles.admin || userRole === UserRoles.supervisor) && (
                                            <li>
                                                <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                    onClick={() => handleOperationsNavigate("/operations")}>
                                                    Work Item Queue & User Activity
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleOperationsNavigate("/workItemNotifications")}>
                                                Operator Console
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {(userRole === UserRoles.supervisor) && (
                                <li className="nav-item dropdown" ref={adminRef}>
                                    <a
                                        className={`nav-link d-flex gap-1 align-items-center ${isAdminPathActive ? "header-color" : ""} }`}
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded={isAdminOpen}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleAdminDropdown();
                                        }}
                                    >
                                        Admin <img src="img/chevron-blue.svg" alt="dropdown" style={{
                                            transition: 'transform 0.3s ease',
                                            transform: isAdminOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                        }} />
                                    </a>
                                    <ul className={`dropdown-menu ${isAdminOpen ? 'show' : ''}`}>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/team")}>
                                                Team Management
                                            </a>
                                        </li>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/workItemSla")}>
                                                Work Item SLAs
                                            </a>
                                        </li>
                                        {userApps?.length > 1 && (
                                            <li>
                                                <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                    onClick={() => handleAdminNavigate("/applications")}>
                                                    Applications
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/shiftBreakTemplates")}>
                                                Shift & Break Templates
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {(userRole === UserRoles.admin) && (
                                <li className="nav-item dropdown" ref={adminRef}>
                                    <a
                                        className={`nav-link d-flex gap-1 align-items-center ${isAdminPathActive ? "header-color" : ""}`}
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded={isAdminOpen}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleAdminDropdown();
                                        }}
                                    >
                                        Admin <img src="img/chevron-blue.svg" alt="dropdown" style={{
                                            transition: 'transform 0.3s ease',
                                            transform: isAdminOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                        }} />
                                    </a>
                                    <ul className={`dropdown-menu ${isAdminOpen ? 'show' : ''}`}>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/userManagementGrid")}>
                                                User Management
                                            </a>
                                        </li>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/auditHistory")}>
                                                Audit History
                                            </a>
                                        </li>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/team")}>
                                                Team Management
                                            </a>
                                        </li>
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/workItemSla")}>
                                                Work Item SLAs
                                            </a>
                                        </li>
                                        {userApps?.length > 1 && (
                                            <li>
                                                <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                    onClick={() => handleAdminNavigate("/applications")}>
                                                    Applications
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a className={`dropdown-item ${!isEquipmentCorrection ? "cursor-pointer" : ""}`}
                                                onClick={() => handleAdminNavigate("/shiftBreakTemplates")}>
                                                Shift & Break Templates
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                        {/* Language selector*/}
                        <div className="d-none d-lg-flex gap-3">
                            <img src="img/orca-logo.svg" alt="Orca logo" />
                            {/* <div className="vr header-vr d-none d-lg-block" /> */}
                            {/* <div className="btn-group language-selector">
                                <button
                                    className="border-0 bg-transparent d-flex align-items-center gap-2"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src="img/language-icon.svg" alt="language" />
                                    <span className="font-semibold font-12 color-black">English</span>
                                    <img
                                        src="img/chevron-blue.svg"
                                        alt="dropdown"
                                        className="lang-selector-dpd ms-2"
                                    />
                                </button>
                                <ul className="dropdown-menu border-0 dropdown-menu-end">
                                    <li>
                                        <div className="input-group border bg-white w-100 rounded-2 mb-1">
                                            <input
                                                type="text"
                                                className="form-control font-13 border-0 shadow-none bg-white"
                                                placeholder="Search"
                                                aria-label="Search"
                                            />
                                            <span className="input-group-text border-0 bg-transparent">
                                                <img src="img/search-icon.svg" alt="Search" />
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <ul className="p-0 language-options">
                                            <li className="dropdown-item">
                                                <span>Spanish</span>
                                            </li>
                                            <li className="dropdown-item active">
                                                <span>English</span>{" "}
                                                <img src="img/tick-icon-blue-sm.svg" alt="selected" />
                                            </li>
                                            <li className="dropdown-item">
                                                <span>French</span>
                                            </li>
                                            <li className="dropdown-item">
                                                <span>Italian</span>
                                            </li>
                                            <li className="dropdown-item">
                                                <span>Japanese</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="vr header-vr d-none d-lg-block" />
                            {/* notification */}
                            <div className="btn-group notification-container-grp notification-box">
                                <button
                                    type="button"
                                    className="border-0 bg-transparent d-flex align-items-center position-relative"
                                    onClick={() => setShowNotificationDropdown(prevShowNotificationDropdown => !prevShowNotificationDropdown)}
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded={showNotificationDropdown}
                                >
                                    <img src="img/notification-bell-icon.svg" alt="notification" />
                                    {unreadNotifications > 0 && (
                                        <span className="noti-alert position-absolute">{unreadNotifications}</span>
                                    )}
                                </button>
                                <div className={`dropdown-menu border-0 dropdown-menu-end p-0 ${showNotificationDropdown ? 'show' : ''}`}>
                                    <div className="p-3 d-flex align-items-center justify-content-between custom-shadow-sm">
                                        <h3 className="font-bold font-14 color-black-v1">
                                            Notifications
                                        </h3>
                                        {unreadNotifications !== 0 && <button
                                            className="d-flex align-items-center gap-1 border-0 p-0 bg-transparent primary-color-blue"
                                            type="button"
                                        >
                                            <img src="img/double-tick.svg" alt="double-tick" />
                                            <span className="font-semibold font-12" onClick={markAllNotificationsAsRead}>Mark All as Read</span>
                                        </button>}
                                    </div>
                                    <NotificationHeaderList
                                        notifications={notifications}
                                        markSingleNotificationAsRead={markSingleNotificationAsRead}
                                        hasMore={hasMore}
                                        currentPage={currentPage}
                                        loadInitialNotificationData={loadInitialNotificationData}
                                    />
                                    <div className="d-flex justify-content-center custom-shadow-sm py-3">
                                        <button className="primary-btn text-center" type="button" onClick={() => {
                                            if (isEquipmentCorrection) return
                                            setShowNotificationDropdown(false)
                                            navigate("/notifications")
                                        }}>
                                            View All Notifications
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="vr header-vr d-none d-lg-block" />
                            {/* user settings */}
                            <div className="btn-group user-setting-dpd">
                                <button
                                    type="button"
                                    className="d-flex align-items-center gap-2 border-0 p-0 bg-transparent"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className={`position-relative dp-wrapper ${userStatus} user-dp-nouser d-flex justify-content-center align-items-center`}>
                                        {profileImage ? (
                                            <img src={profileImage} alt="user" className="user-dp" />
                                        ) : (
                                            getInitials(userDetails?.userName ?? "")
                                        )}
                                        <span className="user-status d-inline-block bottom-0" />
                                    </div>
                                    <div className="d-flex flex-column justify-content-start">
                                        <label className="font-semibold font-14 color-black cursor-pointer">
                                            {userDetails?.userName}
                                        </label>
                                        {!["/applications"].includes(location.pathname) && <span className="font-semibold font-9 color-grey text-start">
                                            {userRole === UserRoles.admin
                                                ? DisplayUserRoles.admin
                                                : userRole === UserRoles.supervisor
                                                    ? DisplayUserRoles.supervisor
                                                    : userRole === UserRoles.Operator
                                                        ? DisplayUserRoles.Operator
                                                        : null
                                            } {/* Display the user role */}
                                        </span>}
                                    </div>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 py-1 px-3">
                                    {(userDetails?.appRole?.length ?? 0) > 1 && (
                                        <li onClick={() => { if (isEquipmentCorrection) return; navigate("/applications") }}>
                                            <span

                                                className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                            >
                                                <img src="img/arrows.svg" alt="arrows" />
                                                <span className={`${isEquipmentCorrection ? "" : "cursor-pointer"}`}>Switch Applications</span>
                                            </span>
                                        </li>
                                    )}
                                    {/* <li className="border-bottom">
                                        <span

                                            className={`${!isEquipmentCorrection && "dropdown-item"} d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2`}
                                        >
                                            <img src="img/usericon.svg" alt="user" />
                                            <span className={`${isEquipmentCorrection ? "" : "cursor-pointer"}`} onClick={() => { if (isEquipmentCorrection) return }}>Profile</span>
                                        </span>
                                    </li> */}
                                    <li>
                                        <span

                                            className="dropdown-item d-flex align-items-center color-black-v1 font-regular font-13 gap-2 p-0 py-2"
                                            onClick={() => onClickLogOut()} >

                                            <img src="img/logout-icon.svg" alt="logout" />
                                            <span className={`${isEquipmentCorrection ? "color-red" : "color-red cursor-pointer"}`}> Logout</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </nav >
            {(showSessionTimeOutPopup) &&
                <SessionTimeOutPopup></SessionTimeOutPopup>}
        </>
    )
}