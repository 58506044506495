/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Popup from "../popup";
import { CountdownTimer } from "./count_down_timer_panel";
import { useNavigate } from "react-router-dom";
import { defaultRequeueAckTime, defaultRequeueTime } from "../../constant";

//PS_01 to PS_21
export const StatusBarPanel = ({ options, formContext, registry }: any) => {
  const navigate = useNavigate()
  const [showEscalateToLanePopup, setShowEscalateToLanePopup] = useState<boolean>(false);
  const [restartTimer, setRestartTimer] = useState<boolean>(true)
  const [navigateTimer, setNavigateTimer] = useState<any>(null);
  const { requeue_time_sec, requeue_ack_time_sec, expected_processing_time_sec, max_processing_time_sec, customer_name, site_name, startTime, completed, CompletedDurationSec } = formContext.navigationProps;
  const { laneDetails, handleEscalateToLane, handleReorderWorkItem,showContinueWorkingPopup,setShowContinueWorkingPopup } = formContext;
  let requeueAckTime = requeue_ack_time_sec === 0 ? Number(defaultRequeueAckTime) : requeue_ack_time_sec;
  let requeueTime = requeue_time_sec === 0 ? Number(defaultRequeueTime) : requeue_time_sec
  const { title } = registry.rootSchema
  const { showPopup, showEscalateToLane } = options
  const receivedTime: Date = startTime ? new Date(startTime) : new Date();

  useEffect(() => {
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('wheel', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('wheel', handleUserActivity);
    };
  }, [showContinueWorkingPopup]);

  useEffect(() => {
    if (!showPopup || completed) {
      return
    }
    const popupTimer = setTimeout(() => {
      setShowContinueWorkingPopup(true);
      const navigateTimer = setTimeout(() => {
        handleReorderWorkItem(true);
      }, requeueAckTime * 1000);
      setNavigateTimer(navigateTimer);
    }, requeueTime * 1000);
    return () => {
      clearTimeout(popupTimer);
      clearTimeout(navigateTimer);
    };
  }, [
    navigateTimer, restartTimer
  ]);
  const handlePopup = (button: string) => {
    setShowContinueWorkingPopup(false);
    switch (button) {
      case "No":
        handleReorderWorkItem(false);
        break;
      case "Yes":
        resetTimers();
        break;
    }
  }
  const handleEscalateToLanePopup = (button: string) => {
    if (completed) {
      return
    }
    setShowEscalateToLanePopup(false);
    switch (button) {
      case "Yes":
        handleEscalateToLane();
        break;
    }
  }
  const resetTimers = () => {
    if (navigateTimer) {
      clearTimeout(navigateTimer);
    }
    setShowContinueWorkingPopup(false);
    setNavigateTimer(null);
  };
  const handleUserActivity = () => {
    if (showContinueWorkingPopup) {
      return
    }
    setRestartTimer((prevRestartTimer) => !prevRestartTimer)
    resetTimers();
  };


  return (
    <>
      {showContinueWorkingPopup && !completed && (
        <Popup
          handleClick={handlePopup}
          popupMessage={"Are you still working on the work item?"}
        />
      )}
      {showEscalateToLanePopup && (
        <Popup
          handleClick={handleEscalateToLanePopup}
          popupMessage={"Are you sure want to defer to lane?"}
        />
      )}
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <nav aria-label="breadcrumb" className="theme-breadcrumbs">
            <ol className="breadcrumb py-2">
              {completed ? (
                <>
                  <li className="breadcrumb-item">
                    <span>Insights</span>
                  </li>
                  <li onClick={() => navigate("/insights")} className="breadcrumb-item cursor-pointer">Completed Work Items</li>
                </>
              ) : (
                <li className="breadcrumb-item disabled">
                  <span>Operator Console</span>
                </li>
              )}
              <li className="breadcrumb-item active" aria-current="page">
                {title} {laneDetails.lane_id && `On Lane ${laneDetails.lane_id}`}
              </li>
            </ol>
          </nav>
          <h2 className="m-0 font-bold font-16 color-black">
            {completed && (
              <a href=" " className="cursor-pointer pe-1" onClick={() => navigate("/insights")}>
                <img src="img/back-arw.svg" className="pb-1" alt="back-arw" />
              </a>
            )}
            {title} {laneDetails.lane_id && `On Lane ${laneDetails.lane_id}`}
          </h2>
        </div>
        <div className="d-flex align-items-end gap-3">
          {!laneDetails.lane_id && showEscalateToLane && !completed && (
            <button
              type="button"
              className="font-semibold font-12 color-white px-3 py-2 rounded-2 border-0 red-gradient-btn"
              onClick={() => setShowEscalateToLanePopup(true)}
            >
              Defer to Lane
            </button>
          )}
          <div className="px-3 py-2 d-flex align-items-center gap-3 info-wrapper">
            <div className="d-flex flex-column gap-2 pt-1">
              <label className="color-grey-v2 font-semibold font-10 lh-1">
                Customer
              </label>
              <p className="m-0 font-semibold font-14 color-black">
                {customer_name}
              </p>
            </div>
            <div className="vr header-vr"></div>
            <div className="d-flex flex-column gap-2 pt-1">
              <label className="color-grey-v2 font-semibold font-10 lh-1">
                Site
              </label>
              <p className="m-0 font-semibold font-14 color-black">
                {site_name}
              </p>
            </div>
          </div>
          <CountdownTimer
            receivedTime={receivedTime.toISOString()}
            normalTime={expected_processing_time_sec}
            moderateTime={max_processing_time_sec}
            CompletedDurationSec={CompletedDurationSec}
            pauseTimer={completed || false}
          />
        </div>
      </div>
    </>
  );
};