/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { addOldValueKey, getEquipmentIdByShortcut, getNestedProperty, updateNestedProperty } from "../../utils/utils";
import { ClassificationTypes, EquipmentTypes } from "../../enums/enums";
import { chassisRegex, containerRegex } from "../../constant";


//PS_01 to PS_43
export const EquipmentClassificationPanel = ({
  options,
  onChange,
  schema,
  formContext,
}: any) => {
  const { portalData, handleSubmit, navigationProps, handleFocusChange, showContinueWorkingPopup } = formContext;
  const { confidence_percentage: minimumConfidence, completed } = navigationProps;
  const { equipments, equipmentDetails } = options;
  const { submit, deselect } = options?.navigationShortcuts;
  let originalJSON = JSON.parse(JSON.stringify(portalData.current));
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const [newWorkItemJSON, setNewWorkItemJSON] = useState<any>(originalJSON);
  const [preSelectedEquipment, setPreSelectedEquipment] = useState<any>(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);

  const { setMatch } = formContext
  const handleItemSelect = useCallback(
    (itemId: string) => {
      try {
        if (completed || showContinueWorkingPopup) {
          return
        }
        let newSelectedEquipment = equipments.find(
          (eachEquipment: any) => eachEquipment.id === itemId
        );
        setSelectedEquipment(newSelectedEquipment);
        const {
          containerNameJsonKey,
          containerEquipmentAttachedJsonKey,
          defaultAttachedEquipment,
          name,
        } = newSelectedEquipment;
        //OK
        setNewWorkItemJSON((prevWorkItemJson: any) => {
          let originalCopy = JSON.parse(JSON.stringify(originalJSON))

          // Update license plate and equipment values
          originalCopy.scan_data.truck.license_plate.value = prevWorkItemJson.scan_data.truck.license_plate.value;
          originalCopy.scan_data.equipment.value = prevWorkItemJson.scan_data.equipment.value;

          originalCopy = updateNestedProperty(
            originalCopy,
            containerNameJsonKey,
            name,
            -1
          );
          if (selectedEquipment !== preSelectedEquipment) {
            addOldValueKey(defaultAttachedEquipment)
          }

          originalCopy = updateNestedProperty(
            originalCopy,
            containerEquipmentAttachedJsonKey,
            defaultAttachedEquipment,
            -1
          );

          // Update the equipment attached array if it exists in prevWorkItemJson
          const equipmentAttachedFromPrev = prevWorkItemJson.scan_data.equipment.equipment_attached;

          originalCopy.scan_data.equipment.equipment_attached.forEach((eachEquipmentAttached: any) => {
            const foundEquipment = equipmentAttachedFromPrev.find((eachPreviousAttachedEquipment: any) => {
              return (
                eachEquipmentAttached.sequence === eachPreviousAttachedEquipment.sequence &&
                eachEquipmentAttached.equipment_type === eachPreviousAttachedEquipment.equipment_type
              );
            });
            if (newSelectedEquipment !== preSelectedEquipment) {
              eachEquipmentAttached.old_value = ""
            }

            // If found, update the current equipment with the previous one
            if (foundEquipment) {
              eachEquipmentAttached.value = foundEquipment.value; // Update only the value property
              eachEquipmentAttached.confidence = foundEquipment.confidence;
            }
          });
          if (newSelectedEquipment === preSelectedEquipment) {
            let originalJson = JSON.parse(JSON.stringify(originalJSON))
            const equipmentAttachedFromOriginal = originalJson.scan_data.equipment.equipment_attached;
            originalCopy.scan_data.equipment.equipment_attached.forEach((eachPreviousAttachedEquipment: any, index: number) => {
              eachPreviousAttachedEquipment.value = equipmentAttachedFromOriginal[index].value
              eachPreviousAttachedEquipment.confidence = equipmentAttachedFromOriginal[index].confidence
            })
            originalCopy.scan_data.truck.license_plate.value = originalJson.scan_data.truck.license_plate.value;
            originalCopy.scan_data.equipment.value = originalJson.scan_data.equipment.value;
          }
          return originalCopy
        });
      } catch (error: any) {
        console.log("an error occured:", error.message)
      }
    },
    [equipments, originalJSON, completed, showContinueWorkingPopup]
  );
  useEffect(() => {
    setMatch(true)
  }, [setMatch])
  useEffect(() => {
    try {
      let disable = false;
      if (!newWorkItemJSON) return
      const { scan_data } = newWorkItemJSON
      switch (scan_data?.classification) {
        case ClassificationTypes.FLATRACK:
        case ClassificationTypes.BARECHASSIS:
          if (scan_data?.equipment?.value?.trim() === "" || !chassisRegex.test(scan_data?.equipment?.value)) {
            disable = true
          }
          break
        case ClassificationTypes.BOBTAIL:
          setDisableSubmitButton(false)
          return
        case ClassificationTypes.CHASSISBUNDLE:
          let nonEmptyChassisCount = scan_data?.equipment
            ?.equipment_attached?.filter((item: any) =>
              item.equipment_type === EquipmentTypes.CHASSIS && item.value && item.value.trim() !== ''
            ).length;
          if (scan_data?.equipment.value.trim() !== "") {
            nonEmptyChassisCount += 1
          }
          disable = nonEmptyChassisCount < 2;
          break
        default:
          if (newWorkItemJSON?.scan_data?.equipment
            ?.equipment_attached) {
            for (let eachEquipment of newWorkItemJSON?.scan_data?.equipment
              ?.equipment_attached) {
              if (eachEquipment?.value.trim() === "") {
                disable = true;
                break;
              }
            }
          }
          break
      }
      if (scan_data?.equipment && scan_data.equipment?.equipment_attached) {
        for (let eachEquipment of newWorkItemJSON?.scan_data?.equipment
          ?.equipment_attached) {
          if (eachEquipment.value.trim() !== "") {
            if (eachEquipment.equipment_type === EquipmentTypes.CONTAINER && !containerRegex.test(eachEquipment.value)) {
              disable = true;
            } else if (eachEquipment.equipment_type === EquipmentTypes.CHASSIS && !chassisRegex.test(eachEquipment.value)) {
              disable = true;
            }
          }
        }
      }
      setDisableSubmitButton(disable);
    } catch (error: any) {
      console.log("an error occured:", error.message)
    }
  }, [newWorkItemJSON, selectedEquipment]);
  useEffect(() => {
    try {
      if (portalData.current) {
        let matchedEquipment = equipments?.find(
          (eachEquipment: any) =>
            eachEquipment?.name === portalData.current?.scan_data?.classification
        );
        setSelectedEquipment(matchedEquipment);
        setPreSelectedEquipment(matchedEquipment);
      }
    } catch (error: any) {
      console.log("an error occured: ", error.message)
    }
  }, []);
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (completed || showContinueWorkingPopup) return;
        const { key } = event;
        const normalizedKey = typeof key === 'string' ? key.toLowerCase() : '';
        if (selectedEquipment) {
          switch (normalizedKey) {
            case submit.toLowerCase():
              if (disableSubmitButton === false) {
                handleSubmit(newWorkItemJSON, new Date().toISOString());
              }
              break;
            case deselect.toLowerCase():
              setSelectedEquipment(null);
              break;
            case "y":
            case "n":
              if (newWorkItemJSON?.scan_data?.classification === ClassificationTypes.BOBTAIL) {
                if (normalizedKey === "y") {
                  handleSubmit(newWorkItemJSON, new Date().toISOString());
                } else {
                  setSelectedEquipment(null);
                }
                return
              }
              break
          }
        } else {
          const selectedId = getEquipmentIdByShortcut(equipments, key);
          if (selectedId) {
            handleItemSelect(selectedId);
          }
        }
      }
      window.addEventListener("keydown", handleKeyPress);
      return () => window.removeEventListener("keydown", handleKeyPress);
    } catch (error: any) {
      console.log("an error occured:", error.message)
    }
  }, [
    selectedEquipment,
    deselect,
    submit,
    newWorkItemJSON,
    disableSubmitButton,
  ]);
  const handleChange = (event: any, containerSequence: any, jsonKeys: any) => {
    try {
      if (showContinueWorkingPopup) return
      if (containerSequence === undefined) {
        containerSequence = -1
      }
      let updatedNewWorkItemJson = updateNestedProperty(
        newWorkItemJSON,
        jsonKeys,
        event.target.value,
        containerSequence
      );
      setNewWorkItemJSON(updatedNewWorkItemJson);
    } catch (error: any) {
      console.log("an error occured: ", error.message)
    }
  }
  const bindEquipmentDetails = () => {
    try {
      return equipmentDetails[selectedEquipment.id]?.length > 0 ? (
        <>
          {equipmentDetails[selectedEquipment?.id]?.map(
            (container: any, index: number) => {
              let value: string = "";
              let valid: boolean = true
              let confidenceNumber: any = 0;
              let inputClassName: string = "";
              let indicatorUrl: string = "";
              let confidenceKey: string[] = [...container?.jsonKeys];
              confidenceKey[confidenceKey.length - 1] = "confidence";
              if (container?.sequence !== undefined) {
                value = getNestedProperty(
                  newWorkItemJSON,
                  container?.jsonKeys,
                  container?.sequence
                );
                confidenceNumber = getNestedProperty(
                  newWorkItemJSON,
                  confidenceKey,
                  container?.sequence
                );
              } else {
                value = getNestedProperty(
                  newWorkItemJSON,
                  container?.jsonKeys,
                  -1
                );
                confidenceNumber = getNestedProperty(
                  newWorkItemJSON,
                  confidenceKey,
                  -1
                );
              }
              let matchupOption: "correction" | "success" | "overwrite" = "overwrite";
              inputClassName = "input-grp-border";
              indicatorUrl = "";
              if (selectedEquipment === preSelectedEquipment) {
                if (confidenceNumber > minimumConfidence) {
                  matchupOption = "success";
                  inputClassName = "input-success";
                  indicatorUrl = "img/tick-icon-green.svg";
                } else {
                  matchupOption = "correction";
                  inputClassName = "input-correction";
                  indicatorUrl = "img/cancel-icon.svg";
                }
              }
              if (value !== "") {
                if (container.id === EquipmentTypes.CONTAINER && !containerRegex.test(value)) {
                  valid = false;
                  matchupOption = "correction";
                  inputClassName = "input-correction";
                  indicatorUrl = "img/cancel-icon.svg";
                } else if (container.id === EquipmentTypes.CHASSIS && !chassisRegex.test(value)) {
                  valid = false;
                  matchupOption = "correction";
                  inputClassName = "input-correction";
                  indicatorUrl = "img/cancel-icon.svg";
                }
              }
              if (completed) {
                inputClassName = ""
              }
              return (
                <div className="mb-3" key={index}>
                  <label
                    htmlFor={`container_${index}`}
                    className="m-0 font-semibold font-12 color-black-v1 mb-2"
                  >
                    {container?.label}
                  </label>
                  <div
                    className={`input-group rounded-2 overflow-hidden ${inputClassName}`}
                  >
                    <input
                      id={`container_${index}`}
                      type="text"
                      className={`form-control font-12 shadow-none ${inputClassName !== "inputCorrection" && "border-0"}`}
                      placeholder={container.placeholder}
                      onFocus={() => handleFocusChange(true)}
                      onBlur={() => handleFocusChange(false)}
                      aria-label={`Container #${index + 1}`}
                      name={container?.name}
                      value={value}
                      onChange={(event: any) =>
                        handleChange(
                          event,
                          container?.sequence,
                          container?.jsonKeys
                        )
                      }
                      maxLength={255}
                      disabled={matchupOption === "success" || completed}
                    />
                    {matchupOption !== "overwrite" && !completed && (
                      <span className={`input-group-text border-0 ${!completed ? `bg-transparent` : `bg-grey`}`}>
                        <img src={indicatorUrl} alt="cancel" />
                      </span>
                    )}
                  </div>
                  {!valid && <span className="font-semibold font-10 color-red">
                    {`Enter a valid ${container.id} number`}
                  </span>}
                </div>
              );
            }
          )}
          {!completed && (
            <button
              className="mt-4 primary-btn d-flex align-items-center ms-auto justify-content-center gap-2 align-self-end"
              type="button"
              disabled={disableSubmitButton}
              onClick={() => {
                handleSubmit(newWorkItemJSON, new Date().toISOString());
              }}
            >
              <span>Done</span>
              <span className="inner-btn">Enter</span>
            </button>
          )}
        </>
      ) : (
        <>
          <p className="font-semibold font-15 color-black text-center py-0">
            {completed ? "This equipment is selected as a Bobtail." : "Are you sure this is a Bobtail?"}
          </p>
          {!completed && <div className="mt-4 d-flex align-items-center justify-content-end gap-2 align-self-end">
            <button
              className="mt-auto secondary-btn no-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
              type="button"
              onClick={() => setSelectedEquipment(null)}
            >
              <span>No</span>
            </button>
            <button
              className="mt-auto primary-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
              type="button"
              onClick={() => handleSubmit(newWorkItemJSON, new Date().toISOString())}
            >
              <span>Yes</span>
              <span className="inner-btn">Enter</span>
            </button>
          </div>}
        </>
      );
    } catch (error: any) {
      console.log("an error occured: ", error.message)
    }
  }
  return (
    <div className="p-3 h-100">
      <div className="row min-h-75vh h-100">
        <div className="col-md-6 border-end h-100">
          <div className="d-flex align-items-center gap-2 pb-2">
            <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
              <img src="img/truck-icon.svg" alt="truck" />
            </span>
            <h2 className="font-bold font-14 color-black m-0">
              {schema.title}
            </h2>
            <span className="cursor-pointer position-relative tooltip-icon">
              <img
                src="img/tooltip-icon.svg"
                alt="sort-arrow-down"
                style={{ marginBottom: "2px" }}
              />
              <span className="custom-tooltip">
                <span className="color-white text-center font-regular">
                  Examine the images below to identify the truck's load type,
                  then select the appropriate option from the list below.
                </span>
              </span>
            </span>
          </div>
          <div className="items-wrapper">
            {options?.equipments?.map((item: any, index: any) => (
              <div
                key={index}
                className={`d-flex align-items-center py-3 px-1 border-bottom cursor-pointer item-select ${item.id === selectedEquipment?.id ? "active" : ""
                  }`}
                onClick={() => handleItemSelect(item.id)}
              >
                <div className="w-25">
                  <img src={item.image} alt={item.name} />
                </div>
                <span className="font-semibold font-12 color-black">
                  {item.name}
                </span>
                <span className="ms-auto keyboard-press font-regular font-10 d-flex align-items-center justify-content-center">
                  {item.shortcut}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6 h-100">
          <div className="field-container min-h-75vh h-100">
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
                <img src="img/product-details-icon.svg" alt="product details" />
              </span>
              <h2 className="font-bold font-14 color-black m-0">Details</h2>
              <span className="cursor-pointer position-relative tooltip-icon top-tooltip">
                <img
                  src="img/tooltip-icon.svg"
                  alt="sort-arrow-down"
                  style={{ marginBottom: "2px" }}
                />
                <span className="custom-tooltip">
                  <span className="color-white text-center font-regular">
                    Listed below is the information for the chassis and
                    containers related to this visit. If any information is
                    incorrect please correct it. When you are finished click
                    done.
                  </span>
                </span>
              </span>
            </div>
            {selectedEquipment?.id ? (
              bindEquipmentDetails()
            ) : (
              <div className="vh-50 d-flex align-items-center justify-content-center gap-3 flex-column ">
                <img src="img/list-icon.svg" alt="list" />
                <p className="font-semibold font-12 color-black text-center">
                  Select Equipment Classification to Continue.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};