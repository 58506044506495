/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GetRequestPayload, Meta } from "../../Interfaces/break_templates_interface";
import { deleteShiftTemplateService, getShiftTemplatesService } from "../../services/shift_templates_service";
import moment from "moment";
import PaginationComponent from "../../reusable_components/pagination";
import ToastMessage from "../../reusable_components/toast";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import { ShiftTemplatePopup } from "./shift_template_popup";
import { ShiftScroll } from "../../reusable_components/shift_scroll_component";
import { FullscreenLoader } from "../loader";

export function ShiftTemplatesGrid({ searchValue, dateFilter, showAddPopup, onCloseAddPopup }: any) {
    const [shiftTemplatesGridData, setShiftTemplatesGridData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [finalSearch, setFinalSearch] = useState<string>("");
    const initialPayloadData: GetRequestPayload = {
        search_value: '',
        created_date_from: '',
        created_date_to: '',
        modified_date_from: '',
        modified_date_to: '',
        sort_column: 'modified_on',
        sort_order: 'desc',
        page_number: 1,
        records_per_page: 10,
    };
    const [metaData, setMetaData] = useState<Meta>({
        page: 1,
        page_size: 10,
        total: 0
    });
    const [dateRange, setDateRange] = useState<GetRequestPayload>(initialPayloadData);
    const [finalDateRange, setFinalDateRange] = useState<GetRequestPayload>(initialPayloadData);
    const [hideSort, setHideSort] = useState<string>("");
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("");
    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [associatedTeams, setAssociatedTeams] = useState<string>("");
    const [showAssociatedTeamsAlertPopup, setShowAssociatesTeamsAlertPopup] = useState<boolean>(false);
    const [deleteShiftTemplateUUID, setDeleteShiftTemplateUUID] = useState<string>("");
    const [showShiftTemplatePopup, setShowShiftTemplatePopup] = useState<boolean>(false);
    const [popupMode, setPopupMode] = useState("add"); // "add" or "edit"
    const [selectedShiftTemplateUuid, setSelectedShiftTemplateUuid] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const getRequestPayload = useMemo(() => ({
        search_value: finalSearch,
        created_date_from: finalDateRange.created_date_from,
        created_date_to: finalDateRange.created_date_to,
        modified_date_from: finalDateRange.modified_date_from,
        modified_date_to: finalDateRange.modified_date_to,
        sort_column: hideSort?.split('@')[0] || "modified_on",
        sort_order: hideSort?.split('@')[1] || 'desc',
        page_number: metaData.page,
        records_per_page: metaData.page_size,
    }), [finalSearch, finalDateRange, hideSort, metaData.page, metaData.page_size]);

    useEffect(() => {
        getShiftTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRequestPayload]);

    const getShiftTemplates = async () => {
        try {
            setLoading(true);
            const response: any = await getShiftTemplatesService(getRequestPayload);
            if (response?.status === 200) {
                setShiftTemplatesGridData(response?.data?.data);
                setMetaData(response?.data?.meta);
            }
        } catch (err: any) {
            console.error(err);
            if (err?.status === 404) {
                setShiftTemplatesGridData([]);
            } else {
                setToastMessage(err?.message);
                setToastType("Error");
                setShowToast(true);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setFinalSearch(searchValue)
        setMetaData((prevMeta) => ({
            ...prevMeta,
            page: 1,
            page_size: 10
        }))
    }, [searchValue])

    useEffect(() => {
        setFinalDateRange(dateFilter)
        setMetaData((prevMeta) => ({
            ...prevMeta,
            page: 1,
            page_size: 10
        }))
    }, [dateFilter])

    useEffect(() => {
        if (showAddPopup === true) {
            handleAddClick();
        }
    }, [showAddPopup])

    const deleteShiftTemplate = async (request: any) => {
        const payload: any = {
            action: request?.action,
            shift_template_uuid: request?.shift_template_uuid
        };
        let finalRecord = shiftTemplatesGridData?.length === 1
        try {
            const response: any = await deleteShiftTemplateService(payload);
            if (response?.status === 200) {
                if (request.action === "VALIDATE") {
                    if (response?.data?.data?.is_associated_with_team) {
                        setAssociatedTeams(response?.data?.data?.team_details?.map((team: any) => team?.team_name).join(', '))
                        setShowAssociatesTeamsAlertPopup(true);
                    } else {
                        setShowConfirmDeletion(true);
                    }
                } else {
                    setToastMessage("Template deleted successfully");
                    setToastType("Success");
                    setShowToast(true);
                    if (finalRecord && metaData?.page !== 1) {
                        setMetaData((prevMeta: any) => ({
                            ...prevMeta,
                            page: prevMeta?.page - 1,
                        }))
                    } else await getShiftTemplates();
                }
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error");
            setShowToast(true);
        }
    };

    const handleAddClick = () => {
        setPopupMode('add');
        setSelectedShiftTemplateUuid("");
        setShowShiftTemplatePopup(true);
    }

    const handleEditClick = (shiftTemplateUuid: string) => {
        setPopupMode("edit");
        setSelectedShiftTemplateUuid(shiftTemplateUuid);
        setShowShiftTemplatePopup(true);
    };

    const cancelDeleteBreakTemplate = () => {
        setShowConfirmDeletion(false);
        setDeleteShiftTemplateUUID("");
    };

    const isValidDate = (date: any): boolean => {
        return date !== undefined && date !== null && date !== '';
    };

    const formatTime = (time: any) => {
        const date = new Date(time);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${minutes}:00`;
    };

    const formShiftScrollPayload = (startTime: string, endTime: string) => {
        const start = formatTime(startTime);
        const end = formatTime(endTime);

        return {
            shift_start_time: start,
            shift_end_time: end,
            break_ranges: []
        };
    };

    const handleSort = (sort_column: string, sort_order: string) => {
        setHideSort(`${sort_column}@${sort_order}`);
        setMetaData((prevMeta) => ({
            ...prevMeta,
            page: 1,
            page_size: 10,
        }));
    };

    const shiftTemplatesBinding = () => {
        return (shiftTemplatesGridData?.map((item: any, index: number) => {
            return (
                <tr key={index}>
                    <td title={item?.shift_name?.length > 30 ? item?.shift_name : ""}>{item?.shift_name?.length > 30 ? item?.shift_name.substring(0, 30) + "..." : item?.shift_name}</td>
                    <td className="align-middle">
                        <ShiftScroll shiftData={formShiftScrollPayload(item?.start_time, item?.end_time)} />
                    </td>
                    <td title={item?.created_by?.length > 30 ? item?.created_by : ""}>{item?.created_by?.length > 30 ? item?.created_by.substring(0, 30) + "..." : item?.created_by}</td>
                    <td>{moment.utc(item?.created_on).format('MM/DD/YYYY')}</td>
                    <td title={item?.modified_by?.length > 30 ? item?.modified_by : ""}>{item?.modified_by ? item?.modified_by?.length > 30 ? item?.created_by.substring(0, 30) + "..." : item?.modified_by : "-"}</td>
                    <td>{item?.modified_on ? moment.utc(item?.modified_on).format('MM/DD/YYYY') : "-"}</td>
                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="btn p-0 action-btn" onClick={() => handleEditClick(item?.shift_template_uuid)}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>

                            <button type="button" className="btn p-0 action-btn" onClick={() => {
                                setDeleteShiftTemplateUUID(item?.shift_template_uuid);
                                const payload: any = {
                                    action: "VALIDATE",
                                    shift_template_uuid: item?.shift_template_uuid
                                };
                                deleteShiftTemplate(payload);
                            }}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </div>
                    </td>
                </tr>
            );
        }));
    }


    return (
        loading ? <FullscreenLoader /> : <>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-images" role="tabpanel"
                    aria-labelledby="pills-images-tab" tabIndex={0}>
                    <div className="table-responsive theme-table bg-white w-100 rounded-3 mt-4 custom-spacing">
                        <table className="table mb-0">
                            <thead>
                                <tr>

                                    <th className={shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                        if (shiftTemplatesGridData && shiftTemplatesGridData.length > 1) {
                                            hideSort === "shift_name@asc" ? handleSort("shift_name", "desc") : handleSort("shift_name", "asc")
                                        }
                                    }}>
                                        Shift Name
                                        {shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? <> <span className="cursor-pointer">
                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                id="shiftaName@asc"
                                                className="sort_shift_name"
                                                hidden={hideSort === "shift_name@asc" ? false : true}
                                            />
                                        </span>

                                            <span className="cursor-pointer">
                                                <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_shift_name"
                                                    hidden={hideSort === "shift_name@asc" ? true : false} />
                                            </span></> : null}
                                    </th>
                                    <th>
                                        Shift Time <span className="cursor-pointer position-relative tooltip-icon">
                                            <img src="img/tooltip-icon.svg" alt="sort-arrow-down" style={{ marginBottom: "2px" }} />
                                            <span className="custom-tooltip">
                                                <span className="color-white font-9 font-regular">
                                                    Click the arrows on the bar to view the shift intervals.
                                                </span>
                                            </span>
                                        </span>
                                    </th>
                                    <th className={shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                        if (shiftTemplatesGridData && shiftTemplatesGridData.length > 1) {
                                            hideSort === "created_by@asc" ? handleSort("created_by", "desc") : handleSort("created_by", "asc")
                                        }
                                    }}>
                                        Created By
                                        {shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? <> <span className="cursor-pointer">
                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                id="shiftaName@asc"
                                                className="sort_created_by"
                                                hidden={hideSort === "created_by@asc" ? false : true}
                                            />
                                        </span>

                                            <span className="cursor-pointer">
                                                <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_created_by"
                                                    hidden={hideSort === "created_by@asc" ? true : false} />
                                            </span></> : null}
                                    </th>
                                    <th className={shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                        if (shiftTemplatesGridData && shiftTemplatesGridData.length > 1) {
                                            hideSort === "created_on@asc" ? handleSort("created_on", "desc") : handleSort("created_on", "asc")
                                        }
                                    }}>
                                        Created On
                                        {shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? <> <span className="cursor-pointer">
                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                id="shiftaName@asc"
                                                className="sort_created_on"
                                                hidden={hideSort === "created_on@asc" ? false : true}
                                            />
                                        </span>

                                            <span className="cursor-pointer">
                                                <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_created_on"
                                                    hidden={hideSort === "created_on@asc" ? true : false} />
                                            </span></> : null}
                                    </th>
                                    <th className={shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                        if (shiftTemplatesGridData && shiftTemplatesGridData.length > 1) {
                                            hideSort === "modified_by@asc" ? handleSort("modified_by", "desc") : handleSort("modified_by", "asc")
                                        }
                                    }}>
                                        Last Modified By
                                        {shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? <> <span className="cursor-pointer">
                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                id="shiftaName@asc"
                                                className="sort_modified_by"
                                                hidden={hideSort === "modified_by@asc" ? false : true}
                                            />
                                        </span>

                                            <span className="cursor-pointer">
                                                <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_modified_by"
                                                    hidden={hideSort === "modified_by@asc" ? true : false} />
                                            </span></> : null}
                                    </th>
                                    <th className={shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? "cursor-pointer" : ""} onClick={() => {
                                        if (shiftTemplatesGridData && shiftTemplatesGridData.length > 1) {
                                            hideSort === "modified_on@asc" ? handleSort("modified_on", "desc") : handleSort("modified_on", "asc")
                                        }
                                    }}>
                                        Last Modified On
                                        {shiftTemplatesGridData && shiftTemplatesGridData?.length > 1 ? <> <span className="cursor-pointer">
                                            <img src="img/sort-up-arrow.svg" alt="sort-arrow-down"
                                                id="shiftaName@asc"
                                                className="sort_modified_on"
                                                hidden={hideSort === "modified_on@asc" ? false : true}
                                            />
                                        </span>

                                            <span className="cursor-pointer">
                                                <img src="img/sort-down-arrow.svg" alt="sort-arrow-up" className="sort_created_on"
                                                    hidden={hideSort === "modified_on@asc" ? true : false} />
                                            </span></> : null}
                                    </th>
                                    <th className="text-center">Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {(shiftTemplatesGridData && shiftTemplatesGridData.length > 0) ? (
                                    shiftTemplatesBinding()
                                ) : (
                                    <NoRecordsFoundComponent
                                        colspan={7}
                                        mainContent={
                                            (getRequestPayload.search_value !== "" && getRequestPayload.created_date_from !== "" && getRequestPayload.created_date_to !== "" && getRequestPayload.modified_date_from !== "" && getRequestPayload.modified_date_to !== "")
                                                ? "No Templates Found"
                                                : "No Records Found"
                                        }
                                        subContent={
                                            (getRequestPayload.search_value !== "" && getRequestPayload.created_date_from !== "" && getRequestPayload.created_date_to !== "" && getRequestPayload.modified_date_from !== "" && getRequestPayload.modified_date_to !== "")
                                                ? "Templates will appear after they are created"
                                                : ""
                                        }
                                        columnHeight="50"
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(shiftTemplatesGridData && shiftTemplatesGridData?.length > 0) && <div className="mt-1"> <PaginationComponent totalCount={metaData?.total}
                        initialRecordsPerPage={metaData?.page_size} currentPage={metaData.page} setPageAndRecords={(currentPage: number, totalCount: number) => {
                            setMetaData((prev: any) => ({
                                ...prev, page: currentPage, page_size: totalCount
                            }))
                        }}></PaginationComponent></div>}
                </div>
            </div>

            {showAssociatedTeamsAlertPopup && <><div className="container-fluid position-fixed toastContainer warning-toast blur-backdrop">
                <div className="p-4 pb-3 toast-content d-flex align-items-start gap-3">
                    <img src="img/warning-icon-lg.svg" alt="warning" />
                    <div className="d-flex flex-column gap-4 mt-2">
                        <h3 className="font-bold font-16 color-white m-0">This shift is assigned to {associatedTeams} teams, so deletion cannot be performed right now</h3>
                        <div className="action-btns d-flex gap-2">
                            <button type="button" className="action-btn-yes font-10 font-semibold px-3 py-2 rounded-2" onClick={() => setShowAssociatesTeamsAlertPopup(false)}>OK</button>
                        </div>
                    </div>
                </div>
            </div><div className="modal-backdrop fade show blur-backdrop"></div></>}

            {showConfirmDeletion && (
                <DeletionConfirmationPopup
                    message="Are you sure you want to delete this shift template?"
                    onConfirmDelete={() => {
                        const payload: any = {
                            action: "DELETE",
                            shift_template_uuid: deleteShiftTemplateUUID
                        }
                        deleteShiftTemplate(payload);
                        setShowConfirmDeletion(false);
                    }}
                    onCancelDelete={cancelDeleteBreakTemplate}
                />
            )}

            {showToast && (
                <ToastMessage props={{
                    isToast: showToast,
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType,
                }} />
            )}

            {showShiftTemplatePopup &&
                <ShiftTemplatePopup
                    mode={popupMode}
                    shiftTemplateUuid={selectedShiftTemplateUuid}
                    onClose={() => {setShowShiftTemplatePopup(false); onCloseAddPopup()}}
                    onAddOrUpdate={() => {
                        var message
                        popupMode === 'edit' ? message = "Template updated successfully" :
                            message = "Template added successfully";
                        setToastMessage(message);
                        setToastType("Success");
                        setShowToast(true);
                        getShiftTemplates();
                    }}
                />
            }
        </>
    )
}