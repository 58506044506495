/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    GetNotificationRequest,
    NotificationDetails,
    ResponseMeta,
    UpdateNotificationRequest
} from "../../Interfaces/notification_interface";
import { useNavigate } from 'react-router-dom';
import { encryptKeys, encryptStorage, getDurationNameFilters, MinimumFilterDate } from "../../constant";
import { getUserNotifications, updateUserNotifications } from '../../services/notification_service';
import NoNotificationsFound from '../../reusable_components/no_notifications_found_component';
import { CompletedDurationFilters, ActionsEnum } from '../../enums/enums';
import { useKeyPress } from '../../hooks/hooks';
import { fetchUserDetailsFromStorage, formatDateLabelForGrid, formatTimeAgo, highlightQuotedText } from '../utils/utils';
import { FullscreenLoader } from '../loader';
import DeletionConfirmationPopup from '../../reusable_components/delete_popup';
import ToastMessage from '../../reusable_components/toast';

const NotificationGrid: React.FC = () => {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState<NotificationDetails[]>([])
    const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(false)
    const [advancedFilterApplied, setAdvancedFilterApplied] = useState<boolean>(false)
    const [showToast, setShowToast] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false)
    const [toastType, setToastType] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const [metaData, setMetaData] = useState<ResponseMeta>({
        page: 0,
        page_size: 0,
        total: 0,
    })
    const [deleteNotificationId, setDeleteNotificationId] = useState<string>("")
    const { userId, userName, appRole } = fetchUserDetailsFromStorage()
    const sessionApplication = encryptStorage.getItem(encryptKeys?.currentApplication);
    const applicationUUID = appRole?.find((role: any) => role.abbreviation === sessionApplication)?.app_uuid || "-";
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loader, setLoader] = useState<boolean>(false)
    const [unreadNotifications, setUnreadNotifications] = useState<number>(0)
    const [requestPayload, setRequestPayload] = useState<GetNotificationRequest>({
        user_uuid: userId,
        application_uuid: applicationUUID,
        filter: {
            search: "",
            duration: CompletedDurationFilters.TODAY,
            from_date: "",
            to_date: "",
        },
        page_number: 1,
        number_of_records_per_page: 10
    })
    const [dateKey, setDateKey] = useState<string>(getDurationNameFilters(CompletedDurationFilters.TODAY))
    // Pseudocode mapping for the initial data loading function
    // Maps to: PS 03, PS 13, PS 14, PS 20, PS 21, PS 22, PS 34, PS 37, PS 50, PS 76, PS 83
    /**
    * Function: loadInitialNotificationData
    * Description: Load initial or subsequent pages of notifications from the API and 
    * update the notifications state with the new data.
    */
    const loadInitialNotificationData = useCallback(async (page = 1) => {
        setLoader(true)
        try {
            let payload: GetNotificationRequest = {
                ...requestPayload,
                filter: {
                    ...requestPayload.filter,
                    search: requestPayload.filter.search?.trim()
                }
            }
            const apiResponse = await getUserNotifications({ ...payload, page_number: page });
            const { status, data: notificationResponse, response: errResponse } = apiResponse;
            if (status !== 200) {
                if (status === 404) {
                    if (page === 1) {
                        setNotifications([])
                        setMetaData({
                            page: 0,
                            page_size: 0,
                            total: 0,
                        })
                        setUnreadNotifications(0)
                    }
                    setHasMore(false); // No more data
                } else {
                    handleShowToast(true, "Error", errResponse?.data?.message);
                }
                throw new Error(errResponse?.data?.message);
            }
            const notificationDetails = notificationResponse.data.notifications.map((eachNotification: NotificationDetails) => ({
                ...eachNotification,
                received_at: new Date(eachNotification.received_at),
            }));
            if (page === 1) setNotifications(notificationDetails);
            else setNotifications(prev => [...prev, ...notificationDetails]);
            setMetaData(notificationResponse?.meta)
            setHasMore(notificationResponse?.data?.notifications.length > 0);
            setUnreadNotifications(notificationResponse?.data?.unread_count)
            setCurrentPage(page);
        } catch (error: any) {
            console.log("An error occured: ", error.message);
        }
        setLoader(false)
    }, [requestPayload]);
    // Pseudocode mapping for the useEffect hook
    // Maps to: PS 12, PS 23, PS 33, PS 34, PS 44, PS 45, PS 57, PS 66, PS 84
    /**
    * Effect hook: Initial load and re-fetch of notifications with filter changes
    */
    useEffect(() => {
        loadInitialNotificationData();
    }, [requestPayload.filter.duration, advancedFilterApplied]);
    // Pseudocode mapping for marking all notifications as read
    // Maps to: PS 58, PS 59, PS 60, PS 61, PS 64, PS 65
    /**
    * Function: markAllNotificationsAsRead
    * Description: Marks all notifications as read, making API call and updating state.
    */
    const markAllNotificationsAsRead = async () => {
        try {
            const payload: UpdateNotificationRequest = {
                user_uuid: userId,
                user_name: userName,
                application_uuid: applicationUUID,
                notification_uuid: "",
                action: ActionsEnum.READALL
            };
            const apiResponse = await updateUserNotifications(payload);
            const { status, response: errResponse } = apiResponse;
            if (status !== 200) {
                if (status !== 404) {
                    handleShowToast(true, "Error", errResponse?.data?.message);
                }
                throw new Error(errResponse?.data?.message);
            }
            setNotifications(prevNotifications =>
                prevNotifications.map((notification: NotificationDetails) => ({
                    ...notification,
                    marked_as_read: true
                }))
            );
            setUnreadNotifications(0)
        } catch (error: any) {
            console.log("An error occured: ", error.message);
        }
    };
    // Pseudocode mapping for deleting single notification
    // Maps to: PS 67, PS 68, PS 69, PS 70, PS 73, PS 74
    /**
    * Function: handleDeleteNotification
    * Description: Deletes a single notification based on notification UUID.
    */
    const handleDeleteNotification = async (notificationUUID: string) => {
        try {
            const payload: UpdateNotificationRequest = {
                user_uuid: userId,
                user_name: userName,
                application_uuid: applicationUUID,
                notification_uuid: notificationUUID,
                action: ActionsEnum.DELETE
            };
            const apiResponse = await updateUserNotifications(payload);
            const { status, response: errResponse } = apiResponse;
            if (status !== 200) {
                if (status !== 404) {
                    handleShowToast(true, "Error", errResponse?.data?.message);
                }
                throw new Error(errResponse?.data?.message);
            }
            setNotifications((prevNotifications: NotificationDetails[]) => {
                return prevNotifications.filter((eachNotification: NotificationDetails) => eachNotification.notification_uuid !== notificationUUID);
            });
            setMetaData((prevMetadata: ResponseMeta) => {
                return {
                    ...prevMetadata,
                    total: prevMetadata.total - 1
                }
            })
        } catch (error: any) {
            console.log("An error occured: ", error.message);
        } finally {
            setDeleteNotificationId("")
            setShowDeletePopup(false)
        }
    };
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };
    // Pseudocode mapping for handleFilterChange function
    // Pseudocode Description PS_24, PS_25, PS_26, PS_27, PS_35, PS_36, PS_37, PS_38, PS_45, PS_48, PS_49, PS_50, PS_51, PS_57
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setCurrentPage(1)
        setRequestPayload((prevRequestPayload: GetNotificationRequest) => ({
            ...prevRequestPayload,
            filter: {
                ...prevRequestPayload.filter,
                [id]: value
            }
        }));
    };
    // Pseudocode mapping for handleDurationChange function
    // Pseudocode Description PS_24, PS_25, PS_26, PS_27
    const handleDurationChange = (duration: string) => {
        setCurrentPage(1)
        setRequestPayload((prevRequestPayload: GetNotificationRequest) => ({
            ...prevRequestPayload,
            filter: {
                ...prevRequestPayload.filter,
                duration: duration
            }
        }));
        const { from_date, to_date } = requestPayload.filter
        if (from_date === "" && to_date === "") {
            setDateKey(getDurationNameFilters(duration))
        }
    };
    // Pseudocode mapping for handleAdvancedFilterApply function
    // Pseudocode Description PS_46, PS_47, PS_48, PS_49, PS_50, PS_51, PS_57
    const handleAdvancedFilterApply = () => {
        const { from_date, to_date } = requestPayload.filter
        setDateKey(`${formatDateLabelForGrid(from_date)} - ${formatDateLabelForGrid(to_date)}`)
        setCurrentPage(1)
        setShowAdvancedFilter(false);
        loadInitialNotificationData();
        setAdvancedFilterApplied((prevAdvancedFilter: boolean) => !prevAdvancedFilter)
    };
    // Pseudocode mapping for handleAdvancedFilterCancel function
    // Pseudocode Description PS_48, PS_49
    const handleAdvancedFilterCancel = () => {
        setCurrentPage(1)
        const { duration } = requestPayload.filter
        setDateKey(getDurationNameFilters(duration))
        setRequestPayload((prevRequestPayload: GetNotificationRequest) => ({
            ...prevRequestPayload,
            filter: {
                ...prevRequestPayload.filter,
                from_date: "",
                to_date: ""
            }
        }));
        setAdvancedFilterApplied((prevAdvancedFilter: boolean) => !prevAdvancedFilter)
    };
    // Pseudocode mapping for markSingleNotificationAsRead function
    // Pseudocode Description PS_76, PS_77, PS_78, PS_81, PS_83, PS_84
    const markSingleNotificationAsRead = async (notificationUUID: string) => {
        try {
            const payload: UpdateNotificationRequest = {
                user_uuid: userId,
                user_name: userName,
                notification_uuid: notificationUUID,
                application_uuid: applicationUUID,
                action: ActionsEnum.READ
            }
            const apiResponse = await updateUserNotifications(payload)
            const { status, response: errResponse } = apiResponse
            if (status !== 200) {
                if (status !== 404) {
                    handleShowToast(true, "Error", errResponse?.data?.message);
                }
                throw new Error(errResponse?.data?.message);
            }
            setNotifications(prevNotifications =>
                prevNotifications.map((notification: NotificationDetails) =>
                    notification.notification_uuid === notificationUUID
                        ? { ...notification, marked_as_read: true }
                        : notification
                )
            );
            setUnreadNotifications((prevUnreadNotifications: number) => prevUnreadNotifications - 1)
        } catch (error: any) {
            console.log("An error occured: ", error.message)
        }
    }
    // Pseudocode mapping for useKeyPress hook
    // Pseudocode Description PS_36, PS_37, PS_45
    useKeyPress('Enter', enableSearch && (requestPayload.filter.search === "" || requestPayload.filter.search?.trim() !== "") ? loadInitialNotificationData : () => { });

    const renderNotification = (notification: NotificationDetails, index: number) => (
        <div key={index} className={`notification-thread ${notification.marked_as_read ? '' : 'new'} d-flex align-items-center p-3 ps-2 pe-5 justify-content-between`} style={{ cursor: `${notification.marked_as_read ? "default" : "pointer"}` }} onClick={() => {
            if (!notification.marked_as_read) {
                markSingleNotificationAsRead(notification.notification_uuid);
            }
        }}>
            <div className="d-flex align-items-start gap-2 mb-2">
                <span className="d-inline-block notification-dot mt-2" />
                <div className={`d-flex flex-column ${!notification.customer_name && !notification.site_name && !notification.work_item_name ? "gap-1" : "gap-2"}`}>
                    <p className="m-0 d-flex align-items-center gap-2 font-14">
                        <span className="font-semibold color-black">{notification.notification_title}</span>
                        <span className="notification-title-seperator" />
                        <span className="font-regular color-grey-v3">{formatTimeAgo(notification.received_at)}</span>
                    </p>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        {notification.work_item_name && (
                            <p className="m-0 font-13 lh-1">
                                <span className="font-regular color-pale-black">Work Item:</span>
                                <span className="font-semibold color-black"> {notification.work_item_name}</span>
                            </p>
                        )}
                        {notification.work_item_name && (notification.customer_name || notification.site_name) && (
                            <div className="vr header" />
                        )}
                        {notification.customer_name && (
                            <p className="m-0 font-13 lh-1">
                                <span className="font-regular color-pale-black">Customer:</span>
                                <span className="font-semibold color-black"> {notification.customer_name}</span>
                            </p>
                        )}
                        {(notification.customer_name && notification.site_name) && (
                            <div className="vr header" />
                        )}
                        {notification.site_name && (
                            <p className="m-0 font-13 lh-1">
                                <span className="font-regular color-pale-black">Site:</span>
                                <span className="font-semibold color-black"> {notification.site_name}</span>
                            </p>
                        )}
                    </div>
                    <p className="m-0 font-regular font-12 color-pale-black">{highlightQuotedText(notification.notification_message)}</p>
                </div>
            </div>
            <button className="noti-delete p-0 border-0 align-items-center justify-content-center" type="button" onClick={() => {
                setShowDeletePopup(true)
                setDeleteNotificationId(notification.notification_uuid)
            }}>
                <img src="img/trash-bin-icon.svg" alt="Delete" />
            </button>
        </div>
    );

    const renderNotificationGroup = () => {
        return (
            <div className="notification-container-grp detail">
                <div className="day-noti-container mb-2 d-flex flex-column pb-2 gap-2">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h3 className="m-0 font-semibold font-16 primary-color-blue">
                            {dateKey} ({metaData?.total})
                        </h3>
                        {unreadNotifications !== 0 && (
                            <button
                                className="d-flex align-items-center gap-1 rounded-2 primary-color-blue border px-3 py-2 outline-btn"
                                type="button"
                                onClick={markAllNotificationsAsRead}
                            >
                                <img src="img/double-tick.svg" alt="double-tick" />
                                <span className="font-semibold font-12">Mark All as Read</span>
                            </button>
                        )}
                    </div>
                    {notifications.map(renderNotification)}
                </div>
            </div>
        );
    };

    return (
        <>
            {loader && <FullscreenLoader />}
            {showDeletePopup && <DeletionConfirmationPopup message={"Are you sure you want to delete this notification?"} onCancelDelete={() => {
                setDeleteNotificationId("")
                setShowDeletePopup(false)
            }} onConfirmDelete={() => handleDeleteNotification(deleteNotificationId)} />}
            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />}
            <div className="container-fluid">
                <div className="p-3 py-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h2 className="m-0 font-bold font-16 color-black d-flex align-items-center gap-2">
                            <p className="cursor-pointer mb-1" onClick={() => navigate(-1)}>
                                <img src="img/back-arw.svg" alt="back-arw" />
                            </p>
                            Notifications
                        </h2>
                        <div className="d-flex align-items-center gap-3">
                            <div className="dropdown time-dpd">
                                <button
                                    type="button"
                                    className="completed-work-item-global-filter px-3 shadow-btn btn-dpd d-flex align-items-center justify-content-between rounded-2 font-semibold font-14 color-black"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {requestPayload.filter.duration === "" ? "Select" : getDurationNameFilters(requestPayload.filter.duration)}
                                    <img className="ps-3" src="img/chevron-grey.svg" alt="drp" />
                                </button>
                                <div className="dropdown-menu border-0 custom-shadow px-4 py-3 shadow-sm dropdown-menu-end">
                                    <div className="row font-regular font-13 color-black-v2 g-3">
                                        {Object.values(CompletedDurationFilters)?.map((duration) => (
                                            <div
                                                key={duration}
                                                className="col-4"
                                                id="duration"
                                                onClick={() => handleDurationChange(duration)}
                                                role="button"
                                                tabIndex={0}>
                                                <span className={`cursor-pointer ${requestPayload.filter.duration === duration ? 'font-semibold primary-color-blue' : ''}`}>
                                                    {getDurationNameFilters(duration)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control font-semibold border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    id="search"
                                    onFocus={() => setEnableSearch(true)}
                                    onBlur={() => setEnableSearch(false)}
                                    onChange={handleFilterChange}
                                    value={requestPayload.filter.search}
                                />
                                <span className="input-group-text border-0 bg-transparent cursor-pointer" onClick={() => loadInitialNotificationData()}>
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                            <div className="dropdown filter-dpd">
                                <button
                                    className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                    type="button"
                                    data-bs-auto-close="outside"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => setShowAdvancedFilter(prevShowAdvancedFilter => !prevShowAdvancedFilter)}
                                >
                                    <img
                                        src="img/filter-icon-blue.svg"
                                        alt="filter"
                                        className="filter-img"
                                    />
                                    <span>Filter</span>
                                </button>
                                <div className={`dropdown-menu border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2 ${showAdvancedFilter ? 'show' : ''}`}>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="m-0 font-bold font-16 color-black-v1">
                                            Advanced Filter
                                        </h3>
                                        <button className="bg-transparent border-0" type="button" onClick={() => {
                                            setShowAdvancedFilter(false)
                                            handleAdvancedFilterCancel()
                                        }}>
                                            <img src="img/close-icon.svg" alt="close" />
                                        </button>
                                    </div>
                                    <h4 className="mb-3 font-semibold font-14 color-black-v1">
                                        Date & Time
                                    </h4>
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <label
                                                htmlFor="from_date"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                From
                                            </label>
                                            <input
                                                type="date"
                                                id="from_date"
                                                className="form-control font-12 theme-input shadow-none"
                                                onChange={handleFilterChange}
                                                min={MinimumFilterDate}
                                                max={requestPayload.filter.to_date ? requestPayload.filter.to_date : new Date().toISOString().split('T')[0]} onKeyDown={(e) => e.preventDefault()}
                                                value={requestPayload.filter.from_date}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label
                                                htmlFor="to_date"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                To
                                            </label>
                                            <input
                                                type="date"
                                                id="to_date"
                                                className="form-control font-12 theme-input shadow-none"
                                                onChange={handleFilterChange}
                                                value={requestPayload.filter.to_date}
                                                min={requestPayload.filter.from_date ? requestPayload.filter.from_date : MinimumFilterDate}
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex justify-content-end gap-3 mt-5">
                                                <button className="secondary-btn rounded-3" type="button" onClick={() => handleAdvancedFilterCancel()}>
                                                    <span className="d-inline-block my-1">Clear</span>
                                                </button>
                                                <button
                                                    className="primary-btn rounded-3"
                                                    type="button"
                                                    disabled={!(requestPayload.filter.from_date !== "" && requestPayload.filter.to_date !== "")}
                                                    onClick={handleAdvancedFilterApply}
                                                >
                                                    <span className="d-inline-block my-1">Apply</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={notifications.length}
                        next={() => loadInitialNotificationData(currentPage + 1)}
                        hasMore={hasMore}
                        loader={undefined}
                        scrollThreshold={0.9}
                    >
                        {notifications.length > 0 ? (
                            <div className="bg-white custom-shadow rounded-3 p-4">
                                {renderNotificationGroup()}
                            </div>
                        ) : (
                            <NoNotificationsFound />
                        )}
                    </InfiniteScroll>
                </div >
            </div >
        </>
    );
};

export default NotificationGrid;