import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { SortConfig } from "../../Interfaces/work_items_interface";
import ToastMessage from "../../reusable_components/toast";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import PaginationComponent from "../../reusable_components/pagination";
import { formatArrayDescription, formatDescription } from "../../constant";
import moment from "moment";
import { deleteUserPayLoadRequest, UserManagementPanelRequest, UserManagementPanel, UserManagementPanelFilterRequest, getApplicationCustomerRequestPayload, applicationInfo } from "../../Interfaces/user_management_interface";
import { deleteUser, fetchAllUserManagementDetails } from "../../services/user_management_service";
import { getCustomerWorkItemAndApplicationsService } from "../../services/work_items_sla_service";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";
import { useNavigate, useLocation } from "react-router-dom";
import { FullscreenLoader } from "../loader";

/**PS_UMP_02-PS_UMP_07 Declaration of the state variables and the necessary imports */
const UserManagementGrid = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userManagementInfo, setUserManagementInfo] = useState<UserManagementPanel[]>([]);
    const [applicationNames, setApplicationNames] = useState<applicationInfo[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<UserManagementPanelFilterRequest>({
        applicationFilter: [],
        statusFilter: undefined
    });
    const [inputSearchTerm, setInputSearchTerm] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'name', direction: 'asc' })
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [userManagementFilter, setUserManagementFilter] = useState<UserManagementPanelFilterRequest>({
        applicationFilter: [],
        statusFilter: undefined
    });
    const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const dropdownRef = useRef<any>(null);
    const [isApplyButtonClicked, setIsApplyButtonClicked] = useState(false);

    useEffect(() => {
        let userFormToastMessage = location?.state?.message ? location?.state?.message : ""
        if (userFormToastMessage) handleShowToast(true, "Success", `User ${userFormToastMessage} Successfully`);
        navigate(location?.pathname, { replace: true, state: null });
    }, [location?.pathname, location?.state?.message, navigate])

    /**PS_UMP_10- PS_UMP_21  - Callback function to be triggered whenever dependencies change */

    const getAllUserManagementDetails = useCallback(async () => {
        const details: UserManagementPanelRequest = {
            sort_column: sortConfig.key,
            sort_order: sortConfig.direction,
            search_value: searchTerm.trim(),
            records_per_page: limit,
            page_number: page,
            application_filter: userManagementFilter.applicationFilter ? userManagementFilter.applicationFilter : [],
            status_filter: userManagementFilter.statusFilter === 1 ? 1 : (userManagementFilter.statusFilter === 0 ? 0 : -1)
        };
        try {
            setIsLoading(true)
            const response: any = await fetchAllUserManagementDetails(details);
            if (response?.status === 200) {
                setUserManagementInfo(response.data.data || []);
                setTotalCount(response.data.meta.total || 0);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setTotalCount(0);
                setUserManagementInfo([]);
                return
            }
            else {
                console.error("Error fetching user management details", error);
                handleShowToast(true, "Error", error.message);
            }

        }
        finally {
            setIsLoading(false)
        }
    }, [sortConfig, userManagementFilter, limit, page, searchTerm]);

    /**PS_UMP_22- 25 - Callback function to be triggered during initialLoad */
    const getAllApplications = useCallback(async () => {
        try {
            let payLoad: getApplicationCustomerRequestPayload = {
                applications: true,
                customer_site: false,
                work_item: false
            }
            setIsLoading(true)
            const response: any = await getCustomerWorkItemAndApplicationsService(payLoad);
            if (response?.status === 200) {
                setApplicationNames(response?.data?.data?.applications || []);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setApplicationNames([]);
            }
            else {
                console.error("Error fetching all applications", error);
                handleShowToast(true, "Error", error.message);
            }

        }
        finally {
            setIsLoading(false)
        }
    }, []);

    /**PS_UMP_36 - 41 Function to invoke the deletion of the user */
    const invokeDeleteUser = async (userId: string) => {
        try {
            let deleteWorkItemPayload: deleteUserPayLoadRequest = {
                user_id: userId
            }
            const response: any = await deleteUser(deleteWorkItemPayload);
            if (response?.status === 200) {
                handleShowToast(true, "Success", "User Deleted Successfully");
                if (limit === 1 && page !== 1) {
                    setPage(prevPage => prevPage - 1);
                }
                else {
                    await getAllUserManagementDetails();
                }
            }
        } catch (error: any) {
            if (error?.response?.status === 409) {
                handleShowToast(true, "Error", "User is Currently Working");
                console.error("User is Currently Working , so couldn't delete")
            }
            else {
                handleShowToast(true, "Error", error.message);
                console.error(error.message)
            }
        }
        finally {
            setIsLoading(false)
        }
    };


    // New effect for fetching users only on initial load
    useEffect(() => {
        getAllApplications();
    }, [getAllApplications]);

    useEffect(() => {
        getAllUserManagementDetails();
    }, [getAllUserManagementDetails]);


    /**PS_UMP_30 - Function to handle the search Term Change */
    const applySearch = (isCancel: boolean = false) => {
        setPage(1);
        setLimit(10);
        setSearchTerm(isCancel ? '' : inputSearchTerm); // Set searchTerm to passiveSearchTerm
    };

    /**PS_UMP_28 - Function to handle the apply button */
    const handleApplyButton = () => {
        setUserManagementFilter({ applicationFilter: selectedFilter.applicationFilter, statusFilter: selectedFilter.statusFilter });
        setPage(1);
        setLimit(10);
        setShowFilter(false);
        setIsDropdownOpen(false);
        setIsApplyButtonClicked(true);
    };

    /**PS_UMP_31 to reset date filters and fetch data */
    const cancelFilter = () => {
        setPage(1);
        setLimit(10);
        setUserManagementFilter({ applicationFilter: [], statusFilter: undefined });
        setSelectedFilter({ applicationFilter: [], statusFilter: undefined });
        setIsDropdownOpen(false);
        setIsApplyButtonClicked(false);
    };

    const handlecloseFilter = () =>{         
        setShowFilter(false);
        if(!isApplyButtonClicked){
            setSelectedFilter({ applicationFilter: [], statusFilter: undefined });
        }
    }

    /**PS_UMP_29 - Function to handle the handleSort */
    const handleSort = (key: string) => {
        setPage(1);
        setLimit(10);
        setSortConfig((prevConfig: { key: string; direction: string; }) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    /** PS_UMP_47 - Callback function to handle page and records per page changes **/
    const handlePageAndRecordsChange = (currentPage: number, recordsPerPage: number) => {
        setPage(currentPage);
        setLimit(recordsPerPage);
    };

    /**PS_AUH_22 - Function to handle the renderSortIcon */
    const renderSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc'
                ? <img src="img/sort-up-arrow.svg" alt="sort-arrow-up" />
                : <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
        }
        return <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
    };

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };


    /**PS_UMP_42 Function to navigate to the userForm */
    const navigateToUserForm = (userId: string) => {
        navigate(`/userManagementForm`, {
            state: {
                user_uuid: userId
            },
        });
    };

    /**PS_UMP_36 - 41 Function to invoke the deletion of the user */
    const handleDeleteUser = (userId: string) => {
        setUserIdToDelete(userId);
        setShowConfirmDeletion(true);
    };

    /**PS_UMP_36 - 41 Function to invoke the deletion of the user */
    const confirmDelete = async () => {
        if (userIdToDelete) {
            await invokeDeleteUser(userIdToDelete);
        }
        setShowConfirmDeletion(false);
    };

    /**PS_UMP_36 - 41 Function to invoke the deletion of the user */
    const cancelDelete = () => {
        setShowConfirmDeletion(false);
        setUserIdToDelete(null); // Clear the delete request if cancelled
    };

    // onChange function to handle status filter changes
    const handleStatusChange = (value: number) => {
        const selectedValue = (value === 1 || value === 0) ? value : -1;
        setSelectedFilter(prevState => ({
            ...prevState,
            statusFilter: selectedValue
        }));
    };

    /**PS_UMP_27 - Function to handle the filter Change */
    const handleFilterChange = (e: ChangeEvent<HTMLInputElement> | any) => { // Adjust type here for flexibility
        const { name, value } = e.target;
        const checked = e.target.checked !== undefined ? e.target.checked : !selectedFilter.applicationFilter.includes(value);
        setSelectedFilter(prevState => {
            if (name === "applicationFilter") {
                const newApplicationFilter = checked
                    ? [...prevState.applicationFilter, value]
                    : prevState.applicationFilter.filter(item => item !== value);
                return {
                    ...prevState,
                    applicationFilter: newApplicationFilter
                };
            }
            return prevState;
        });
    };


    const getSelectedAbbreviations = () => {
        const selectedApps = applicationNames.filter(app => selectedFilter.applicationFilter.includes(app.application_uuid));
        return selectedApps.map(app => app.abbreviation).join(', ');
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    /**PS_Function to render the User Management */
    const renderUserManagement = () => {
        if (totalCount === 0) {
            return <NoRecordsFoundComponent
                colspan={7}
                mainContent="No Users Found"
                subContent="Users will be listed once onboarded"
                columnHeight='62'
            />
        }

        return (userManagementInfo?.map?.((user: UserManagementPanel) => {
            return (<>  <tr key={user.userId}>
                <td>
                    <span
                        data-bs-placement="right"
                        title={user.display_name?.length > 25 ? user.display_name : " "}
                        {...(user.display_name && user.display_name?.length > 25 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {user.display_name ? formatDescription(user.display_name, 25) : "-"}
                    </span>
                </td>
                <td>
                    <span
                        data-bs-placement="right"
                        title={user.business_email?.length > 25 ? user.business_email : " "}
                        {...(user.business_email && user.business_email?.length > 25 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {user.business_email ? formatDescription(user.business_email, 25) : "-"}
                    </span>
                </td>
                <td data-bs-toggle="tooltip" data-bs-placement="right" title={user?.application_name ? user.application_name : "-"}> {user?.application_name
                    ? formatArrayDescription(user.application_name)
                    : " -"}</td>
                <td>{user.last_login ? moment.utc(user.last_login).format("MM/DD/YYYY HH:mm") : " -"}</td>
                <td className="text-center">
                    <span className={`d-inline-flex align-items-center justify-content-center status ${user.status ? user.status.toLowerCase() : "-"}`}>
                        {user.status ? user.status : "-"}
                    </span>
                </td>
                <td className="text-center">
                    <span className="d-flex align-items-center justify-content-center gap-2">
                        <button type="button" className="btn p-0 action-btn">
                            <img src="img/edit-icon.svg" alt="edit-icon-blue" onClick={() => navigateToUserForm(user.userId)} />
                        </button>
                        <button type="button" className="btn p-0 action-btn">
                            <img src="img/delete-icon.svg" alt="bin-icon-red" onClick={() => handleDeleteUser(user.userId)} />
                        </button>
                    </span>
                </td>
            </tr>
            </>)
        }))
    }

    return (
        <>
            <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
                {isLoading ? (
                    <FullscreenLoader />
                ) : (
                    <div className="p-3 py-4">
                        <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    Admin
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    User Management
                                </li>
                            </ol>
                        </nav>                    <div className="d-flex align-items-center justify-content-between mb-3">
                            <h2 className="m-0 font-bold font-16 color-black">User Management</h2>
                            <div className="d-flex align-items-stretch gap-3">
                                <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                    <input
                                        type="search"
                                        className="form-control font-semibold border-0 shadow-none font-14"
                                        placeholder="Search"
                                        aria-label="Search"
                                        value={inputSearchTerm}
                                        onChange={(e) => setInputSearchTerm(e.target.value)}
                                        onKeyUp={(event) => event.key === "Enter" ? applySearch() : null}
                                    />
                                    <span className="input-group-text border-0 bg-transparent cursor-pointer" onClick={() => {
                                        applySearch()
                                    }}>
                                        <img src="img/search-icon.svg" alt="search" />
                                    </span>
                                </div>
                                {/* filter */}
                                <div className="dropdown filter-dpd">
                                    <button
                                        className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                        type="button"
                                        data-bs-auto-close="false"
                                        aria-expanded="false"
                                        onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <img
                                            src="img/filter-icon-blue.svg"
                                            alt="filter"
                                            className="filter-img"
                                        />
                                        <span>Filter</span>
                                    </button>
                                    {showFilter && (<div className="dropdown-menu border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2 show" style={{ position: 'absolute', right: '-5px' }}>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="m-0 font-bold font-16 color-black-v1">
                                                Advanced Filter
                                            </h3>
                                            <button className="bg-transparent border-0" type="button" onClick={() => { handlecloseFilter()}}>
                                                <img src="img/close-icon.svg" alt="close" />
                                            </button>
                                        </div>
                                        <div className="row g-3">
                                            <div className="col-6" ref={dropdownRef}>
                                                <label htmlFor="_Applications" className="font-semibold font-12 color-black-v1 form-label">
                                                    Applications
                                                </label>
                                                <span className="filter-drop-down">
                                                    <button
                                                        id="_Applications"
                                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12 cursor-pointer"
                                                        onClick={() => { toggleDropdown() }}
                                                        aria-expanded={isDropdownOpen}
                                                    >
                                                        <span>
                                                            {selectedFilter.applicationFilter?.length > 0
                                                                ? getSelectedAbbreviations()
                                                                : 'Select'}
                                                        </span>
                                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                                    </button>
                                                    <div>
                                                        {isDropdownOpen && (
                                                            <span className={`dropdown-menu font-regular mt-1 font-14 p-0 ${isDropdownOpen ? 'show' : "-"} `}>
                                                                {applicationNames?.map((item) => (
                                                                    <div
                                                                        className="form-check border-bottom py-2 ps-4 m-1 dropdown-item-hover"
                                                                        key={item?.application_uuid}
                                                                        onClick={(e) => { handleFilterChange({ target: { name: "applicationFilter", value: item.application_uuid } }) }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <input
                                                                            className="form-check-input shadow-none"
                                                                            type="checkbox"
                                                                            value={item.application_uuid}
                                                                            id={`app_${item.application_uuid}`}
                                                                            name="applicationFilter"
                                                                            checked={selectedFilter.applicationFilter.includes(item.application_uuid)}
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                        <label className="form-check-label font-semibold font-12" htmlFor={`app_${item.application_uuid}`}>
                                                                            {item?.abbreviation}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </span>
                                                        )}
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="col-6">
                                                <label
                                                    htmlFor="status"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    id="status"
                                                    className="form-select font-12 theme-input custom-input cursor-pointer"
                                                    value={selectedFilter.statusFilter}
                                                    onChange={(e) => { handleStatusChange(Number(e.target.value)) }}
                                                >
                                                    <option value={-1}>Select</option>
                                                    <option value={1}>Active</option>
                                                    <option value={0}>Inactive</option>
                                                </select>
                                            </div>
                                            <div className="co1-12">
                                                <div className="d-flex justify-content-end gap-3 mt-5">
                                                    <button className="secondary-btn rounded-3" type="button" onClick={() => { cancelFilter() }}>
                                                        <span className="d-inline-block my-1">Clear</span>
                                                    </button>
                                                    <button disabled={selectedFilter.applicationFilter?.length === 0 && selectedFilter.statusFilter === undefined}
                                                        onClick={() => { handleApplyButton() }}
                                                        className="primary-btn rounded-3"
                                                        type="button"
                                                    >
                                                        <span className="d-inline-block my-1">Apply</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <button className="primary-btn text-nowrap font-14" type="button" onClick={() => { navigateToUserForm('') }}>
                                    Add New User
                                </button>
                            </div>
                        </div>
                        {/* grid */}
                        <div className="table-responsive bg-white theme-table rounded-3 mb-3 custom-spacing">
                            <table className="table m-0 align-middle">
                                <thead>
                                    <tr className="alter">
                                        <th>
                                            {userManagementInfo?.length < 2 ? (
                                                <span>Name</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('name')}>
                                                    Name{" "}{renderSortIcon('name')}
                                                </span>
                                            )}
                                        </th>
                                        <th>Business Email</th>
                                        <th>
                                            {userManagementInfo?.length < 2 ? (
                                                <span>Application Name</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('applicationName')}>
                                                    Application Name{" "}{renderSortIcon('applicationName')}
                                                </span>
                                            )}
                                        </th>
                                        <th>
                                            {userManagementInfo?.length < 2 ? (
                                                <span>Last Login</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('lastLogin')}>
                                                    Last Login{" "}{renderSortIcon('lastLogin')}
                                                </span>
                                            )}
                                        </th>
                                        <th className="text-center">
                                            {userManagementInfo?.length < 2 ? (
                                                <span>Status</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('status')}>
                                                    Status{" "}{renderSortIcon('status')}
                                                </span>
                                            )}
                                        </th>

                                        {userManagementInfo?.length > 0 && (<th className="text-center">Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderUserManagement()}
                                </tbody>
                            </table>
                        </div>
                        {(userManagementInfo && userManagementInfo?.length > 0) && <div className="mt-1"><PaginationComponent
                            totalCount={totalCount}
                            initialRecordsPerPage={limit}
                            setPageAndRecords={(currentPage: number, limit: number) => {
                                handlePageAndRecordsChange(currentPage, limit)
                            }}
                            currentPage={page}
                        /></div>}

                    </div>)}
            </div>

            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />
            }

            {showConfirmDeletion && (
                <DeletionConfirmationPopup
                    message="Are you sure you want to delete this user?"
                    onConfirmDelete={confirmDelete}
                    onCancelDelete={cancelDelete}
                />
            )}
        </>
    )
};
export default UserManagementGrid