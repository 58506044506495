import InnerImageZoom from "react-inner-image-zoom";
import { ImageWithFallbackProps } from "../Interfaces/work_item_interface";
import { useState } from "react";

const ImageWithFallback = ({ imageUrl, fallbackUrl, enableZoom }: ImageWithFallbackProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const handleImageError = () => {
        setIsImageLoaded(false);
    };

    return (
        <>
            <img
                src={imageUrl}
                alt=""
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ display: "none" }}
            />
            {isImageLoaded ? (
                enableZoom ?
                    <InnerImageZoom zoomScale={1.5} src={imageUrl} className="container-view-img d-flex justify-content-center align-items-center" hideHint={true} moveType="drag" hideCloseButton={true} />
                    : <img src={imageUrl} alt="container-image" className="container-img" />
            ) : (
                <div className="container-view-img d-flex justify-content-center align-items-center flex-column">
                    <img src={fallbackUrl} alt="container-image" />
                    <span className="font-semibold font-8 color-grey-v2 mt-2">
                        Image Unavailable
                    </span>
                </div>
            )}
        </>
    );
};

export default ImageWithFallback;