/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { EditWorkItemSLARequest } from "../../Interfaces/work_items_sla_interface";
import PaginationComponent from "../../reusable_components/pagination";
import { createWorkItemSlaService, deleteOrValidateWorkItemSlaService, getCustomerWorkItemAndApplicationsService, getWorkItemSlaService } from "../../services/work_items_sla_service";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";
import WorkItemSLAPopup from "./add_work_item_sla";
import ToastMessage from "../../reusable_components/toast";
import { ActionsEnum } from "../../enums/enums";
import { FullscreenLoader } from "../loader";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";

export const WorkItemSLAComponent: React.FC = () => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [associatedTeams, setAssociatedTeam] = useState<string>("");
    const [showAssociatedTeamsAlertPopup, setShowAssociatesTeamsAlertPopup] = useState<boolean>(false);
    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [slaGridData, setSlaGridData] = useState<any[]>([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [applications, setApplications] = useState<any>([]);
    const [customer, setCustomers] = useState<any>([]);
    const [workItems, setWorkItems] = useState<any>([]);
    const [deleteSLAData, setDeleteSLAData] = useState<any>();
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [editCustomerName, setEditCustomerName] = useState<string>("");
    const [editSiteName, setEditSiteName] = useState<string>("")

    const [metaData, setMetaData] = useState<any>({
        page: 1,
        page_size: 10,
        total: 0
    });

    const [selectedApplication, setSelectedApplication] = useState<string>("");
    const [popupData, setPopupData] = useState<EditWorkItemSLARequest>({
        action: ActionsEnum.CREATE,
        customer_id: "",
        site_id: "",
        work_items: [],
        application_uuid: ""
    });

    const initialPayloadData: any = {
        sort_order: "",
        sort_column: "",
        page_number: 1,
        records_per_page: 10,
        application_uuid: "",
        search_value: ""
    }

    const [payload, setPayload] = useState<any>(initialPayloadData);

    // Memoize the payload
    const memoizedPayload: any = useMemo(() => payload, [
        payload.sort_order,
        payload.sort_column,
        payload.page_number,
        payload.records_per_page,
        payload.application_uuid,
        payload.search_value
    ]);

    useEffect(() => {
        getApplications();
    }, []);

    useEffect(() => {
        if (memoizedPayload.application_uuid) {
            getWorkItemsSla();
        }
    }, [memoizedPayload]);

    const getApplications = async () => {
        const payload: any = {
            applications: true,
            customer_site: false,
            work_item: false,
            eliminate_associated_customer_and_sites: true
        };
        try {
            const response: any = await getCustomerWorkItemAndApplicationsService(payload);
            if (response?.status === 200) {
                setApplications(response?.data?.data?.applications);
                const firstAppUuid: any = response?.data?.data?.applications[0].application_uuid;
                setSelectedApplication(firstAppUuid);
                setPayload((prev: any) => ({
                    ...prev,
                    application_uuid: firstAppUuid
                }));
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const getCustomersAndWorkItems = useCallback(async (application_uuid: string) => {
        const payload: any = {
            applications: false,
            customer_site: true,
            work_item: true,
            eliminate_associated_customer_and_sites: true,
            application_uuid: application_uuid
        };
        try {
            const response: any = await getCustomerWorkItemAndApplicationsService(payload);
            if (response?.status === 200) {
                setCustomers(response?.data?.data?.customer_sites);
                setWorkItems(response?.data?.data?.work_items);
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    }, []);

    const getWorkItemsSla = useCallback(async () => {
        try {
            setLoader(true);
            const response: any = await getWorkItemSlaService(memoizedPayload);
            if (response?.status === 200) {
                setSlaGridData(response?.data?.data);
                setMetaData(response?.data?.meta)
            }
        } catch (err: any) {
            console.error(err);
            if (err?.response?.status === 404) {
                setSlaGridData([]);
            }
            else {
                setToastMessage(err?.message);
                setToastType("Error")
                setShowToast(true);
            }
        } finally {
            setLoader(false);
        }
    }, [memoizedPayload]);

    const deleteWorkItemSla = async (customer_id: string, site_id: string, type: string) => {
        const payload: any = {
            customer_uuid: customer_id,
            site_uuid: site_id,
            action: type,
            application_uuid: selectedApplication
        };
        try {
            const response: any = await deleteOrValidateWorkItemSlaService(payload);
            if (response?.status === 200) {
                if (type === ActionsEnum.VALIDATE) {
                    if (response?.data?.data?.is_associated_with_team) {
                        setAssociatedTeam(response?.data?.data?.team_details?.map((team: any) => team?.team_name).join(', '));
                        setShowAssociatesTeamsAlertPopup(true);
                    } else {
                        setShowConfirmDeletion(true);
                    }
                } else if (type === ActionsEnum.DELETE) {
                    setShowConfirmDeletion(false);
                    getWorkItemsSla();
                    setToastMessage("SLA deleted successfully");
                    setToastType("Success")
                    setShowToast(true);
                }
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const cancelSlaDeletion = () => {
        setShowConfirmDeletion(false);
    };

    const getAbbreviation = (workItemName: string): string => {
        const words: string[] = workItemName?.split(' ');
        if (words.length === 1) {
            return workItemName?.slice(0, 2)?.toUpperCase();
        } else {
            return words?.map((word: string) => word[0]?.toUpperCase()).join('');
        }
    };

    const handleAddClick = () => {
        getCustomersAndWorkItems(selectedApplication);
        setPopupData({
            action: ActionsEnum.CREATE,
            customer_id: "",
            site_id: "",
            work_items: [],
            application_uuid: selectedApplication
        });
        setShowPopup(true);
    };

    const handleEditClick = (data: any) => {
        setEditCustomerName(data?.customer_name);
        setEditSiteName(data?.site_name);
        getCustomersAndWorkItems(selectedApplication);
        setPopupData({
            action: ActionsEnum.UPDATE,
            customer_id: data.customer_uuid,
            site_id: data.site_uuid,
            work_items: data.work_item_sla_details.map((item: any) => ({
                work_item_sla_id: item.workItem_sla_id,
                work_item_uuid: item.app_work_item_uuid,
                is_deleted: false
            })),
            application_uuid: selectedApplication
        });
        setShowPopup(true);
    };

    const handlePopupSave = async (data: EditWorkItemSLARequest) => {
        try {
            const response: any = await createWorkItemSlaService(data);
            if (response?.status === 201) {
                data?.action === ActionsEnum.CREATE ? setToastMessage("SLA added successfully") : setToastMessage("SLA updated successfully");
                setToastType("Success")
                setShowToast(true);
                setShowPopup(false);
                await getWorkItemsSla();
            }
        }
        catch (err: any) {
            console.error(err)
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const handleSort = (column: string) => {
        setPayload((prev: any) => ({
            ...prev,
            sort_column: column,
            sort_order: prev.sort_column === column && prev.sort_order === "asc" ? "desc" : "asc"
        }));
    };

    const handleSearch = (searchValue: string) => {
        setPayload((prev: any) => ({
            ...prev,
            search_value: searchValue,
            page_number: 1,
            records_per_page: 10,
        }));
    };

    const handleApplicationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedApplication(e.target.value);
        setPayload((prev: any) => ({
            ...prev,
            application_uuid: e.target.value,
            page_number: 1,
            records_per_page: 10,
        }));
    };

    const gridDataBinding = () => {
        return slaGridData?.map((data: any) => {
            const workItems: any = data?.work_item_sla_details?.map((item: any) => getAbbreviation(item?.work_item_name));
            const displayedItems: any = workItems?.slice(0, 3);
            const remainingItems: any = workItems?.slice(3);

            return (
                <tr key={data.customer_uuid}>
                    <td>
                        <a className="theme-breadcrumbs cursor-pointer" onClick={() => navigate("/workItemSlaAccordion", { state: { data: data, application_id: selectedApplication } })}>{data?.customer_name}</a>
                    </td>
                    <td>{data?.site_name}</td>
                    <td>
                        {displayedItems?.map((item: any, index: number) => (
                            <span key={index} className="gradient-tag me-1">{item}</span>
                        ))}
                        {remainingItems?.length > 0 && (
                            <span
                                className="gradient-tag me-1 cursor-pointer"
                                title={remainingItems?.join(', ')}
                            >
                                +{remainingItems?.length}
                            </span>
                        )}
                    </td>
                    <td className="text-center">
                        <span className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="btn p-0 action-btn" onClick={() => handleEditClick(data)}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>
                            <button type="button" className="btn p-0 action-btn" onClick={() => { setDeleteSLAData(data); deleteWorkItemSla(data?.customer_uuid, data?.site_uuid, ActionsEnum.VALIDATE) }}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </span>
                    </td>
                </tr>
            );
        });
    };

    return (
        loader ? <FullscreenLoader></FullscreenLoader> :
            <>
                <div className="container-fluid">
                    <div className="p-3 py-4">
                        <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a>Admin</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Work Item SLAs
                                </li>
                            </ol>
                        </nav>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <h2 className="m-0 font-bold font-16 color-black">Work Item SLAs</h2>
                            <div className="d-flex align-items-stretch gap-3">
                                <div className="d-flex align-items-center">
                                    <label
                                        htmlFor="_Applications"
                                        className="font-semibold font-14 color-black-v1 form-label mb-0 me-2"
                                    >
                                        Product:
                                    </label>
                                    <select
                                        id="_Applications"
                                        className="form-select font-12 theme-input custom-input custom-select font-bold work-item-sla-dropdown cursor-pointer"
                                        onChange={(event: any) => handleApplicationChange(event)}
                                        value={payload.application_uuid}
                                    >
                                        {applications?.map((item: any) => (
                                            <option key={item?.application_uuid} value={item?.application_uuid}>{item?.abbreviation}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                    <input
                                        type="search"
                                        className="form-control border-0 font-semibold shadow-none font-14"
                                        placeholder="Search"
                                        aria-label="Search"
                                        value={searchTerm}
                                        onChange={(event: any) => setSearchTerm(event.target.value)}
                                        onKeyUp={(event: any) => {
                                            event.key === "Enter" && handleSearch(searchTerm);
                                        }}
                                    />
                                    <span className="input-group-text border-0 bg-transparent cursor-pointer" onClick={() => handleSearch(searchTerm)}>
                                        <img src="img/search-icon.svg" alt="search" />
                                    </span>
                                </div>
                                <button className="primary-btn text-nowrap font-14" type="button" onClick={() => handleAddClick()}>
                                    Add SLA
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive bg-white theme-table rounded-3 mb-3 custom-spacing">
                            <table className="table m-0 align-middle">
                                <thead>
                                    <tr className="alter">
                                        <th className={`${slaGridData && slaGridData.length > 1 ? "cursor-pointer" : ""}`} onClick={() => { (slaGridData && slaGridData.length > 1) && handleSort("customer") }}>
                                            Customer
                                            {(slaGridData && slaGridData.length > 1) && <span>
                                                <img
                                                    src={`img/sort-${payload.sort_column === "customer" ?
                                                        (payload.sort_order === "asc" ? "up" : "down") :
                                                        "down"}-arrow.svg`}
                                                    alt="sort-arrow"
                                                    className="ms-1"
                                                />
                                            </span>}
                                        </th>
                                        <th className={`${slaGridData && slaGridData.length > 1 ? "cursor-pointer" : ""}`} onClick={() => { (slaGridData && slaGridData.length > 1) && handleSort("site") }}>
                                            Site
                                            {(slaGridData && slaGridData.length > 1) && <span>
                                                <img
                                                    src={`img/sort-${payload.sort_column === "site" ?
                                                        (payload.sort_order === "asc" ? "up" : "down") :
                                                        "down"}-arrow.svg`}
                                                    alt="sort-arrow"
                                                    className="ms-1"
                                                />
                                            </span>}
                                        </th>
                                        <th>
                                            Work Items
                                        </th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(slaGridData && slaGridData.length > 0) ? gridDataBinding() :
                                        <NoRecordsFoundComponent colspan={4} mainContent={!payload.search_value ? "No Data Found" : "No Records Found"} subContent={!payload.search_value ? "SLAs will be displayed when created" : ""} columnHeight={"50"}></NoRecordsFoundComponent>}
                                </tbody>
                            </table>
                        </div>
                        {(slaGridData && slaGridData?.length > 0) && (
                            <div className="mt-1">
                                <PaginationComponent
                                    totalCount={metaData?.total}
                                    initialRecordsPerPage={metaData?.page_size}
                                    currentPage={metaData.page}
                                    setPageAndRecords={(currentPage: number, totalCount: number) => {
                                        setPayload((prev: any) => ({
                                            ...prev,
                                            page_number: currentPage,
                                            records_per_page: totalCount < 10 ? 10 : totalCount
                                        }));
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <WorkItemSLAPopup
                    isOpen={showPopup}
                    onClose={() => setShowPopup(false)}
                    onSave={handlePopupSave}
                    isEditing={popupData.action === ActionsEnum.UPDATE}
                    initialData={popupData}
                    customers={customer}
                    workItems={workItems}
                    customerName={editCustomerName}
                    siteName={editSiteName}
                    selectedApplication={selectedApplication}
                />

                {showAssociatedTeamsAlertPopup && (<>
                    <div className="container-fluid position-fixed toastContainer warning-toast blur-backdrop">
                        <div className="p-4 pb-3 toast-content d-flex align-items-start gap-3">
                            <img src="img/warning-icon-lg.svg" alt="warning" />
                            <div className="d-flex flex-column gap-4 mt-2">
                                <h3 className="font-bold font-16 color-white m-0">This SLA is assigned to {associatedTeams} teams, so deletion cannot be performed right now</h3>

                                <div className="action-btns d-flex gap-2">
                                    <button type="button" className="action-btn-yes font-10 font-semibold px-3 py-2 rounded-2" onClick={() => setShowAssociatesTeamsAlertPopup(false)}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show blur-backdrop"></div>
                    </>
                )}

                {showConfirmDeletion && (
                    <DeletionConfirmationPopup
                        message="Are you sure you want to delete this SLA?"
                        onConfirmDelete={() => {
                            deleteWorkItemSla(deleteSLAData?.customer_uuid, deleteSLAData?.site_uuid, ActionsEnum.DELETE)
                        }}
                        onCancelDelete={() => cancelSlaDeletion()}
                    />
                )}

                {showToast && (
                    <ToastMessage props={{
                        isToast: showToast,
                        setIsToast: setShowToast,
                        toastMessage: toastMessage,
                        toastType: toastType,
                    }} />
                )}
            </>
    );
};

export default WorkItemSLAComponent;