/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { GetRequestPayload } from "../../Interfaces/break_templates_interface";
import { MinimumFilterDate } from "../../constant";
import { BreakTemplates } from "../break_template/break_templates";
import { ShiftTemplatesGrid } from "../shift_template/shift_templates";

export function BreakShiftTabComponent() {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [finalSearch, setFinalSearch] = useState<string>("");
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false);

    const initialPayloadData: GetRequestPayload = {
        search_value: '',
        created_date_from: '',
        created_date_to: '',
        modified_date_from: '',
        modified_date_to: '',
        sort_column: 'modified_on',
        sort_order: 'desc',
        page_number: 1,
        records_per_page: 10,
    };
    const [dateRange, setDateRange] = useState<GetRequestPayload>(initialPayloadData);
    const [finalDateRange, setFinalDateRange] = useState<GetRequestPayload>(initialPayloadData);
    const [selectedTab, setSelectedTab] = useState<string>("shift-templates");
    const [showPopup, setShowPopup] = useState<boolean>(false)

    // PS_BT_27: handleSearch function
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchValue = event.target.value;
        setSearchTerm(newSearchValue);
    };

    const handleApplySearch = () => {
        setFinalSearch(searchTerm?.trim());
    };

    const handleDateApply = () => {
        setFinalDateRange(dateRange)
    };

    const handleClearFilter = () => {
        setDateRange(initialPayloadData);
        setFinalDateRange(initialPayloadData);
    };

    const handleDateRangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateRange((prev) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const isValidDate = (date: any): boolean => {
        return date !== undefined && date !== null && date !== '';
    };

    const handleAddClick = () => {
        setShowPopup(true);
    }

    const handleCloseFilter = () => {
        if(finalDateRange.created_date_from === '' && finalDateRange.created_date_to === '' && finalDateRange.modified_date_from === '' && finalDateRange.modified_date_to === ''){
            setDateRange(initialPayloadData)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="p-3">
                    {/* Breadcrumbs */}
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a>Admin</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {selectedTab === "shift-templates" ? "Shift Templates" : "Break Templates"}
                            </li>
                        </ol>
                    </nav>
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <ul className="nav nav-pills theme-tab" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-images-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-images"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-images"
                                    aria-selected="true"
                                    onClick={() => { setSelectedTab("shift-templates"); setSearchTerm(""); setFinalSearch(""); setDateRange(initialPayloadData); setFinalDateRange(initialPayloadData) }}
                                >
                                    Shift Templates
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="pills-liveFeed-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-liveFeed"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-liveFeed"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => { setSelectedTab("break-templates"); setSearchTerm(""); setFinalSearch(""); setDateRange(initialPayloadData); setFinalDateRange(initialPayloadData) }}
                                >
                                    Break Templates
                                </button>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center gap-3 mt-3 mt-lg-0">
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control font-semibold border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={(event) => handleSearch(event)}
                                    onKeyUp={(event) => event.key === "Enter" ? handleApplySearch() : null}
                                    maxLength={255}
                                />
                                <span className="input-group-text border-0 bg-transparent cursor-pointer"
                                    onClick={() => handleApplySearch()}
                                >
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                            <div className="dropdown filter-dpd">
                                <button
                                    className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                    type="button"
                                    onClick={() => {setShowDateFilter(!showDateFilter)}}
                                >
                                    <img
                                        src="img/filter-icon-blue.svg"
                                        alt="filter"
                                        className="filter-img"
                                    />
                                    <span>Filter</span>
                                </button>
                                {
                                    showDateFilter &&
                                    <div
                                        className="dropdown-menu show active border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2"
                                        style={{position: "absolute", right: "0%" }}
                                    >
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="m-0 font-bold font-16 color-black-v1">
                                                Advanced Filter
                                            </h3>
                                            <button className="bg-transparent border-0" type="button"
                                                onClick={() => {setShowDateFilter(false); handleCloseFilter()}}
                                            >
                                                <img src="img/close-icon.svg" alt="close" />
                                            </button>
                                        </div>
                                        <h4 className="mb-3 font-semibold font-14 color-black-v1">
                                            Created Date Range
                                        </h4>
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <label
                                                    htmlFor="from_date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="date"
                                                    id="from_date"
                                                    name="created_date_from"
                                                    className="form-control font-12 theme-input shadow-none"
                                                    value={dateRange.created_date_from}
                                                    min={MinimumFilterDate}
                                                    max={dateRange.created_date_to ? dateRange.created_date_to : new Date().toISOString().split('T')[0]} onKeyDown={(e) => e.preventDefault()}
                                                    onChange={(event) => handleDateRangeSelection(event)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label
                                                    htmlFor="to_date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="date"
                                                    id="to_date"
                                                    name="created_date_to"
                                                    className="form-control font-12 theme-input shadow-none"
                                                    value={dateRange.created_date_to}
                                                    min={dateRange.created_date_from ? dateRange.created_date_from : MinimumFilterDate}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    onChange={(event) => handleDateRangeSelection(event)}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />
                                            </div>
                                        </div>
                                        <h4 className="mb-3 font-semibold font-14 color-black-v1 mt-4">
                                            Modified Date Range
                                        </h4>
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <label
                                                    htmlFor="from_date_md"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="date"
                                                    id="from_date_md"
                                                    name="modified_date_from"
                                                    className="form-control font-12 theme-input shadow-none"
                                                    value={dateRange.modified_date_from}
                                                    min={MinimumFilterDate}
                                                    max={dateRange.modified_date_to ? dateRange.modified_date_to : new Date().toISOString().split('T')[0]}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    onChange={(event) => handleDateRangeSelection(event)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label
                                                    htmlFor="to_date_md"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="date"
                                                    id="to_date_md"
                                                    name="modified_date_to"
                                                    className="form-control font-12 theme-input shadow-none"
                                                    value={dateRange.modified_date_to}
                                                    min={dateRange.modified_date_from ? dateRange.modified_date_from : MinimumFilterDate}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    onChange={(event) => handleDateRangeSelection(event)}
                                                    max={new Date().toISOString().split('T')[0]}

                                                />
                                            </div>
                                            <div className="co1-12">
                                                <div className="d-flex justify-content-end gap-3 mt-4">
                                                    <button className="secondary-btn rounded-3" type="button"
                                                        onClick={() => { handleClearFilter() }}
                                                    >
                                                        <span className="d-inline-block my-1">Clear</span>
                                                    </button>
                                                    <button
                                                        className="primary-btn rounded-3"
                                                        type="button"
                                                        disabled={
                                                            !(isValidDate(dateRange.modified_date_to) ||
                                                                isValidDate(dateRange.modified_date_from) ||
                                                                isValidDate(dateRange.created_date_from) ||
                                                                isValidDate(dateRange.created_date_to)) ||
                                                            (isValidDate(dateRange.created_date_from) && !isValidDate(dateRange.created_date_to)) ||
                                                            (isValidDate(dateRange.created_date_to) && !isValidDate(dateRange.created_date_from)) ||
                                                            (isValidDate(dateRange.modified_date_from) && !isValidDate(dateRange.modified_date_to)) ||
                                                            (isValidDate(dateRange.modified_date_to) && !isValidDate(dateRange.modified_date_from))
                                                        }
                                                        onClick={() => {
                                                            handleDateApply();
                                                            setShowDateFilter(false)
                                                        }}
                                                    >
                                                        <span className="d-inline-block my-1">Apply</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <button
                                className="border-0 snooze-btn active font-semibold font-12 text-nowrap"
                                type="button"
                                onClick={() => handleAddClick()}
                            >
                                {selectedTab === 'shift-templates' ? "Add Shift Template" : "Add Break Template"}
                            </button>
                        </div>
                    </div>
                    {
                        selectedTab === 'shift-templates' ?
                            <ShiftTemplatesGrid searchValue={finalSearch} dateFilter={finalDateRange} showAddPopup={showPopup} onCloseAddPopup={() => {
                                setShowPopup(false);
                            }} /> : <BreakTemplates searchValue={finalSearch} dateFilter={finalDateRange} showAddPopup={showPopup} onCloseAddPopup={() => {
                                setShowPopup(false);
                            }} />
                    }
                </div></div>

        </>
    )

}