import InfiniteScroll from "react-infinite-scroll-component";
import { NotificationDetails } from "../../Interfaces/notification_interface";
import { formatDateLabel, formatTimeAgo, groupNotificationsByDate, highlightQuotedText } from "../utils/utils";


// NotificationList renders a list of notifications with interaction capabilities.
// Pseudocode mapping:
// PS_21 to PS_26 (SQ_EX_2.3 to SQ_EX_2.8)
// 1. Display the notification dropdown on bell icon click.
// 2. Format date labels and times for each notification.
// 3. Render each notification item with appropriate styles and mark-as-read functionality.
// 4. Update the UI to show the notification dropdown with all items rendered.
const NotificationHeaderList: React.FC<any> = ({ notifications, markSingleNotificationAsRead, hasMore, currentPage, loadInitialNotificationData }) => {
    const groupedNotifications = groupNotificationsByDate(notifications);
    const sortedDates = Object.keys(groupedNotifications).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const renderNotification = (notification: NotificationDetails, index: number) => (
        <div key={index} className={`notification-thread ${notification.marked_as_read ? '' : 'new'} d-flex align-items-start p-2 rounded-3 gap-1`} style={{ cursor: `${notification.marked_as_read ? "default" : "pointer"}` }} onClick={() => {
            if (!notification.marked_as_read) {
                markSingleNotificationAsRead(notification.notification_uuid);
            }
        }}>
            <span className="d-inline-block notification-dot" />
            <div className={`d-flex flex-column ${!notification.customer_name && !notification.site_name && !notification.work_item_name ? "gap-1" : "gap-2"}`}>
                <p className="m-0 d-flex align-items-center gap-1">
                    <span className="font-semibold font-10 color-black">
                        {notification.notification_title}
                    </span>
                    <span className="notification-title-seperator" />
                    <span className="font-regular font-9 color-grey-v3">
                        {formatTimeAgo(notification.received_at)}
                    </span>
                </p>
                <div className="d-flex flex-wrap align-items-center gap-2">
                    {notification.work_item_name && (
                        <p className="m-0 font-10 lh-1">
                            <span className="font-regular color-pale-black">Work Item:</span>
                            <span className="font-semibold color-black"> {notification.work_item_name}</span>
                        </p>
                    )}
                    {(notification.work_item_name && (notification.customer_name || notification.site_name)) && (
                        <div className="vr header" />
                    )}
                    {notification.customer_name && (
                        <p className="m-0 font-10 lh-1">
                            <span className="font-regular color-pale-black">Customer:</span>
                            <span className="font-semibold color-black"> {notification.customer_name}</span>
                        </p>
                    )}
                    {(notification.customer_name && notification.site_name) && (
                        <div className="vr header" />
                    )}
                    {notification.site_name && (
                        <p className="m-0 font-10 lh-1">
                            <span className="font-regular color-pale-black">Site:</span>
                            <span className="font-semibold color-black"> {notification.site_name}</span>
                        </p>
                    )}
                </div>
                <p className="m-0 font-regular font-10 color-pale-black">{highlightQuotedText(notification.notification_message)}</p>
            </div>
        </div >
    );



    const renderNotificationGroup = (dateKey: string) => {
        const notificationsForDate = groupedNotifications[dateKey];
        const isLastGroup = sortedDates.indexOf(dateKey) === sortedDates.length - 1; // Check if this is the last group

        return (
            <div key={dateKey} className={`day-noti-container ${isLastGroup ? '' : 'border-bottom'} mb-2 d-flex flex-column gap-2 pb-2`}>
                <p className="font-semibold font-12 primary-color-blue w-100 ms-2">
                    {formatDateLabel(dateKey)}
                </p>
                {notificationsForDate.map(renderNotification)}
            </div>
        );
    };

    return (
        <div id="scrollableDiv" className="noti-container-wrapper">
            {notifications.length > 0 ? <InfiniteScroll
                dataLength={notifications.length}
                next={() => loadInitialNotificationData(currentPage + 1)}
                hasMore={hasMore}
                loader={undefined}
                scrollableTarget="scrollableDiv"
                scrollThreshold={0.9}
            >
                <div className="p-2">
                    {sortedDates.map((dateKey, index) => renderNotificationGroup(dateKey))}
                </div>
            </InfiniteScroll> :
                <div className="noti-container-empty d-flex align-items-center justify-content-center flex-column gap-1 py-5 h-100">
                    <img src="img/notification-bell-icon-lg.svg" alt="notification" />
                    <h3 className="m-0 mt-1 font-semibold font-12 color-black">No Notifications</h3>
                    <p className="m-0 font-semibold font-10 color-grey-v1">Stay tuned for updates and alerts.</p>
                </div>}
        </div >
    );
};

export default NotificationHeaderList;