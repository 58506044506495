import { EncryptStorage } from "encrypt-storage";
import { endpoints, UserApplication } from "./Interfaces/login_interface";
import { breakEndpoints } from "./Interfaces/break_templates_interface";
import { EquipmentCorrectionEndpoints } from "./Interfaces/work_item_interface";
import { applicationAbbrievation, applicationName, CompletedDurationFilters, UserRoles, UserStatus } from "./enums/enums";
import { SortConfig } from "./Interfaces/work_items_interface";

export const baseURL: string | undefined = process.env.REACT_APP_BE_URL;
export const blobURL: string | undefined = process.env.REACT_APP_BLOB_URL;
export const defaultRequeueTime: string = process.env.DEFAULT_REQUEUE_TIME || "15"
export const defaultRequeueAckTime: string = process.env.DEFAULT_REQUEUE_ACK_TIME || "5"
export const defaultSesionTimeOut: string = process.env.REACT_APP_SESSION_TIMEOUT_MILLI_SECONDS || "1800000"
export const webRTCStreamPort: string = process.env.WEBRTC_STREAM_PORT || "5004"
export const rtpBaseURL: string = process.env.RTP_BASE_URL || "rtp://localhost"


export const authentication: endpoints = {
  generateAuthToken: "/auth/token",
  generateAuthTokenByRefreshToken: "/auth/token/refresh",
};

export const router = {
  applications: "/applications",
};

export const encryptKeys = {
  profile: "yar64876-dhghd6-854687-hjbdfjidhu",
  userDetails: "yar85-8jhgfj-76843-79hfhj-hhipathi",
  role: "enna-y8648h-jguyfu-t75yhe-rvela",
  currentApplication: "sure83-9kdjfk-57392-38nfkd-kekfrolo",
};

const secret: any = process.env.REACT_APP_ENCRYPT_SECRET;

export const encryptStorage = new EncryptStorage(secret ? secret : "", {
  stateManagementUse: true,
  storageType: "sessionStorage",
});

export const operatorConsoleService = {
  getQueuedWorkItems: "/vem/work-item/get-work-items",
  deleteWorkItem: "/vem/work-item/escalate-to-lane",
  escalateToCustomer: "/vem/work-item/escalate-to-customer",
  takeWorkItem: "/vem/work-item/take",
  takeOverWorkItem: "/vem/work-item/takeover",
  assignQueuedWorkItem: "/vem/work-item/assign-work-item",
  getAvailableOperators: "/vem/work-item/get-eligible-operators",
  getLiveWorkItemsNotification: "/vem-notification/subscribe?topics=WorkItemQueue",
  reArrangeWorkItem: "/vem-work-item-tracker/rearrange-work-item",
  getUserRecentStatus: "/vem/user/status",
  getStatus: "/vem/user-activity/status",
  assignWorkItem: "/vem/work-item/take",
  updateSchedule: "/vem/user-activity/update-schedule",
  getSchedule: "/vem/user-activity/get-current-shift-break",
  getShiftBreakAndTimeZoneDetails: "/vem/break-templates/get-shift-break-timezone-details"
};

export const breakTemplate: breakEndpoints = {
  createBreak: "/vem/break-templates/create",
  editBreak: "/vem/break-templates/update",
  deleteBreak: "/vem/break-templates/delete",

  getBreakDetails: "/vem/break-templates/get",
  getAssociatedTeams: "/vem/break-templates/associated-teams"
};

export const teams = {
  getUsers: "/vem/team/fetch-users",
  getProfileImg: "/vem/team/get-profile-image",
  postTeam: "/vem/team/create",
  getAllTeams: "/vem/team/get",
  getTeamById: "/vem/team/get-by-id",
  updateTeam: "/vem/team/update",
  deleteTeam: "/vem/team/delete"
}

export const streamingService = {
  LaneDevices: "/vem/streaming/get-lane-devices",
  CamControl: "/vem/streaming/control-lane-camera",
  CamStatus: "/vem/streaming/get-camera-status",
  SignalWebRTC: "/vem/streaming/signal-webrtc",
  RegisterWebRTC: "/vem/streaming/register-webrtc",
  StartRTPStream: "/vem/streaming/start-rtp-stream",
  StopRTPStream: "/vem/streaming/stop-rtp-stream",
  GetRTPStreamStatus: "/vem/streaming/check-rtp-stream-status"
}



//PS_15-Function to formate the description
export const formatDescription = (description: string, size: number) => {
  if (description?.length > size) {
    return (description?.slice(0, size + 1) + "...")
  }
  return description
}

// Function to format a comma-separated string into a description
export const formatArrayDescription = (items: string): string => {
  if (!items || items.trim() === '') {
    return '';
  }

  // Split the comma-separated string into an array of strings
  const itemsArray = items.split(',')?.map(item => item.trim())?.filter(item => item !== '');
  switch (itemsArray.length) {
    case 0:
      return '';
    case 1:
      return itemsArray[0];
    case 2:
      return `${itemsArray[0]} , ${itemsArray[1]}`;
    case 3:
      return `${itemsArray[0]} , ${itemsArray[1]} , ${itemsArray[2]}`;
    default:
      const remainingCount = itemsArray.length - 2;
      return `${itemsArray[0]} , ${itemsArray[1]} , ${itemsArray[2]} + ${remainingCount} `;
  }
};

/*SQ_EX_22.3 - Helper function to format the shiftDetails */
export const formatShiftDetails = (input: any): any => {
  const formatTime = (isoString: string): string => {
    const timePart = isoString?.split('T')[1];
    return timePart?.split('.')[0].split('Z')[0];
  };
  return {
    shift_start_time: formatTime(input?.start_time),
    shift_end_time: formatTime(input?.end_time),
    break_ranges: input?.break_details?.map((breakDetail: any) => ({
      break_start_time: formatTime(breakDetail?.break_start_time),
      duration_minutes: breakDetail?.duration_minutes?.toString()
    }))
  };
};


/*SQ_EX_22.3 - Helper function to retreieve the colour code in UI function */
export const determineColorCode = (timeDifference: string, expectedTime: string, maxTime: string): string => {
  if (+timeDifference < +expectedTime) {
    return 'low';
  } else if (+timeDifference >= +expectedTime && +timeDifference < +maxTime) {
    return 'medium';
  } else {
    return 'high';
  }
}

/*SQ_EX_22.3 - Helper function to retreieve the time difference function */
export const calculateTimeDifference = (start: string, end: string) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const differenceInSeconds = (endTime - startTime) / 1000;
  return differenceInSeconds.toString();
}

// Helper function to format status name
export const formatStatusName = (statusName: string) => {
  if (statusName === UserStatus.DND) {
    return UserStatus.DND;
  }
  return statusName.charAt(0).toUpperCase() + statusName?.slice(1).toLowerCase();
};



export const userActivityService = {
  getAvailableTeams: "/vem/user-activity/teams",
  getUserActivity: "/vem/user-activity",
  getTeamCreatorsInfo: "/vem/user-activity/teams-creation",
  setOperatorStatus: "/vem/user-activity/update-status",
  getOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  overrideOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  getIndividualUserActivity: "/vem/user-activity/user",
  getUserActivityKPI: "/vem/user-activity-kpi",
  getIndividualUserActivityKPI: "/vem/user-activity/user/kpi",
  user: "/vem/user/",
  getTeamDetails: "/team-details"
}


export const userActivityNotificationTopics = {
  getIndividualUserActivityTopic: "INDIVIDUAL_USER_ACTIVITY",
  getUserActivityTopic: "USER_ACTIVITY"
}

export const operatorNotifications = {
  getLiveWorkItemsNotification: "?topics=WORK_ITEM_QUEUE"
}

export const notificationSubscribe = {
  getLiveSubscribeNotification: "/vem-notification/subscribe"
}

export const userStatus = {
  IDLE: "Idle",
  WORKING: "Working",
  DND: "Dnd",
  BREAK: "Break",
  OFFLINE: "Offline"
}



export const equipmentCorrection: EquipmentCorrectionEndpoints = {
  escalatetoLane: "/vem/work-item/escalate-to-lane",
  fetchLayoutJson: "/orca-config/get-screen-layout",
  requeueWorkItem: "/vem/work-item/requeue",
  websocket: "/vem-notification/subscribe",
  cameraFootage: "/camera-footage",
  getStatus: "/vem/user-activity/status",
  updateUserActivityStatus: "/vem/user-activity/update-status",
  completeWorkItem: "/vem/work-item/complete",
  visitMatchupCheck: "/vem/visit-matchup/check"
};

export const workItems = {
  getScheduleDetails: "/vem/user-activity/get-current-shift-break",
  updateActivityStatus: "/vem/user-activity/update-status",
  publishNotification: "/vem-notification/publish",
  getAvailableWorkItems: "/vem-work-item-tracker/assign-work-item"
}

export const notificationWebSocket = {
  connectWebSocket: "/vem-notification/subscribe"
}

export const insightService = {
  getCompletedWorkItems: "/vem/work-items/get-completed-work-items"
}

export const auditHistoryService = {
  getAuditHistoryDetails: "/vem/audit-history/get-audit-history"
}


export const sessionStorageKeyNames = {
  overallUserActivitySessionKey: "userActivityData",
  individualUserActivitySessionKey: "individualUserActivityData"
}

export const userManagementService = {
  getAllUsers: "/vem/user/all-users",
  getAllUserDetails: "/vem/user/get",
  deleteUser: (userId: string) => `/vem/user/${userId}`,
  verifyBusinessMail: `/vem/user/verify-email`,
  saveuser: `/vem/user/create`,
  getUserDetailsById: (user_uuid: string) => `/vem/user/${user_uuid}/get`,
  getAppRoles: (application_uuid: string) => `/vem/user/app-roles/${application_uuid}`
}

// Function to convert enum value or string to display name
export function getDurationNameFilters(input: CompletedDurationFilters | string): string {
  if (Object.values(CompletedDurationFilters).includes(input as CompletedDurationFilters)) {
    input = input as CompletedDurationFilters;
  } else if (typeof input === 'string') {
    return formatDisplayDurationName(input);
  } else {
    throw new Error('Invalid input type');
  }

  return formatDisplayDurationName(input);
}

// Helper function to format display names
function formatDisplayDurationName(name: string): string {
  return name
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function transformDateTime(dateTimeString: string) {
  // Check if the input is a valid date-time string
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(dateTimeString)) {
    throw new Error("Invalid date-time format. Expected YYYY-MM-DDTHH:mm");
  }

  // Split the date and time
  const [datePart, timePart] = dateTimeString.split('T');

  // Add seconds to the time part
  const timeWithSeconds = timePart + ':00';

  // Combine date and time with a space separator
  return `${datePart} ${timeWithSeconds}`;
}

// Set the minimum date to January 1, 2024
export const MinimumFilterDate = "2024-01-01";
export const MinimumFilterDateTime = "2024-01-01T00:00";

export const workItemSla = {
  getCustomerWorkItemAndApplications: "/vem/work-item-sla/cust-site-app-work-details",
  deleteWorkItemSla: "/vem/work-item-sla/delete",
  getWorkItemSla: "/vem/work-item-sla/get",
  createWorkItemSla: "/vem/work-item-sla/create",
  updateWorkItemSla: "/vem/work-item-sla/update",
  getWorkItemSlaById: "/vem/work-item-sla/getById"
}

export const UserAppRoles: Record<string, UserApplication> = {
  [applicationAbbrievation.VEM]: {
    name: applicationName.VEM,
    routes: {
      [UserRoles.admin]: '/operations',
      [UserRoles.supervisor]: '/operations',
      [UserRoles.Operator]: '/workItemNotifications'
    }
  }
  // Add more applications as needed
};

export const AllowedRoles = [UserRoles.admin, UserRoles.supervisor, UserRoles.Operator];
export function getRouteBasedOnRoles(applicationName: string, userRoles: UserRoles) {
  const application = UserAppRoles[applicationName];
  if (!application) {
    return '/forbidden'
  }
  // Find the highest priority role that the user has
  const userRole = AllowedRoles?.find(role => userRoles?.includes(role));
  if (!userRole) {
    return '/forbidden'
  }
  const route = application.routes[userRole];
  return route;
}

export const notifications = {
  getNotifications: "/vem/notifications/get",
  updateNotifications: "/vem/notifications/update",
  createNotifications: "/vem/notifications/create"
}

export const notificationAudioPath = "audio/notification.mp3"

export const ShiftTemplatesEndPoints = {
  getShiftTemplates: "/vem/shift-templates/get",
  deleteShiftTemplate: "/vem/shift-templates/delete",
  getShiftTemplateById: "/vem/shift-templates/get/",
  createShiftTemplate: "/vem/shift-templates/create",
  updateShiftTemplate: "/vem/shift-templates/update"
}

export const notificationEndpoints = {
  createNotification: "/vem/notifications/create"
}

export const fetchUserDetailsFromStorage = (): any => {

  const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
  const parsedUserDetails = storedUserDetails
    ? JSON.parse(storedUserDetails)
    : null;
  return parsedUserDetails;
}

export const WorkItemNotificationTopics = {
  adminSupervisorSubscription: "SUPERVISOR_ADMIN_WORKITEMS",
  operatorNotification: "NEW_WORKITEM"
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


export function sortData<T>(data: T[], sortConfig: SortConfig): T[] {
  if (!sortConfig.key) {
    return data;
  }

  return [...data].sort((a: T, b: T) => {
    const aValue = (a as any)[sortConfig.key];
    const bValue = (b as any)[sortConfig.key];

    // Check if both values are numbers
    const aIsNumber = typeof aValue === 'number';
    const bIsNumber = typeof bValue === 'number';

    if (aIsNumber && bIsNumber) {
      // Numerical comparison
      return sortConfig.direction === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    } else {
      // String comparison
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();

      if (aString < bString) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aString > bString) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    }
  });
}

export const containerRegex = /^.{4,}$/;
export const chassisRegex = /^.{3,}$/;