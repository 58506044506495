/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { ServerResponse, SignalData, WebRTCPlayerProps } from "../Interfaces/webrtc_player_interface";
import { RegisterWebRTCRequest, RTPStreamRequest, RTPStreamStatusRequest, SignalWebRTCRequest } from "../Interfaces/streaming_service_interface";
import { generateStreamingServiceBaseURL } from "../utils/utils";
import { checkRTPStatusService, registerWebRTCService, signalWebRTCService, startRTPStreamService } from "../services/streaming_service";
import { rtpBaseURL } from "../constant";
import { SimpleLoader } from "../components/loader";

const WebRTCPlayer: React.FC<WebRTCPlayerProps> = ({ camera }) => {
    const [connectionStatus, setConnectionStatus] = useState<'connecting' | 'connected' | 'failed'>('connecting');
    const videoRef = useRef<HTMLVideoElement>(null);
    const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
    const remoteCandidatesQueueRef = useRef<RTCIceCandidate[]>([]);
    const connectionIdRef = useRef<string>("");
    const [streamKey, setStreamKey] = useState<string>("");
    const [port, setPort] = useState<string>("");

    const cleanupPeerConnection = useCallback(() => {
        if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }
        remoteCandidatesQueueRef.current = [];
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    }, []);

    const sendSignal = useCallback(async (data: SignalData): Promise<ServerResponse | null> => {
        if (!camera) return null;
        try {
            const payload: SignalWebRTCRequest = {
                ...data,
                connection_id: connectionIdRef.current,
                base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.pion_server_port)
            };
            const apiResponse = await signalWebRTCService(payload);
            if (apiResponse?.status !== 200) {
                throw new Error(apiResponse?.response?.data?.message);
            }
            return apiResponse?.data?.data;
        } catch (error) {
            console.error('Error sending signal:', error);
            throw error;
        }
    }, [camera]);

    const handleRemoteCandidate = useCallback(async (candidate: RTCIceCandidateInit) => {
        const pc = peerConnectionRef.current;
        if (pc && pc.remoteDescription) {
            try {
                await pc.addIceCandidate(new RTCIceCandidate(candidate));
            } catch (error) {
                console.error('Error handling remote candidate:', error);
            }
        } else {
            remoteCandidatesQueueRef.current.push(new RTCIceCandidate(candidate));
        }
    }, []);

    const addQueuedCandidates = useCallback(async () => {
        const pc = peerConnectionRef.current;
        if (pc) {
            try {
                for (const candidate of remoteCandidatesQueueRef.current) {
                    await pc.addIceCandidate(candidate);
                }
                remoteCandidatesQueueRef.current = [];
            } catch (error) {
                console.error('Error adding queued candidates:', error);
            }
        }
    }, []);

    const sendLocalDescription = useCallback(async (localDescription: RTCSessionDescription | null) => {
        if (!localDescription) {
            console.error('Local description is null');
            return;
        }

        try {
            const serverResponse = await sendSignal({
                sdp: localDescription,
                stream_key: streamKey,
            });

            if (serverResponse?.sdp) {
                const pc = peerConnectionRef.current;
                if (pc) {
                    await pc.setRemoteDescription(new RTCSessionDescription(serverResponse.sdp));
                    await addQueuedCandidates();
                }
            }

            if (serverResponse?.candidates) {
                for (const candidate of serverResponse.candidates) {
                    await handleRemoteCandidate(candidate);
                }
            }

            setConnectionStatus('connected');
        } catch (error) {
            console.error('Error in sendLocalDescription:', error);
            setConnectionStatus('failed');
        }
    }, [streamKey, handleRemoteCandidate, addQueuedCandidates, sendSignal]);

    const setupWebRTC = useCallback(async () => {
        try {
            setConnectionStatus('connecting');
            cleanupPeerConnection();

            const pc = new RTCPeerConnection({
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                ],
                iceTransportPolicy: "all",
            });
            peerConnectionRef.current = pc;
            if (videoRef.current) {
                videoRef.current.preload = "none"
                videoRef.current.autoplay = true;
            }

            pc.ontrack = (event) => {
                if (event.streams.length > 0) {
                    if (videoRef.current) {
                        videoRef.current.srcObject = event.streams[0];
                        videoRef.current.onplay = () => {
                            console.log("Stream is active.");
                        };
                        videoRef.current.onpause = () => {
                            console.log("Stream paused or inactive.");
                        };
                    }
                } else {
                    console.error("No streams available in track event.");
                }
            };

            pc.onicecandidate = (event) => {
                if (event.candidate) {
                    sendSignal({
                        candidate: event.candidate,
                        stream_key: streamKey,
                    }).catch(console.error);
                }
            };

            pc.oniceconnectionstatechange = () => {
                console.log('ICE connection state:', pc.iceConnectionState);
                if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'disconnected') {
                    console.error('ICE connection failed or disconnected');
                    setConnectionStatus('failed');
                }
            };

            pc.onicegatheringstatechange = () => {
                console.log('ICE gathering state:', pc.iceGatheringState);
            };

            pc.onsignalingstatechange = () => {
                console.log('Signaling state:', pc.signalingState);
            };

            // Create a dummy video stream using a canvas
            const canvas = document.createElement('canvas');
            canvas.width = 640;
            canvas.height = 480;
            const ctx = canvas.getContext('2d')!;
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const dummyStream = canvas.captureStream(1);
            // Add dummy tracks to ensure proper SDP generation
            dummyStream
                .getTracks()
                .forEach((track) => pc.addTrack(track, dummyStream));

            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
            await sendLocalDescription(pc.localDescription);

        } catch (err) {
            console.error('Error in setupWebRTC:', err);
            setConnectionStatus('failed');
        }
    }, [streamKey, sendLocalDescription, cleanupPeerConnection]);

    const checkRTPStream = useCallback(async () => {
        if (!camera || !streamKey) return;
        try {
            const statusPayload: RTPStreamStatusRequest = {
                stream_key: streamKey,
                base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.stream_server_port)
            };
            const statusResponse = await checkRTPStatusService(statusPayload);
            if (statusResponse?.status !== 200) {
                throw new Error(statusResponse?.response?.data?.message);
            }
            const isActive: boolean = statusResponse?.data?.data?.active;
            if (isActive) return;
            await initiateRTPStream()
        } catch (error: any) {
            console.error("Error stopping RTP stream:", error.message);
        }
    }, [streamKey, camera, port]);

    const initiateWebRTCStream = useCallback(async () => {
        if (!camera) return;
        try {
            const registerWebRTCRequest: RegisterWebRTCRequest = {
                stream_key: streamKey,
                port: port,
                base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.pion_server_port)
            };
            const apiResponse = await registerWebRTCService(registerWebRTCRequest);
            if (apiResponse?.status !== 200) {
                throw new Error(apiResponse?.response?.data?.message);
            }
        } catch (error: any) {
            console.error("Error initiating WebRTC stream:", error.message);
        }
    }, [camera, streamKey, port]);

    const initiateRTPStream = useCallback(async () => {
        if (!camera) return;
        try {
            const payload: RTPStreamRequest = {
                source_url: `rtsp://${camera?.ip_address}/axis-media/media.amp`,
                stream_key: streamKey,
                target_url: `${rtpBaseURL}:${port}`,
                base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.stream_server_port),
            };
            const apiResponse = await startRTPStreamService(payload);
            if (apiResponse?.status !== 200) {
                throw new Error(apiResponse?.response?.data?.message);
            }
        } catch (error: any) {
            console.error("Error initiating RTP stream:", error.message);
        }
    }, [camera, streamKey, port]);

    const configureStream = useCallback(async () => {
        try {
            await checkRTPStream();
            await initiateWebRTCStream();
            await setupWebRTC();
        } catch (error: any) {
            console.error("Error configuring stream:", error.message);
            setConnectionStatus('failed');
        }
    }, [initiateRTPStream, checkRTPStream, initiateWebRTCStream, setupWebRTC]);

    useEffect(() => {
        if (camera && streamKey && port) {
            connectionIdRef.current = crypto.randomUUID()
            setStreamKey(camera?.device_uuid)
            configureStream();
        }
        return () => cleanupPeerConnection();
    }, [camera, configureStream, cleanupPeerConnection]);

    useEffect(() => {
        if (camera) {
            setStreamKey(camera?.device_uuid);
            setPort(camera?.port.toString())
        }
    }, [camera]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'black',
        }}>
            {connectionStatus !== 'connected' && <SimpleLoader />}
            <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};

export default WebRTCPlayer;