/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";

import { jwtDecode } from "jwt-decode";
import { generateAuthToken } from "../../services/login_service";
import { encryptKeys, encryptStorage, getRouteBasedOnRoles } from "../../constant";

import { decodedToken, acessTokenPayload, accessTokenResponse, userDetails } from "../../Interfaces/login_interface";
import { FullscreenLoader } from "../loader";
import { useNavigate } from "react-router-dom";
import { getStatusService, updateUserActivityStatus } from "../../services/operator_service";
import { UserStatus } from "../../enums/enums";
import { UpdateUserStatusInterface } from "../../Interfaces/operations_interface";
import { useContext } from "react";
import { overallContext } from "../../context/Context";



// PS_12 - PS_36 Handling authentication, Token & role acquisition, profile image retrieval
const LoginAuth = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState<string>()
  const { setCurrentApplication } = useContext(overallContext);



  useEffect(() => {
    if (isAuthenticated) {
      acquireToken();
    }
  }, [isAuthenticated]);


  const getStatus = async () => {
    try {
      const response: any = await getStatusService();
      if (response?.status === 200) {
        return response?.data?.data;
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const updateUserStatus = async (request: UpdateUserStatusInterface) => {
    let userDetails = encryptStorage.getItem(encryptKeys?.userDetails);
    userDetails = userDetails ?JSON.parse(userDetails) : null;
    if(userDetails) {
      const userUUID = userDetails?.userId;
      const payload: UpdateUserStatusInterface = {
        user_uuid: userUUID,
        user_status_uuid: request.user_status_uuid,
        reason: request.reason,
        category: request.category
      };
  
      try {
        const response: any = await updateUserActivityStatus(payload);
        if (response?.status === 200) {
         setUserStatus(request.status_name) 
        }
      } catch (error: any) {
        console.error("Error Updating Activity Status", error);
      }
    }    
  };

  const authToken = async (userId: string) => {
    try {
      setLoader(true);
      const payload: acessTokenPayload = { user_id: userId };
      const token = await generateAuthToken(payload);

      if (token && token.status === 200 && token.data) {
        const accessTokenResponse: accessTokenResponse = token.data;
        const decodedToken: decodedToken = jwtDecode(accessTokenResponse.access_token);
        const userData: userDetails = {
          accessToken: accessTokenResponse.access_token,
          userName: decodedToken.userName,
          emailId: decodedToken.emailId,
          userId: decodedToken.userUUID,
          refreshToken: accessTokenResponse.refresh_token,
          appRole: decodedToken.appRole || [],
        };

        // Save the complete session data object
        encryptStorage.setItem(encryptKeys.userDetails, JSON.stringify(userData));
      } else {
        console.error("Invalid token response:", token);
        navigate(`/`, {
          state: {
              message: "Your account may be deleted or inactive. Please contact the admin"
          } 
      }); 
      }
    } catch (error: any) {
      console.error("Error during token generation:", error.response?.data || error.message);
    } finally {
      setLoader(false);
    }
  };

  /**PS_18 -PS 22 Checks for the users roles and navigates based on the users application role*/

  const acquireToken = async () => {
    try {
      setLoader(true);
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const silentRequest = { account: accounts[0], scopes: ["user.read"] };
        const response = await instance.acquireTokenSilent(silentRequest);
        let token = response?.accessToken;

        if (token) {
       
          const accessToken: any = jwtDecode(token);
          await authToken(accessToken.oid);
          let statusData = await getStatus();

          // Fetch profile image with error handling
          try {
            const profileImageResponse = await axios.get(
              `https://graph.microsoft.com/v1.0/me/photo/$value`,
              { headers: { Authorization: `Bearer ${token}` }, responseType: "blob" }
            );

            if (profileImageResponse && profileImageResponse.data) {
              const reader = new FileReader();
              reader.readAsDataURL(profileImageResponse.data);
              reader.onloadend = () => {
                const dataUrl: any = reader.result;
                sessionStorage.setItem(encryptKeys?.profile, dataUrl);
              };
            }
          } catch (error) {
            console.error('Error fetching profile image:', error);
          }
          const foundStatus = statusData?.find((item: any) => item.status_name === UserStatus.ACTIVE);
          let statusUUID = foundStatus?.user_status_uuid;
          const payload: UpdateUserStatusInterface = {
            user_status_uuid: statusUUID,
            status_name : UserStatus.ACTIVE,
            reason: "User Logged In",
            category: UserStatus.ACTIVE
          }
          await updateUserStatus(payload);
          // Get user details and check for multiple applications
          const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
          const userDetails = userDetailsString ? JSON?.parse(userDetailsString) : null;
          if(!userDetails){
            navigate(`/`, {
              state: {
                  message: "Your account may be deleted or inactive. Please contact the admin"
              }
          });
          return
          }
          const userApps = userDetails?.appRole || [];
          if (userApps?.length > 1 || userApps?.length === 0) {
            // User has access to multiple applications
            navigate('/applications');
          } else if (userApps.length === 1) {
            // User has access to only one applicationx`
            const appAbbreviation = userApps[0].abbreviation;
            setCurrentApplication(appAbbreviation);
            encryptStorage.setItem(encryptKeys.currentApplication, appAbbreviation);
            const userRoles = userDetails.appRole?.map((role: any) => role.role_name);
            const route = getRouteBasedOnRoles(appAbbreviation, userRoles);
            navigate(route);
          }
        }
      }
    }
    catch (error) {
      console.error("Error acquiring token:", error);
    }
    finally {
      setLoader(false);
    }
  }


  return (
    <>
      {loader ? (
        <FullscreenLoader />
      ) : <></>}
    </>
  );
};

export default LoginAuth;