/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { assignWorkItemService, getScheduleDetailsService, getStatusService, getUserStatusService, updateUserActivityStatus } from "../../services/operator_service";
import { encryptKeys, encryptStorage, WorkItemNotificationTopics } from "../../constant";
import ToastMessage from "../../reusable_components/toast";
import { BreakTiming, ShiftData, UpdateUserStatusInterface } from "../../Interfaces/operations_interface";
import { applicationAbbrievation, NotificationCategories, NotificationTypes, TeamPrivilege, UserRoles, UserStatus, WebsocketCodes } from "../../enums/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { overallContext } from "../../context/Context";
import WebsocketClient from "../../client/websocket";
import { CreateNotificationRequest } from "../../Interfaces/notification_interface";
import { createNotificationService } from "../../services/notification_service";
import { getAvailableWorkItemsService, getOperatorTeamDetailsService } from "../../services/work_item_service";

export function OperatorNotifications() {
    const { userStatus: globalUserStatus } = useContext(overallContext);
    const navigate = useNavigate()
    let location = useLocation()
    const [userStatus, setUserStatus] = useState<any>(globalUserStatus);
    const [workItems, setWorkItems] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>([]);
    const socket = useRef<WebSocket>()
    const acceptButton = useRef<any>(null)
    let userDetails = encryptStorage.getItem(encryptKeys?.userDetails);
    userDetails = userDetails ? JSON.parse(userDetails) : null;
    const vemAppId = userDetails ? userDetails?.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-" : null;
    const userUUID = userDetails?.userId;
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("");
    const storedRole = userDetails?.appRole?.filter((app: any) => (app.abbreviation === "VEM"));
    let userRole = storedRole ? storedRole[0]?.role_name : "Unauthorized";
    const [teamDetails, setTeamDetails] = useState<any>();
    const [topicName, setTopicName] = useState<string>("");
    const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);


    const [shiftData, setShiftData] = useState<ShiftData>({
        shift_start_time: "",
        shift_end_time: "",
        break_timings: [
            {
                break_start_time: "",
                duration_minutes: ""
            }
        ]
    });

    useEffect(() => {
        switch (globalUserStatus) {
            case UserStatus.BREAK.toLowerCase():
                setUserStatus(UserStatus.BREAK)
                break
            case UserStatus.DND.toLowerCase():
                setUserStatus(UserStatus.DND)
                break
            case UserStatus.IDLE.toLowerCase():
                setUserStatus(UserStatus.IDLE)
                break
        }
    }, [globalUserStatus])

    useEffect(() => {
        if (socket.current) {
            socket.current.onmessage = handleWebSocketMessage
        }
    }, [userStatus])

    useEffect(() => {
        if (userRole === UserRoles.Operator) {
            getOperatorTeamDetails();
        } else setTopicName(WorkItemNotificationTopics.adminSupervisorSubscription)
        getScheduleDetails();
        if (location?.state?.action) {
            const { action } = location?.state
            let toastMessage = ""
            if (action === WebsocketCodes.TAKEOVER) {
                toastMessage = "Your current work item has been removed"
            } else if (action === WebsocketCodes.ESCALATEDTOCUSTOMER) {
                toastMessage = "Your current work item is deferred to customer"
            }
            navigate(location?.pathname, { replace: true, state: null });
            if (toastMessage !== "") {
                setToastType("Message");
                setToastMessage(toastMessage);
                setShowToast(true);
            }
        }

        document.addEventListener("visibilitychange", handleVisibilityChange);

        const handleKeyPress = (event: KeyboardEvent) => {
            const { key } = event;
            switch (key) {
                case "Enter":
                    acceptButton?.current?.click()
                    break
                case " ":
                    acceptButton?.current?.click()
                    break
            }
        }
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            if (socket.current) socket.current.close();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, []);

    useEffect(() => {
        if (topicName !== "") {
            var newSocket = WebsocketClient(userUUID + WorkItemNotificationTopics.operatorNotification, [topicName])
            newSocket.onopen = () => {
                setIsSocketConnected(true);
                console.log("Connection Opened");
            }
            newSocket.onmessage = handleWebSocketMessage;
        }
        return () => {
            if (newSocket) newSocket.close();
        }
    }, [topicName])

    useEffect(() => {
        if (isSocketConnected) {
            getStatus();
            getUserStatus();
        }
    }, [isSocketConnected])

    const getOperatorTeamDetails = async () => {
        try {
            const response: any = await getOperatorTeamDetailsService(userUUID);
            setTeamDetails(response?.data?.data);
            setTopicName(response?.data?.data?.team_uuid);
        }
        catch (err: any) {
            console.error(err);
        }
    }

    const getStatus = async () => {
        try {
            if (userDetails) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    setStatus(response?.data?.data);
                }
                else {
                    setToastMessage(response?.message);
                    setToastType("Error")
                    setShowToast(true);
                }
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const getUserStatus = async () => {
        try {
            if (userDetails) {
                const response: any = await getUserStatusService(userUUID);
                if (response?.status === 200) {
                    setUserStatus(response?.data?.data?.status_name);
                    if (response?.data?.data?.status_name !== UserStatus.DND && response?.data?.data?.status_name !== UserStatus.BREAK) {
                        setIdleStatus();
                    } else if (response?.data?.data?.status_name === UserStatus.IDLE) {
                        await getAvailableWorkItems();
                    }
                }
                else {
                    setToastMessage(response?.message);
                    setToastType("Error")
                    setShowToast(true);
                }
            }
        } catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };
    const getAvailableWorkItems = async () => {
        try {
            const response = await getAvailableWorkItemsService();
            console.log(response);
        }
        catch (err) {
            console.error(err)
        }
    }

    const updateUserStatus = async (request: UpdateUserStatusInterface) => {
        const payload = {
            user_uuid: userUUID,
            user_status_uuid: request.user_status_uuid,
            reason: request.reason,
            category: request.category
        };

        try {
            const response: any = await updateUserActivityStatus(payload);
            if (response?.status === 200) {
                setUserStatus(request.status_name)
                if (request?.status_name === UserStatus.IDLE) {
                    await getAvailableWorkItems();
                }
            }
            else {
                setToastMessage(response?.message);
                setToastType("Error")
                setShowToast(true);
            }
        } catch (error: any) {
            console.error("Error Updating Activity Status", error);
            setToastMessage(error?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const setBreakStatus = async (breakTime: BreakTiming) => {
        try {
            if (userDetails) {
                let userDetails = JSON.parse(encryptStorage.getItem(encryptKeys.userDetails) || "{}");
                if (userDetails.accessToken !== undefined) {
                    const response: any = await getStatusService();
                    if (response?.status === 200) {
                        const statusData = response?.data?.data;
                        const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.BREAK);
                        let statusUUID = foundStatus?.user_status_uuid;
                        if (statusUUID) {
                            const payload: UpdateUserStatusInterface = {
                                user_status_uuid: statusUUID,
                                reason: `Scheduled break for ${breakTime.duration_minutes} minutes`,
                                category: UserStatus.BREAK
                            }
                            updateUserStatus(payload);
                        } else {
                            console.error("BREAK status not found");
                        }
                    }

                    else {
                        setToastMessage(response?.message);
                        setToastType("Error")
                        setShowToast(true);
                    }
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const setIdleStatus = async () => {
        try {
            if (userDetails) {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    const statusData = response?.data?.data;
                    const foundStatus = statusData.find((item: any) => item.status_name === UserStatus.IDLE);
                    let statusUUID = foundStatus?.user_status_uuid;
                    if (statusUUID) {
                        const payload: UpdateUserStatusInterface = {
                            user_status_uuid: statusUUID,
                            status_name: UserStatus.IDLE,
                            reason: "Logged into Operations Page",
                            category: UserStatus.IDLE
                        }
                        updateUserStatus(payload);
                    } else {
                        console.error("IDLE status not found");
                    }
                }
                else {
                    setToastMessage(response?.message);
                    setToastType("Error")
                    setShowToast(true);
                }
            }
        } catch (err: any) {
            console.error("Error fetching status data:", err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    };

    const handleVisibilityChange = async () => {
        let userDetails = JSON.parse(encryptStorage.getItem(encryptKeys.userDetails) || "{}");
        if (!document.hidden && userDetails && userDetails.accessToken !== undefined) {
            try {
                const response: any = await getStatusService();
                if (response?.status === 200) {
                    const statusData = response?.data?.data;
                    setStatus(statusData);

                    const now = new Date();
                    const shiftStart = new Date(now.toDateString() + ' ' + shiftData.shift_start_time);

                    for (const break_time of shiftData.break_timings) {
                        const [hours, minutes] = break_time.break_start_time.split(':').map(Number);
                        const breakStart = new Date(shiftStart.getTime() + (hours * 3600000 + minutes * 60000));
                        const duration = parseInt(break_time.duration_minutes);
                        const breakEnd = new Date(breakStart.getTime() + duration * 60000);

                        if (now >= breakStart && now < breakEnd) {
                            // We're currently in a break
                            setBreakStatus(break_time);
                            break;
                        }
                    }
                }
                else {
                    setToastMessage(response?.message);
                    setToastType("Error")
                    setShowToast(true);
                }
            } catch (err: any) {
                console.error("Error fetching status data:", err);
                setToastMessage(err?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
    };

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        try {
            const message = JSON.parse(event.data);

            if (message.data.type === WebsocketCodes.NEWWORKITEM) {
                if (userStatus !== UserStatus.DND && userStatus !== UserStatus.BREAK) {
                    workItems.length > 0 ? setWorkItems(prev => [...prev, ...message.data.data]) : setWorkItems(message.data.data);
                }
            } else if (message.data.type === WebsocketCodes.REVOKEWORKITEM) {
                setWorkItems(prev =>
                    prev.filter(item => item.work_item_uuid !== message.data.data.work_item_uuid)
                );
            }
        } catch (error) {
            console.error("Parsing error:", error);
        }
    }, []);

    useEffect(() => {
        if (teamDetails?.workitem_handling_method === TeamPrivilege.Push && workItems?.length > 0) {
            handleAccept();
        }
    }, [workItems])

    const getStatusUuidByName = useCallback((statusName: string): string | undefined => {
        const foundStatus = status.find(item => item.status_name === statusName);
        return foundStatus?.user_status_uuid;
    }, [status]);

    const isBreakTime = useCallback((): boolean => {
        const now = new Date();
        const shiftStart = new Date(now.toDateString() + ' ' + shiftData.shift_start_time);

        for (const break_time of shiftData.break_timings) {
            const [hours, minutes] = break_time.break_start_time.split(':').map(Number);
            const breakStart = new Date(shiftStart.getTime() + (hours * 3600000 + minutes * 60000));
            const duration = parseInt(break_time.duration_minutes);
            const breakEnd = new Date(breakStart.getTime() + duration * 60000);

            if (now >= breakStart && now < breakEnd) {
                return true;
            }
        }
        return false;
    }, [shiftData]);

    const isBreakTimeWithCurrentBreak = useCallback((): string => {
        const now = new Date();
        const shiftStart = new Date(now.toDateString() + ' ' + shiftData.shift_start_time);

        for (const break_time of shiftData.break_timings) {
            const [hours, minutes] = break_time.break_start_time.split(':').map(Number);
            const breakStart = new Date(shiftStart.getTime() + (hours * 3600000 + minutes * 60000));
            const duration = parseInt(break_time.duration_minutes);
            const breakEnd = new Date(breakStart.getTime() + duration * 60000);

            if (now >= breakStart && now < breakEnd) {
                return break_time.break_start_time;
            }
        }
        return "";
    }, [shiftData]);

    const formatTime = (timeValue: string) => {
        // Extract the time part from the ISO string
        if (timeValue.includes('T')) {
            const timePart = timeValue.split('T')[1].split('.')[0];
            const [hours, minutes] = timePart.split(':');
            // Pad with leading zeros if necessary
            const formattedHours = hours.padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}`;
        }
        else return timeValue
    }

    const getScheduleDetails = async () => {
        let userDetails = JSON.parse(encryptStorage.getItem(encryptKeys.userDetails) || "{}");
        if (userDetails.accessToken !== undefined) {
            try {
                const response: any = await getScheduleDetailsService(userUUID);
                if (response?.status === 200) {
                    const responseData = response.data.data

                    if (responseData.usbo_uuid) {
                        setShiftData((prev: any) => ({
                            ...prev,
                            shift_start_time: formatTime(responseData.override_shift_start),
                            shift_end_time: formatTime(responseData.override_shift_end)
                        }))
                        const formattedBreaks: any[] = responseData.break_override_details.map((breakDetail: any) => ({
                            break_start_time: breakDetail.break_override_uuid_uuid ? formatTime(breakDetail.original_break_start) : formatTime(breakDetail.original_break_start),
                            duration_minutes: breakDetail.break_override_uuid_uuid ? breakDetail.override_duration_minutes : breakDetail.original_duration_minutes
                        }));
                        setShiftData((prev: any) => ({
                            ...prev,
                            break_timings: formattedBreaks
                        }))
                    }
                    else {
                        setShiftData((prev: any) => ({
                            ...prev,
                            shift_start_time: formatTime(responseData.original_shift_start),
                            shift_end_time: formatTime(responseData.original_shift_end)
                        }))
                        const formattedBreaks: any[] = responseData.break_details.map((breakDetail: any) => ({
                            break_start_time: formatTime(breakDetail.original_break_start),
                            duration_minutes: breakDetail.original_duration_minutes
                        }));
                        setShiftData((prev: any) => ({
                            ...prev,
                            break_timings: formattedBreaks
                        }))
                    }
                }
                else {
                    setToastMessage(response?.message);
                    setToastType("Error")
                    setShowToast(true);
                }
            } catch (error: any) {
                console.error("Error retrieving schedule details", error);
                setToastMessage(error?.message);
                setToastType("Error")
                setShowToast(true);
            }
        }
    };

    const handleResumeQueuing = useCallback(async () => {
        const statusUUID = getStatusUuidByName(UserStatus.IDLE);
        if (statusUUID) {
            const payload: UpdateUserStatusInterface = {
                user_status_uuid: statusUUID,
                status_name: UserStatus.IDLE,
                reason: "Resumed Queuing",
                category: UserStatus.IDLE
            }
            await updateUserStatus(payload);
            if (userRole === UserRoles.Operator) {
                const breakPeriod: string = isBreakTimeWithCurrentBreak()
                if (breakPeriod !== "") {
                    const payload: CreateNotificationRequest = {
                        type: NotificationTypes.BROADCAST,
                        destination: [UserRoles.supervisor],
                        application_uuid: vemAppId,
                        notification_details: {
                            notification_category: NotificationCategories.BREAK,
                            notification_title: "Break",
                            notification_message: `${userDetails.userName} working during the ${breakPeriod} break period.`,
                        }
                    }
                    const response = await createNotificationService(payload)
                    if (response.status !== 201) {
                        throw new Error(response?.error);
                    }
                }
            }
        } else {
            console.error("IDLE status not found");
        }
    }, [getStatusUuidByName]);

    const handlePauseQueuing = useCallback(async () => {
        if (isBreakTime()) {
            const statusUUID = getStatusUuidByName(UserStatus.BREAK);
            if (statusUUID) {
                const payload: UpdateUserStatusInterface = {
                    user_status_uuid: statusUUID,
                    status_name: UserStatus.BREAK,
                    reason: "On scheduled break",
                    category: UserStatus.BREAK
                }
                await updateUserStatus(payload);
            } else {
                console.error("BREAK status not found");
            }
        } else {
            const statusUUID = getStatusUuidByName(UserStatus.DND);
            if (statusUUID) {
                const payload: UpdateUserStatusInterface = {
                    user_status_uuid: statusUUID,
                    status_name: UserStatus.DND,
                    reason: "Manually paused",
                    category: UserStatus.DND
                }
                await updateUserStatus(payload);
                if (userRole === UserRoles.Operator) {
                    const notificationPayload: CreateNotificationRequest = {
                        type: NotificationTypes.BROADCAST,
                        destination: [UserRoles.supervisor],
                        application_uuid: vemAppId,
                        notification_details: {
                            notification_category: NotificationCategories.BREAK,
                            notification_title: "Break",
                            notification_message: `${userDetails.userName} has taken an unplanned break.`,
                        }
                    }
                    const response = await createNotificationService(notificationPayload)
                    if (response.status !== 201) {
                        throw new Error(response?.error);
                    }
                }
            } else {
                console.error("DND status not found");
            }
        }
    }, [isBreakTime, getStatusUuidByName]);

    const handleAccept = async () => {
        try {
            const payload = {
                work_item_uuid: workItems[0].work_item_uuid,
            }
            const response: any = await assignWorkItemService(payload);
            if (response?.status === 200) {
                let workItem = workItems[0]
                setWorkItems(prevItems => prevItems.slice(1));
                workItem.startTime = new Date();
                navigate("/workItem", {
                    state: workItem
                })
            }
        }
        catch (err: any) {
            console.error(err);
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    }

    const renderContent = useCallback(() => {
        switch (userStatus) {
            case UserStatus.IDLE:
                return (workItems.length > 0 && (teamDetails?.workitem_handling_method !== TeamPrivilege.Push || userRole === UserRoles.supervisor || userRole === UserRoles.admin)) ? (
                    <div className="content-container d-flex align-items-start justify-content-center">
                        <div className="e-content-wrapper alter d-flex align-items-center justify-content-center flex-column py-5 mt-5">
                            <span className="icon-wrapper d-flex align-items-center justify-content-center mb-3">
                                <img src="img/tick-icon-blue.svg" alt="tick" />
                            </span>
                            <h4 className="font-semibold font-18 color-black mb-4">
                                New Work Item Available
                            </h4>
                            <button
                                className="accept-btn py-2 border-0 font-semibold font-12 color-white rounded-3 w-75"
                                type="button" onClick={handleAccept}
                                ref={acceptButton}
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="content-container d-flex align-items-center justify-content-center">
                        <div className="e-content-wrapper d-flex align-items-center justify-content-center flex-column gap-2">
                            <img src="img/items-icon.svg" alt="items" />
                            <h4 className="font-semibold font-18 color-black m-0">No Work Items found </h4>
                            <p className="font-semibold font-13 color-grey-v1 m-0">You will receive a notification if a new work item is queued.</p>
                        </div>
                    </div>
                );
            case UserStatus.BREAK:
            case UserStatus.DND:
                return (
                    <div className="content-container d-flex align-items-center justify-content-center">
                        <div className="e-content-wrapper d-flex align-items-center justify-content-center flex-column gap-2">
                            <img src="img/snooze-icon-lg.svg" alt="items" />
                            <h4 className="font-semibold font-18 color-black m-0">
                                {userStatus === UserStatus.BREAK ? "You are on a break" : "You are on DND"}
                            </h4>
                            <p className="font-semibold font-13 color-grey-v1 m-0">
                                Resume queuing to receive notifications for new work items.
                            </p>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }, [userStatus, workItems, handleAccept]);

    return (
        <> <div className="container-fluid">
            <div className="p-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h2 className="font-bold font-18 color-black m-0">Operator Console</h2>
                    {userStatus === UserStatus.IDLE ? (
                        <button
                            className="d-flex align-items-center gap-1 border-0 snooze-btn"
                            type="button"
                            onClick={handlePauseQueuing}
                        >
                            <img src="img/snooze-icon.svg" alt="snooze" />
                            <span className="font-semibold font-12">Pause Queuing</span>
                        </button>
                    ) : (userStatus === UserStatus.DND || userStatus === UserStatus.BREAK) ? (
                        <button
                            className="d-flex align-items-center gap-1 border-0 snooze-btn active"
                            type="button"
                            onClick={handleResumeQueuing}
                        >
                            <img src="img/snooze-icon.svg" alt="snooze" />
                            <span className="font-semibold font-12">Resume Queuing</span>
                        </button>
                    ) : <></>}
                </div>
                {renderContent()}
            </div>
        </div>

            {showToast && (
                <ToastMessage props={{
                    isToast: showToast,
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType,
                }} />
            )}</>
    );
}