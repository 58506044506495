import { useCallback, useEffect, useState } from "react";
import { DeleteTeamRequest, GetAllTeamsRequest, Meta, Team } from "../../Interfaces/team_interface";
import { deleteTeamService, getAllTeamsService } from "../../services/team_service";
import { SortConfig } from "../../Interfaces/user_activity_interface";
import { FullscreenLoader } from "../loader";
import PaginationComponent from "../../reusable_components/pagination";
import ToastMessage from "../../reusable_components/toast";
import { useLocation, useNavigate } from "react-router-dom";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import DeletionConfirmationPopup from "../../reusable_components/delete_popup";
import { encryptKeys, encryptStorage, formatDescription } from "../../constant";
import { applicationAbbrievation, TeamPrivilege } from "../../enums/enums";


//teams grid component
const TeamGrid = () => {
    // Declare state variable for GetAllTeamsRequest
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetail =  userDetailsString ? JSON.parse(userDetailsString) : null;
    const vemAppId = userDetail ? userDetail?.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-" : null;

    const [requestPayload, setRequestPayload] = useState<GetAllTeamsRequest>({
        application_uuid: vemAppId,
        search: '',
        sort_order: 'desc', // Default sort order
        sort_column: 'team_name', // Default sort column
        records_per_page: 10, // Default limit
        page_number: 1,
        team_status: '' // Set your required status value
    });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false)
    const [teamsData, setTeamsData] = useState<Team[]>([]); // Array of Team objects
    const [selectedTeam, setSelectedTeam] = useState<string>('');

    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<string>(''); // Default to 'active'
    const [showFilter, setShowFilter] = useState(false);
    const IntialMeta :Meta ={
        page: 1,        // Initial page number
        page_size: 10,  // Initial page size (number of items per page)
        total: 0        // Total number of items (initially set to 0)
    }
    const [meta, setMeta] = useState<Meta>(IntialMeta);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 't.modified_on', direction: 'desc' });
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
    const [isApplyButtonClicked, setIsApplyButtonClicked] = useState(false);


    const navigate = useNavigate();
    const location = useLocation();

    // Function to fetch all teams
    const fetchAllTeams = useCallback(async (isCancel: boolean = false) => {
        try {
            const payload: GetAllTeamsRequest = {
                application_uuid: requestPayload.application_uuid,
                sort_order: sortConfig.direction,
                sort_column: sortConfig.key,
                team_status: isCancel ? '' : requestPayload.team_status,
                search: requestPayload.search?.trim(),
                page_number: isCancel ? 1 : meta.page,
                records_per_page: isCancel ? 10 : meta.page_size
            }
            setIsLoading(true)
            const response: any = await getAllTeamsService(payload);
            if (response?.code === 200) {
                setIsLoading(false);
                setTeamsData(response?.data);
                setMeta(response?.meta)
            } else {
                console.error(response.message);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setMeta(prevState => ({
                    ...prevState,
                    total: 0,
                    page: 1,
                    page_size: 10
                }))
                setTeamsData([]);
                return
            }

            console.error("Error fetching teams:", error);
            console.error(error.message);
        }
        finally {
            setIsLoading(false);
        }
    }, [meta.page, meta.page_size, requestPayload.application_uuid, requestPayload.search, requestPayload.team_status, sortConfig.direction, sortConfig.key]);
    useEffect(() => {
        if (location?.state?.mode === "Add") {
            setShowToast(true);
            setToastType("Success");
            setToastMessage("Team added successfully");
        } else if (location?.state?.mode === "Edit") {
            setShowToast(true);
            setToastType("Success");
            setToastMessage("Team updated successfully");
        }
        navigate(location?.pathname, { replace: true, state: null });
        fetchAllTeams();
    }, [fetchAllTeams, location?.pathname, location?.state?.mode, navigate]);


    const handleAddNewTeamClick = () => {
        navigate(`/createTeam`, {
            state: {
                mode: 'Add'
            }
        });
    };

    const handleEditTeamClick = (editId: string) => {
        navigate(`/createTeam`, {
            state: {
                mode: 'Edit',
                team_uuid: editId
            }
        });
    };

    const handleDeleteTeamClick = (editId: string) => {
        setSelectedTeam(editId)
        setShowConfirmDeletion(true)
    };

    const handleSort = (key: string) => {       
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setMeta(IntialMeta)
    };

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };


    const renderSortIcon = (key: string) => {
        const iconSrc = sortConfig.key === key
            ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
            : "img/sort-down-arrow.svg";
        return <img src={iconSrc} alt="sort-arrow" />;
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value); // Update selected status
    };

    const handleApplyFilter = () => {
        setIsApplyButtonClicked(true)
        setMeta(prevMeta => ({
            ...prevMeta,
            page: IntialMeta.page,
            page_size: IntialMeta.page_size
        }));        // Update requestPayload based on selected status when applying the filter 
        setRequestPayload(prev => ({
            ...prev,
            team_status: selectedStatus === 'active'
                ? '1'
                : selectedStatus === 'inactive'
                    ? '0'
                    : '' // Convert to '1' for active and '0' for inactive
        }));
        setShowFilter(false); // Close the filter dropdown after applying
    };

    const handleCancelFilter = () => {
        setIsApplyButtonClicked(false);
        setSelectedStatus('');
        fetchAllTeams(true);
        setRequestPayload(prev => ({
            ...prev,
            team_status: '' 
        }));
    };

    const handlecloseFilter = () =>{         
        setShowFilter(false);
        if(!isApplyButtonClicked){
            setSelectedStatus('');
        }
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value); // Update search term as user types
    };

    const handleSearchClick = () => {
        setRequestPayload(prev => ({
            ...prev,
            page_number: 0,
            records_per_page: 10,
            search: searchTerm // Set the search term in request payload
        }));
        // setSortConfig

    }

    const deleteTeam = async () => {
        setShowConfirmDeletion(false)
        const payload: DeleteTeamRequest = {
            team_uuid: selectedTeam
        }
        try {
            const response: any = await deleteTeamService(payload)
            if (response?.status === 200) {

                handleShowToast(true, "Success", "Team deleted successfully");
                fetchAllTeams()


            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
    }

    const cancelDeleteTeam = async () => {
        setShowConfirmDeletion(false);
        setSelectedTeam("");
    }




    const getAbbreviation = (workItemName: string): string => {
        const words: string[] = workItemName?.split(' ');
        if (words?.length === 1) {
            return workItemName?.slice(0, 2)?.toUpperCase();
        } else {
            return words?.map((word: string) => word[0]?.toUpperCase()).join('');
        }
    };

    const handleOpenModal = (teamId: string) => {
        setSelectedTeamId(teamId);
    };

    const getSelectedTeamCustomers = () => {
        if (!selectedTeamId) return [];
        const selectedTeam = teamsData.find(team => team.team_uuid === selectedTeamId);
        return selectedTeam?.customer_response.customer_details || [];
    };

    const renderCustomerDetails = (customerDetails: string[]) => {
        // Sort the customerDetails array in alphabetical ascending order
        const sortedCustomerDetails = customerDetails?.sort((a, b) => a?.localeCompare(b));

        if (sortedCustomerDetails?.length === 0) {
            return (
                <p className="font-semibold font-14 color-grey-v4 border-bottom pb-2 m-0">
                    No customers available
                </p>
            );
        }

        return sortedCustomerDetails?.map((customer, index) => (
            <p key={index} className="font-semibold font-14 color-grey-v4 border-bottom pb-3 m-0">
                {customer}
            </p>
        ));
    };

    function teamDetailsBinding() {
        if (teamsData?.length === 0) {
            return <NoRecordsFoundComponent
                colspan={7}
                mainContent="No Team Found"
                subContent="Teams will be listed once onboarded"
                columnHeight='65'
            />
        }
        return teamsData?.map((team) => {

            const workItemsArray = team.workitems.split(',');
            const workItems: any = workItemsArray?.map((item: any) => getAbbreviation(item));
            const displayedItems: any = workItems?.slice(0, 3);
            const remainingItems: any = workItems?.slice(3);

            return (<>
                <tr key={team.team_uuid}>
                    <td>
                    <span
                        data-bs-placement="right"
                        title={team.team_name?.length > 20 ? team.team_name : " "}
                        {...(team.team_name && team.team_name?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {team.team_name ? formatDescription(team.team_name, 20) : "-"}
                    </span>
                </td>
                    <td>
                    <span
                        className="text-truncate d-inline-block max-w-650px"
                        title={team.team_description?.length > 20 ? team.team_description : " "}
                        {...(team.team_description && team.team_description?.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {team.team_description ? formatDescription(team.team_description, 50) : "-"}
                    </span>
                    </td>
                    <td className="text-end pe-5">{team.users_count}</td>
                    <td>{team.handling_method=== TeamPrivilege.Push?"Push":"Pull"}</td>
                    <td>
                        {team.customer_response.type === "Custom" ? (
                            <a href="#/" data-bs-toggle="modal" data-bs-target="#customers" onClick={() => handleOpenModal(team.team_uuid)}>Custom</a>
                        ) : (
                            "All"
                        )}
                    </td>
                    <td>
                        <span className="d-flex align-items-center gap-1">
                            {displayedItems?.map((item: any, index: number) => (
                                <span key={index} className="gradient-tag me-1">{item}</span>
                            ))}
                            {remainingItems?.length > 0 && (
                                <span
                                    className="gradient-tag me-1 cursor-pointer"
                                    title={remainingItems?.join(', ')}
                                >
                                    +{remainingItems?.length}
                                </span>
                            )}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className={`d-inline-flex align-items-center justify-content-center status ${team?.team_status === "1" ? 'active' : 'inactive'}`}>
                            {team?.team_status === "1" ? 'Active' : 'Inactive'}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="d-flex align-items-center justify-content-center gap-2">
                            <button type="button" className="btn p-0 action-btn" onClick={() => { handleEditTeamClick(team.team_uuid) }}>
                                <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                            </button>
                            <button type="button" className="btn p-0 action-btn" onClick={() => { handleDeleteTeamClick(team.team_uuid) }}>
                                <img src="img/delete-icon.svg" alt="bin-icon-red" />
                            </button>
                        </span>
                    </td>
                </tr>
            </>)
        })
    }

    return (
        <>
            <div>
                <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
                    {isLoading ? (
                        <FullscreenLoader />
                    ) : (
                        <div className="p-3 py-4">
                            {/* Breadcrumbs */}
                            <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Admin</li>
                                    <li className="breadcrumb-item active" aria-current="page">Team Management</li>
                                </ol>
                            </nav>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h2 className="m-0 font-bold font-16 color-black">Team Management</h2>
                                <div className="d-flex align-items-stretch gap-3">
                                    <div>
                                        <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                            <input
                                                type="search"
                                                className="form-control font-semibold border-0 shadow-none font-14"
                                                placeholder="Search"
                                                aria-label="Search"
                                                value={searchTerm}
                                                onChange={(e) => { handleSearchChange(e) }} // Handle input change
                                                onKeyUp={(event) => event.key === "Enter" ? handleSearchClick() : null}
                                            />
                                            <span className="input-group-text border-0 bg-transparent cursor-pointer" onClick={() => { handleSearchClick() }}>
                                                <img src="img/search-icon.svg" alt="search" />
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        {/* Filter Button */}
                                        <div className="dropdown filter-dpd">
                                            <button
                                                onClick={() => {
                                                    setShowFilter(!showFilter);
                                                }}
                                                className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                                type="button"
                                            >
                                                <img src="img/filter-icon-blue.svg" alt="filter" className="filter-img" />
                                                <span>Filter</span>
                                            </button>

                                            {/* Filter Dropdown */}
                                            {showFilter && (
                                                <div className="dropdown-menu show active border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2"
                                                    style={{ zIndex: 99999, position: "absolute", right: "0%" }}>
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <h3 className="m-0 font-bold font-16 color-black-v1">Advanced Filter</h3>
                                                        <button className="bg-transparent border-0" type="button" onClick={() =>handlecloseFilter()}>
                                                            <img src="img/close-icon.svg" alt="close" />
                                                        </button>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <label htmlFor="status" className="font-semibold font-12 color-black-v1 form-label">Status</label>
                                                            <select
                                                                id="status"
                                                                className="form-select font-12 theme-input custom-input  cursor-pointer"
                                                                value={selectedStatus}
                                                                onChange={handleStatusChange}
                                                            >
                                                                <option value="" selected>Select</option>
                                                                <option value="active">Active</option>
                                                                <option value="inactive">Inactive</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="d-flex justify-content-end gap-3 mt-5">
                                                                <button className="secondary-btn rounded-3" type="button" onClick={() => {
                                                                    handleCancelFilter()
                                                                }}>
                                                                    <span className="d-inline-block my-1">Clear</span>
                                                                </button>
                                                                <button className="primary-btn rounded-3" disabled={!selectedStatus} type="button" onClick={handleApplyFilter}>
                                                                    <span className="d-inline-block my-1" >Apply</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <button className="primary-btn text-nowrap font-14" type="button" onClick={() => { handleAddNewTeamClick() }}>Add New Team</button>
                                </div>
                            </div>
                            {/* grid */}
                            <div className="table-responsive bg-white theme-table rounded-3 mb-3 custom-spacing">
                                <table className="table m-0 align-middle">
                                    <thead>
                                        <tr className="alter">
                                            <th>
                                                {teamsData?.length < 2 ? (
                                                    <span>Team Name</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('team_name')}>
                                                        Team Name {" "}{renderSortIcon('team_name')}
                                                    </span>
                                                )}
                                            </th>
                                            <th>
                                            <span>Description</span>
                                            </th>
                                            <th className="text-end pe-5">
                                                {teamsData?.length < 2 ? (
                                                    <span># Users</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('users_count')}>
                                                        # Users {" "}{renderSortIcon('users_count')}
                                                    </span>
                                                )}
                                            </th>
                                            <th>
                                                {teamsData?.length < 2 ? (
                                                    <span>Handling Method</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('handling_method')}>
                                                        Handling Method {" "}{renderSortIcon('handling_method')}
                                                    </span>
                                                )}
                                            </th>
                                            <th>                                              
                                                    <span>
                                                        Customers 
                                                    </span>                                                
                                            </th>
                                            <th>
                                                {teamsData?.length < 2 ? (
                                                    <span>Work Items</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('workitems')}>
                                                        Work Items {" "}{renderSortIcon('workitems')}
                                                    </span>
                                                )}
                                            </th>
                                            <th className="text-center">
                                                {teamsData?.length < 2 ? (
                                                    <span>Status</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('status')}>
                                                        Status {" "}{renderSortIcon('status')}
                                                    </span>
                                                )}
                                            </th>
                                            <th className="text-center">
                                                {teamsData?.length > 0 && (
                                                    <span>Action</span>
                                                )}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teamDetailsBinding()}
                                    </tbody>
                                </table>
                            </div>
                            {(teamsData && teamsData?.length > 0) && <div className="mt-1"> <PaginationComponent totalCount={meta?.total}
                                initialRecordsPerPage={meta?.page_size} currentPage={meta.page} setPageAndRecords={(currentPage: number, totalCount: number) => {
                                    setMeta((prev: Meta) => ({
                                        ...prev, page: currentPage, page_size: totalCount
                                    }))
                                }}></PaginationComponent></div>}
                        </div>
                    )}
                </div>

                <div className="modal fade" id="customers" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content border-0">
                            <div className="modal-header px-4 border-0">
                                <h3 className="modal-title font-16 font-bold color-black">
                                    Customers (<span>{getSelectedTeamCustomers()?.length}</span>)
                                </h3>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body px-4 py-0 border-0 d-flex flex-column gap-2 customerModal-content-wrapper">
                                {renderCustomerDetails(getSelectedTeamCustomers())}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmDeletion && (
                <DeletionConfirmationPopup
                    message="Are you sure you want to delete this Team?"
                    onConfirmDelete={deleteTeam}
                    onCancelDelete={cancelDeleteTeam}
                />
            )}

            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />
            }
        </>
    )
}

export default TeamGrid;
