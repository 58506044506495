import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { EquipmentContext } from './context/Context';
import Header from './components/login/header';
import Login from './components/login/login';
import LoginAuth from './components/login/login_auth';
import Applications from './components/application/applications';
import UserActivity from './components/user_activity/user_activity';
import NotFoundComponent from './components/404NotFound';
import ErrorPage from './components/ErrorPage';
import { OperatorNotifications } from './components/operations/work_item_notifications';
import WorkItem from './components/work_item/work_item';
import CompletedWorkItemsGrid from './components/insights/completed_work_item';
import { WorkItemSLAComponent } from './components/work_item_sla/work_item_sla';
import { WorkItemSLAAccordion } from './components/work_item_sla/work_item_sla_details';
import ProtectedRoute from './ProtectedRoute';
import { IndividualUserActivity } from './components/user_activity/individual_user_activity';
import { encryptKeys, encryptStorage } from './constant';
import UnauthorizedPage from './components/unauthorized_page';
import UserManagementGrid from './components/user_management/user_management_grid';
import UserManagementForm from './components/user_management/user_management_form';
import LiveFeedWindow from './reusable_components/panels/live_feed_window';
import CreateTeam from './components/teams/team_form';
import TeamGrid from './components/teams/team_grid';
import AuditHistoryGrid from './components/audit_history/audit_history';
import NotificationGrid from './components/notifications/notification_grid';
import { BreakShiftTabComponent } from './components/break_shift/break_shift_tab';
// Protected Route component

const AppContent = () => {
    const location = useLocation();
    const user = encryptStorage.getItem(encryptKeys?.userDetails);

    return (
        <React.Fragment>
            {!["/"].includes(location.pathname) && user && <Header />}
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/loginAuth" element={<LoginAuth />} />
                <Route path="/applications" element={<ProtectedRoute><Applications /></ProtectedRoute>} />
                <Route path="/operations" element={<ProtectedRoute><UserActivity /></ProtectedRoute>} />
                <Route path="/individualUserActivity" element={<ProtectedRoute><IndividualUserActivity /></ProtectedRoute>} />
                <Route path="/notFound" element={<NotFoundComponent />} />
                <Route path="/errorPage" element={<ErrorPage />} />
                <Route path="/forbidden" element={<UnauthorizedPage />} />
                <Route path="/workItemNotifications" element={<ProtectedRoute><OperatorNotifications /></ProtectedRoute>} />
                <Route path="/insights" element={<ProtectedRoute><CompletedWorkItemsGrid /></ProtectedRoute>} />
                <Route path="/workItem" element={<ProtectedRoute><WorkItem /></ProtectedRoute>} />
                <Route path="/workItemSLA" element={<ProtectedRoute><WorkItemSLAComponent /></ProtectedRoute>} />
                <Route path="/workItemSlaAccordion" element={<ProtectedRoute><WorkItemSLAAccordion /></ProtectedRoute>} />
                <Route path="*" element={<NotFoundComponent />} />
                <Route path="/auditHistory" element={<ProtectedRoute><AuditHistoryGrid /></ProtectedRoute>} />
                <Route path="/notifications" element={<ProtectedRoute><NotificationGrid /></ProtectedRoute>}></Route>
                <Route path="/createTeam" element={<ProtectedRoute><CreateTeam /></ProtectedRoute>} />
                <Route path="/team" element={<ProtectedRoute><TeamGrid /></ProtectedRoute>} />
                <Route path="/userManagementGrid" element={<ProtectedRoute><UserManagementGrid /></ProtectedRoute>} />
                <Route path="/userManagementForm" element={<ProtectedRoute><UserManagementForm /></ProtectedRoute>} />
                <Route path="/liveFeed" element={<ProtectedRoute><LiveFeedWindow /></ProtectedRoute>} />
                <Route path="/shiftBreakTemplates" element={<ProtectedRoute><BreakShiftTabComponent /></ProtectedRoute>} />
            </Routes>
        </React.Fragment>
    );
};
function App() {
    return (
        <BrowserRouter>
            <EquipmentContext>
                <AppContent />
            </EquipmentContext>
        </BrowserRouter>
    );
}

export default App;