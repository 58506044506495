/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import PTZVideoPlayer from "../video_player";
import { ImageAngles } from "../../enums/enums";
import { createBlobUrl, generateStreamingServiceBaseURL } from "../../utils/utils";
import { PTZValues } from "../../Interfaces/webrtc_player_interface";
import { camControlService, camStatusService, getLaneDevicesService } from "../../services/streaming_service";
import { GetLaneDevicesRequest, ImageAngleObject, LaneCameraDetails, PTZCamRequest } from "../../Interfaces/streaming_service_interface";
import ImageWithFallback from "../image_with_fallback";

export const LiveFeedPanel = ({ options, formContext }: any) => {
  const [activeTab, setActiveTab] = useState("liveFeed");
  const [focusedImageUrl, setFocusedImageUrl] = useState<string>("img/img-icon.svg");
  const [camera, setCamera] = useState<LaneCameraDetails>();
  const [imageAngles, setImageAngles] = useState<ImageAngleObject[]>()
  const [initialPTZValues, setInitialPTZValues] = useState<PTZValues>({ pan: 0, tilt: 0, zoom: 0 });
  const { portalData, isFocusedToInputField, laneDetails, match } = formContext
  const { completed, customer_uuid, site_uuid } = formContext.navigationProps
  const [laneCameras, setLaneCameras] = useState<LaneCameraDetails[]>([])

  const carouselRef = useRef<HTMLUListElement | null>(null);
  const cameraDropdownRef = useRef<HTMLSelectElement | null>(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const loadPortalImages = useCallback(async () => {
    try {
      if (!portalData.current.event_id) return
      const imageAnglesNames = Object.values(ImageAngles);

      // Use map to create the imageAnglesObject array
      const imageAnglesObject: ImageAngleObject[] = imageAnglesNames.map((eachAngle, index) => ({
        active: false,
        label: eachAngle,
        url: createBlobUrl(customer_uuid, site_uuid, portalData.current.event_id, eachAngle),
        id: index
      }));

      // Set the first image as active
      imageAnglesObject[0].active = true;
      setFocusedImageUrl(imageAnglesObject[0].url);
      setImageAngles(imageAnglesObject);
    } catch (error: any) {
      console.log("An error occurred: ", error);
    }
  }, [match]);

  const loadLaneCameras = useCallback(async () => {
    try {
      const payload: GetLaneDevicesRequest = {
        lane_id: laneDetails?.lane_id,
        customer_id: customer_uuid,
        site_id: site_uuid
      }
      const apiResponse = await getLaneDevicesService(payload)
      if (apiResponse?.status !== 200) {
        throw new Error(apiResponse?.response?.data?.message);
      }
      const { data: laneCameraDetails } = apiResponse?.data
      setLaneCameras(laneCameraDetails)
      setCamera(laneCameraDetails[0])
    } catch (error: any) {
      console.log("An error occurred: ", error);
    }
  }, [laneDetails?.lane_id])

  useEffect(() => {
    loadLaneCameras()
  }, [loadLaneCameras])

  useEffect(() => {
    if (match) {
      loadPortalImages()
      setActiveTab("images")
    } else {
      setActiveTab("liveFeed")
    }
  }, [match])
  useEffect(() => {
    if (activeTab === "liveFeed") {
      cameraDropdownRef?.current?.focus();
    }
  }, [activeTab]);
  useEffect(() => {
    if (completed || isFocusedToInputField) {
      return
    }
    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      switch (key) {
        case "Tab":
          event.preventDefault();
          if (activeTab === "images") {
            setActiveTab("liveFeed");
          } else if (match) {
            setActiveTab("images");
          }
          break;
      }
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [activeTab, match, completed, isFocusedToInputField]);
  const handleImageSelect = (imageUrl: string, id: number) => {
    setFocusedImageUrl(imageUrl);
    setImageAngles((prevImageAngles: any) => {
      return prevImageAngles.map((eachPrevImage: ImageAngleObject) => {
        if (eachPrevImage.id === id) {
          return {
            ...eachPrevImage,
            active: true
          }
        }
        return {
          ...eachPrevImage,
          active: false
        }
      })
    })
  };
  const handleCameraSelect = (event: any) => {
    setCamera(JSON.parse(event.target.value));
  }
  const handleEnlarge = () => {
    const width = window.screen.width;
    const height = window.screen.height;
    const liveFeedWindowData = {
      customerUUID: customer_uuid,
      siteUUID: site_uuid,
      laneDetails: laneDetails
    };
    sessionStorage.setItem('liveFeedData', JSON.stringify(liveFeedWindowData));
    window.open("/liveFeed", "_blank", `width=${width},height=${height}`);
  }

  const handlePTZChange = async (ptz: PTZValues) => {
    try {
      if (!camera) return
      const payload: PTZCamRequest = {
        camera_id: camera?.device_uuid,
        coordinates: ptz,
        ip_address: camera?.ip_address,
        port: 0,
        video_port: "",
        username: camera?.enct_username,
        password: camera?.enct_password,
        preset_name: "",
        base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.cam_control_port)
      }
      const response = await camControlService(payload)
      if (response?.status !== 200) {
        throw new Error(response?.response?.data?.message);
      }
    } catch (error) {
      console.log("an error occured: ", error)
    }
  };

  useEffect(() => {
    const loadInitialCameraPosition = async () => {
      if (!camera || activeTab !== "liveFeed") return
      try {
        const payload: PTZCamRequest = {
          camera_id: camera.device_uuid,
          ip_address: camera.ip_address,
          port: 0,
          video_port: "",
          username: camera.enct_username,
          password: camera.enct_password,
          preset_name: "",
          base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.cam_control_port)
        };
        const response = await camStatusService(payload);
        if (response?.status !== 200) {
          throw new Error(response?.response?.data?.message || "Failed to get camera status");
        }
        const { pan, tilt, zoom } = response.data.data;
        setInitialPTZValues({ pan, tilt, zoom });
      } catch (error: any) {
        console.log("an error occured: ", error.message)
      }
    }
    loadInitialCameraPosition()
  }, [camera, activeTab])

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let isDragging = false;
    let startX: number, startScrollLeft: number;

    const dragStart = (e: MouseEvent) => {
      isDragging = true;
      carousel.classList.add("dragging");
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e: MouseEvent) => {
      if (!isDragging) return;
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
      updateArrowVisibility();
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const updateArrowVisibility = () => {
      const scrollLeft = carousel.scrollLeft;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < maxScrollLeft);
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", updateArrowVisibility);

    // Initial arrow visibility update
    updateArrowVisibility();

    return () => {
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", updateArrowVisibility);
    };
  }, [activeTab, imageAngles]);
  const scrollCarousel = (direction: "left" | "right") => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollAmount = direction === "left" ? -200 : 200; // Adjust this value as needed
    carousel.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  return (
    <div className="content-container p-0">
      <div className="p-3">
        <div className="row">
          <div className="col-md-12 min-h-75vh" style={{ overflow: 'hidden' }}>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2">
                <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
                  <img src="img/gallery-icon.svg" alt="product details" />
                </span>
                <h2 className="font-bold font-14 color-black m-0">
                  {!completed && `${match ? "Portal Images/" : ""}Live Feed`}
                  {completed && `Portal Images`}
                </h2>
              </div>
              {match && !completed && <ul
                className="nav nav-pills theme-tab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "images" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("images")}
                    type="button"
                  >
                    Images
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "liveFeed" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("liveFeed")}
                    type="button"
                  >
                    Live Feed
                  </button>
                </li>
              </ul>}
            </div>
            <div className="tab-content" id="pills-tabContent">
              {activeTab === "images" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-images"
                  role="tabpanel"
                  aria-labelledby="pills-images-tab"
                  tabIndex={0}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <ImageWithFallback imageUrl={focusedImageUrl} fallbackUrl={"img/img-icon.svg"} enableZoom={true} />
                  </div>
                  <div className="wrapper carousel-ptd pt-4 pt-xl-5">
                    {showLeftArrow && (
                      <i
                        className="left-arrow"
                        onClick={() => scrollCarousel("left")}
                      >
                        <img src="img/chevron-blue.svg" alt="left-arrow" />
                      </i>
                    )}
                    <ul className="carousel list-unstyled" ref={carouselRef}>
                      {imageAngles?.map((value: any, index: any) => {
                        return (
                          <li key={index}>
                            <div
                              className={`cards position-relative ${value?.active === true ? "active" : ""
                                }`}
                              onClick={() => {
                                handleImageSelect(value?.url, value?.id)
                              }}
                            >
                              <ImageWithFallback imageUrl={value?.url} fallbackUrl={"img/img-icon.svg"} enableZoom={false} />
                              <span className="container-label position-absolute py-1 px-2 rounded-2 color-white font-bold font-8">
                                {value.label}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {showRightArrow && (
                      <i
                        className="right-arrow"
                        onClick={() => scrollCarousel("right")}
                      >
                        <img src="img/chevron-blue.svg" alt="right-arrow" />
                      </i>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "liveFeed" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-liveFeed"
                  role="tabpanel"
                  aria-labelledby="pills-liveFeed-tab"
                  tabIndex={0}
                >
                  <div className="d-flex align-items-center justify-content-between mb-2 border-top pt-2">
                    <span className="live-indicator font-regular font-12 color-black d-flex align-items-center lh-1 gap-1">
                      <span className="dot d-inline-block"></span>
                      Live
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <select
                        name="camera-option"
                        id="cam-view-options"
                        aria-label="Camera View Options"
                        className="form-select shadow-none font-regular font-12 custom-select cursor-pointer"
                        value={JSON.stringify(camera)}
                        ref={cameraDropdownRef}
                        onChange={handleCameraSelect}
                      >
                        {laneCameras?.map((eachCamera: LaneCameraDetails, index: any) => (
                          <option key={index} value={JSON.stringify(eachCamera)}>
                            {eachCamera?.device_name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn bg-white border rounded-2 p-2 d-flex hover-change"
                        type="button"
                        onClick={handleEnlarge}
                      >
                        <img src="img/enlarge-icon.svg" alt="enlarge" />
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mb-5">
                    <PTZVideoPlayer
                      width={800}
                      height={600}
                      onPTZChange={handlePTZChange}
                      initialPTZValues={initialPTZValues}
                      camera={camera} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}