import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SessionTimeOutPopup =() =>{

    useEffect(() => {
        // Add overflow-hidden class to body when component mounts
        document.body.classList.add('overflow-hidden');

        // Cleanup function to remove class when component unmounts
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    const navigate = useNavigate();
    return (
        <>
        <div
                className="modal fade custom-popup show d-block ps-0 blur-backdrop "
                id="sessionexpPopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="sessionexpPopup"
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered session-popup-width ">
                    <div className="modal-content session-popup-content-bottom">
                        <div className="modal-body custom-popup-form text-center p-5">
                            <img src="img/session-exp.svg" alt="session" className="mb-3" />
                            <p className="font-bold font-18 color-dark-blue">
                                Your Session Expired
                            </p>
                            <p className="font-regular font-14 color-dark-blue mb-4">
                                Your session has expired due to your inactivity. No worry simply login
                                again.
                            </p>
                            <button
                                className="primary-btn rounded-3 mt-2"
                                type="button"
                                onClick={() => {
                                    navigate("/"); 
                                }}
                            ><span className="d-inline-block my-1">Login</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div >
                <div className="modal-backdrop fade show"></div>
            </>
    )
}

export default SessionTimeOutPopup