import React, { useCallback, useEffect, useState } from "react";
import { GetLaneDevicesRequest, LaneCameraDetails, liveFeedWindowProps, PTZCamRequest } from "../../Interfaces/streaming_service_interface";
import { camControlService, camStatusService, getLaneDevicesService } from "../../services/streaming_service";
import PTZVideoPlayer from "../video_player";
import { PTZValues } from "../../Interfaces/webrtc_player_interface";
import { generateStreamingServiceBaseURL } from "../../utils/utils";
import { FullscreenLoader } from "../../components/loader";

const LiveFeedWindow: React.FC = () => {
    const [windowProps, setWindowProps] = useState<liveFeedWindowProps | null>(null);
    const [laneCameras, setLaneCameras] = useState<LaneCameraDetails[]>([]);
    const [initialPTZValues, setInitialPTZValues] = useState<PTZValues>({ pan: 0, tilt: 0, zoom: 0 });
    const [camera, setCamera] = useState<LaneCameraDetails | undefined>(undefined);

    useEffect(() => {
        const storedData = sessionStorage.getItem('liveFeedData');
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                setWindowProps(parsedData);
                sessionStorage.removeItem('liveFeedData');
            } catch (error) {
                console.error("Error parsing stored live feed data:", error);
            }
        } else {
            console.error("No live feed data found in session storage");
        }

        return () => {
            // Cleanup function
            setWindowProps(null);
            setLaneCameras([]);
            setCamera(undefined);
        };
    }, []);

    const loadLaneCameras = useCallback(async () => {
        if (!windowProps?.laneDetails?.lane_id || !windowProps?.customerUUID || !windowProps?.siteUUID) {
            console.error("Missing required props for loading lane cameras");
            return;
        }

        try {
            const payload: GetLaneDevicesRequest = {
                lane_id: windowProps.laneDetails.lane_id,
                customer_id: windowProps.customerUUID,
                site_id: windowProps.siteUUID
            };
            const apiResponse = await getLaneDevicesService(payload);
            if (apiResponse?.status !== 200) {
                throw new Error(apiResponse?.response?.data?.message || "Failed to load lane cameras");
            }
            const { data: laneCameraDetails } = apiResponse.data;
            setLaneCameras(laneCameraDetails);
            setCamera(laneCameraDetails[0]);
        } catch (error: any) {
            console.error("An error occurred while loading lane cameras:", error.message);
        }
    }, [windowProps]);

    useEffect(() => {
        if (windowProps) {
            loadLaneCameras();
        }
    }, [windowProps, loadLaneCameras]);

    const handleCameraSelect = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCamera = laneCameras.find(cam => cam.device_uuid === event.target.value);
        setCamera(selectedCamera);
    }, [laneCameras]);

    const handlePTZChange = useCallback(async (ptz: PTZValues) => {
        if (!camera) {
            console.error("No camera selected");
            return;
        }

        try {
            const payload: PTZCamRequest = {
                camera_id: camera.device_uuid,
                coordinates: ptz,
                ip_address: camera.ip_address,
                port: 0,
                video_port: "",
                username: camera.enct_username,
                password: camera.enct_password,
                preset_name: "",
                base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.cam_control_port)
            };
            const response = await camControlService(payload);
            if (response?.status !== 200) {
                throw new Error(response?.response?.data?.message || "Failed to control camera");
            }
        } catch (error: any) {
            console.error("An error occurred while controlling the camera:", error.message);
        }
    }, [camera]);

    useEffect(() => {
        const loadInitialCameraPosition = async () => {
            if (!camera) return
            try {
                const payload: PTZCamRequest = {
                    camera_id: camera.device_uuid,
                    ip_address: camera.ip_address,
                    port: 0,
                    video_port: "",
                    username: camera.enct_username,
                    password: camera.enct_password,
                    preset_name: "",
                    base_url: generateStreamingServiceBaseURL(camera.base_ip_address, camera.cam_control_port)
                };
                const response = await camStatusService(payload);
                if (response?.status !== 200) {
                    throw new Error(response?.response?.data?.message || "Failed to get camera status");
                }
                const { pan, tilt, zoom } = response.data.data;
                setInitialPTZValues({ pan, tilt, zoom });
            } catch (error: any) {
                console.log("an error occured: ", error.message)
            }
        }
        loadInitialCameraPosition()
    }, [camera])

    if (!windowProps) {
        return <FullscreenLoader />;
    }

    return (
        <div
            className="tab-pane fade show active p-3"
            id="pills-liveFeed"
            role="tabpanel"
            aria-labelledby="pills-liveFeed-tab"
            tabIndex={0}
        >
            <div className="d-flex align-items-center justify-content-between mb-2 border-top pt-2">
                <span className="live-indicator font-regular font-12 color-black d-flex align-items-center lh-1 gap-1">
                    <span className="dot d-inline-block"></span>
                    Live
                </span>
                <div className="d-flex align-items-center gap-2">
                    <select
                        name="camera-option"
                        id="cam-view-options"
                        aria-label="Camera View Options"
                        className="form-select shadow-none font-regular font-12 custom-select cursor-pointer"
                        value={camera?.device_uuid || ""}
                        onChange={handleCameraSelect}
                    >
                        {laneCameras.map((eachCamera: LaneCameraDetails) => (
                            <option key={eachCamera.device_uuid} value={eachCamera.device_uuid}>
                                {eachCamera.device_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-5">
                <PTZVideoPlayer
                    width={1280}
                    height={720}
                    onPTZChange={handlePTZChange}
                    initialPTZValues={initialPTZValues}
                    camera={camera}
                />
            </div>
        </div>
    );
};

export default LiveFeedWindow;