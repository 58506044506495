import { useEffect } from "react";
import { PopupProps } from "../Interfaces/work_item_interface";

export default function Popup(props: PopupProps) {
  useEffect(() => {
    // Add overflow-hidden class to body when component mounts
    document.body.classList.add('overflow-hidden');

    // Cleanup function to remove class when component unmounts
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  const { handleClick, popupMessage } = props;
  return (
    <>
      <div className="container-fluid position-fixed toastContainer warning-toast blur-backdrop">
        <div className="p-4 pb-3 toast-content d-flex align-items-start gap-3">
          <img src="img/warning-icon-lg.svg" alt="warning" />
          <div className="d-flex flex-column gap-4 mt-2">
            <h3 className="font-bold font-16 color-white m-0">{popupMessage}</h3>
            <div className="action-btns d-flex gap-2">
              <button
                type="button"
                className="action-btn-yes font-10 font-semibold px-3 py-2 rounded-2"
                onClick={() => handleClick("Yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="action-btn-no font-10 font-semibold color-white px-3 py-2 rounded-2"
                onClick={() => handleClick("No")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show blur-backdrop">
      </div>
    </>
  );
}
