export enum UserRoles {
    supervisor = "SUPERVISOR",
    Operator = "OPERATOR",
    admin = "ADMIN",
}
export enum DisplayUserRoles {
    supervisor = "Supervisor",
    admin = "Admin",
    Operator = "Operator",
}

export enum WorkItemStatus {
    Completed = "COMPLETED",
    EscalatedToLane = "ESCALATE_TO_LANE",
    InProgress = "IN_PROGRESS",
    Queued = "QUEUED",
    EscalatedToCustomer = "ESCALATE_TO_CUSTOMER",
    Requeued = "RE_QUEUED"
}

export enum UserStatusType {
    DND = "DND",
    OFFLINE = "OFFLINE",
    BREAK = "BREAK",
    WORKING = "WORKING",
    IDLE = "IDLE",
    ACTIVE = "ACTIVE"
}


export enum applicationAbbrievation {
    VEM = "VEM",
    ORCA = "ORCA"
}

export enum applicationName {
    VEM = "Vision Exception Management"
}

export enum TeamPrivilege {
    Push = "PUSH",
    NoPush = "NO_PUSH"
}

export enum UserStatus {
    IDLE = 'IDLE',
    BREAK = 'BREAK',
    DND = 'DND',
    OFFLINE = 'OFFLINE',
    WORKING = 'WORKING',
    ACTIVE = 'ACTIVE',
    LIVEUSERSTATUS = '_LIVE_USER_STATUS'
}

export enum WebsocketCodes {
    NEWWORKITEM = 'NEW_WORKITEM',
    REVOKEWORKITEM = 'REVOKE_WORKITEM',
    STATUSUPDATE = 'STATUS_UPDATE',
    TAKEOVER = 'TAKEOVER_WORKITEM',
    AUTOVISIT = 'AUTO_VISIT',
    ESCALATEDTOCUSTOMER = 'ESCALATED_TO_CUSTOMER',
    WORKITEMCORRECTION = '_WORKITEM_CORRECTION'
}

export enum CompletedDurationFilters {
    LASTHOUR = 'LAST_HOUR',
    TODAY = 'TODAY',
    LAST3MONTHS = 'LAST_3_MONTHS',
    LASTWEEK = 'LAST_WEEK',
    THISWEEK = 'THIS_WEEK',
    LAST6MONTHS = 'LAST_6_MONTHS',
    LASTMONTH = 'LAST_MONTH',
    THISMONTH = 'THIS_MONTH',
    LAST12MONTHS = 'LAST_12_MONTHS',
    LASTYEAR = 'LAST_YEAR',
    THISYEAR = 'THIS_YEAR',
}
export enum ActionsEnum {
    VALIDATE = 'VALIDATE',
    DELETE = 'DELETE',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    READ = 'READ',
    READALL = 'READ_ALL',
}

export enum HeaderNotificationSocketActions {
    REFRESH = 'REFRESH',
    UPDATE = 'UPDATE'
}

export enum EquipmentTypes {
    CONTAINER = 'container',
    CHASSIS = 'chassis'
}

export enum ClassificationTypes {
    BOBTAIL = 'Bobtail',
    CHASSISBUNDLE = 'Chassis Bundle',
    FLATRACK = 'Flat Rack',
    BARECHASSIS = 'Bare Chassis'
}
export enum NotificationTypes {
    DIRECT = "DIRECT",
    BROADCAST = "BROADCAST"
}

export enum NotificationCategories {
    WORKITEM = "WORK_ITEM",
    BREAK = "BREAK",
    DND = "DND"
}
export enum NotificationHeader {
    NOTIFICATIONSUBSCRIPTIONID = '_NOTIFICATIONS',
    SUPERVISORNOTIFICATIONID = 'SUPERVISOR_NOTIFICATIONS'
}

export enum ImageAngles {
    BOTTOM_LEFT = "Bottom Left",
    BOTTOM_RIGHT = "Bottom Right",
    MIDDLE_BACK = "Middle Back",
    MIDDLE_LEFT = "Middle Left",
    MIDDLE_RIGHT = "Middle Right",
    TOP_FRONT = "Top Front"
}

export enum WorkItemTypes {
    EQUIPMENT_CORRECTION = "EQUIPMENT_CORRECTION",
    VISIT_MATCHUP = "VISIT_MATCHUP"
}

export enum VisitMatchupType {
    PORTAL = "Portal",
    MANUAL = "Manual"
}